/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 计算优惠券能扣减的金额
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  couponDeductionReq: order.CouponDeductionReq,

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: number;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "post",
    url: `/order/coupon-deduction`,
    data: couponDeductionReq,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
