/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 票务订单接口
 */
import getAdvance from "./getAdvance";
import calculatedAmount from "./calculatedAmount";
import changeOrderDelivery from "./changeOrderDelivery";
import couponDeduction from "./couponDeduction";
import calculateDiscountAmt from "./calculateDiscountAmt";
import getOrderDMDetailTicket from "./getOrderDMDetailTicket";
import douYinCancelOrder from "./douYinCancelOrder";
import getExpressFee from "./getExpressFee";
import cardList from "./cardList";
import lockSeatChoose from "./lockSeatChoose";
import lockSeatNow from "./lockSeatNow";
import deleteLockSeat from "./deleteLockSeat";
import getOrderMhDetailTicket from "./getOrderMhDetailTicket";
import getOrderMyDetailTicket from "./getOrderMyDetailTicket";
import createOrder from "./createOrder";
import orderGrowthExplain from "./orderGrowthExplain";
import cancelOrder from "./cancelOrder";
import orderConfirmReceipt from "./orderConfirmReceipt";
import orderPayInfo from "./orderPayInfo";
import testUpdateEs, { TestUpdateEsParams } from "./testUpdateEs";
import deleteOrder from "./deleteOrder";
import getOrderList from "./getOrderList";
import getOrderCountInfo from "./getOrderCountInfo";
import saveOrderTicketQuestionnaire from "./saveOrderTicketQuestionnaire";
import getOrderListNum from "./getOrderListNum";
import quickOrder from "./quickOrder";
import quickOrderResult from "./quickOrderResult";
import addRedisKeyValue, { AddRedisKeyValueParams } from "./addRedisKeyValue";
import getStayCommentOrderList from "./getStayCommentOrderList";
import getOrderDetailTicket from "./getOrderDetailTicket";
import verifyOrder from "./verifyOrder";

export class TicketOrderApi {
  constructor(
    public getAdvance: (
      uuid: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.OrderDetailsParameterDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public calculatedAmount: (
      createOrder: order.CreateOrder,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: number;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public changeOrderDelivery: (
      deliveryWay:
        | "SCENE"
        | "EXPRESS"
        | "ETICKET"
        | "SHOP"
        | "VIRTUAL"
        | "ID_CARD"
        | "WINDOW",
      orderIds: Array<string>,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public couponDeduction: (
      couponDeductionReq: order.CouponDeductionReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: number;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public calculateDiscountAmt: (
      calculateDiscountAmt: order.CalculateDiscountAmt,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.CalculateDiscountRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderDMDetailTicket: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.DaMaiOrderDetail;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public douYinCancelOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getExpressFee: (
      getExpressFeeReq: order.GetExpressFeeDMReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: number;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cardList: (
      uuid: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<order.huaiAnCardResponse>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public lockSeatChoose: (
      lockSeatChoose: order.LockSeatChoose,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public lockSeatNow: (
      lockSeatNow: order.LockSeatNow,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public deleteLockSeat: (
      uuid: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderMhDetailTicket: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.ThirdOrderDetailInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderMyDetailTicket: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.MyOrderDetail;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public createOrder: (
      createOrder: order.CreateOrder,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.CreateOrderResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderGrowthExplain: (
      orderGrowthReq: order.OrderGrowthReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.OrderGrowthRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cancelOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderConfirmReceipt: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderPayInfo: (
      orderId: string,
      orderType:
        | "SHOW"
        | "RECHARGE"
        | "COMMODITY"
        | "VIDEO"
        | "ACTIVITYAPPLY"
        | "MOVIE",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.OrderPayInfoRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public testUpdateEs: (
      params: TestUpdateEsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: any;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public deleteOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderList: (
      orderListReq: order.OrderListReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.IPage<order.EsOrderInfo>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderCountInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.OrderCountInfoRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public saveOrderTicketQuestionnaire: (
      req: order.OrderTicketQuestionnaireReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderListNum: (
      memberId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.IPage<order.EsOrderInfo>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public quickOrder: (
      createOrder: order.CreateOrder,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public quickOrderResult: (
      key: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.CreateOrderResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public addRedisKeyValue: (
      params: AddRedisKeyValueParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getStayCommentOrderList: (
      orderListReq: order.OrderListReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.IPage<order.ProductCommentDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderDetailTicket: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.EsOrderDetailTicketInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public verifyOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getAdvance,
  calculatedAmount,
  changeOrderDelivery,
  couponDeduction,
  calculateDiscountAmt,
  getOrderDMDetailTicket,
  douYinCancelOrder,
  getExpressFee,
  cardList,
  lockSeatChoose,
  lockSeatNow,
  deleteLockSeat,
  getOrderMhDetailTicket,
  getOrderMyDetailTicket,
  createOrder,
  orderGrowthExplain,
  cancelOrder,
  orderConfirmReceipt,
  orderPayInfo,
  testUpdateEs,
  deleteOrder,
  getOrderList,
  getOrderCountInfo,
  saveOrderTicketQuestionnaire,
  getOrderListNum,
  quickOrder,
  quickOrderResult,
  addRedisKeyValue,
  getStayCommentOrderList,
  getOrderDetailTicket,
  verifyOrder,
} as TicketOrderApi;
