























import { Component, Mixins, Watch, Vue } from "vue-property-decorator";
import { Initializer, Persistor } from "@/core";
import { Mixin } from "@/core/mixins/mixin";
import { isWechat } from "@/utils/index";
import { Route } from "vue-router";
import wx from "weixin-js-sdk";

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave"]);
@Component({})
export default class App extends Mixins(Mixin) {
  public loginMessage!: oauth2.OAuth2AccessTokenDto;
  isPageHide = false;

  beforeCreate(): void {
    Initializer();
    let location = window.location;
    if (isWechat()) {
      // 配置微信 JSSDK
      this.$api.oauth2Api.socialLogin.createJsapiSignature(
        { url: location.origin + location.pathname },
        ({ data }) => {
          if (data) {
            wx.config(
              Object.assign(data, {
                jsApiList: [
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                  "scanQRCode",
                ],
              })
            );
          }
        }
      );
    }
  }
  created(): void {
    window.addEventListener("pageshow", this.refreshPage);
    window.addEventListener("pagehide", this.refreshPageHide);
  }

  beforeDestroy(): void {
    window.removeEventListener("pageshow", this.refreshPage);
    window.removeEventListener("pagehide", this.refreshPageHide);
  }
  refreshPage(event: PageTransitionEvent): void {
    if (this.isPageHide && event.persisted) {
      window.location.reload();
    }
  }

  refreshPageHide(): void {
    this.isPageHide = true;
  }

  @Watch("$route")
  routerChange(to: Route, from: Route): void {
    let channelDistributionQueryData =
      this.getRedirectQueryDataForChannelDistribution(to, from);
    let theaterId = this.getRedirectQueryDataForTheater(to, from);
    if (channelDistributionQueryData || theaterId) {
      // 需要在 to 上增加参数后重定向
      let newQuery = {};
      if (channelDistributionQueryData) {
        newQuery = Object.assign(newQuery, channelDistributionQueryData);
        // 保证缓存的与 url 上的一致
        this.ChannelDistributionModule.SET_distributionChannelId_PERSIST(
          channelDistributionQueryData.distributionChannelId
        );
        this.ChannelDistributionModule.SET_distributionSeriesId_PERSIST(
          channelDistributionQueryData.distributionSeriesId
        );
        this.ChannelDistributionModule.SET_templateType_PERSIST(
          channelDistributionQueryData.templateType
        );
      }
      if (theaterId) {
        newQuery = Object.assign(newQuery, { theaterId });
        // sessionStorage 缓存一下请求参数中的 theaterId，AJAX 请求会从缓存取，用于设置请求头字段
        this.TheaterModule.SET_theaterId_PERSIST(theaterId);
      }
      this.$router.replace({
        path: to.path,
        query: Object.assign(newQuery, to.query),
      });
    }
    this.getChannelData();
  }

  /**
   * 如果 from 的 url 上有剧院 ID 而 to 的 url 上没有
   * 则在 to 的 url 参数上加上剧院 ID 后重定向
   */
  getRedirectQueryDataForTheater(to: Route, from: Route): string | null {
    const toTheaterId = to.query.theaterId;
    if (!!toTheaterId) {
      // 如果 to 上已经有剧院 ID 了，则不用加参数了，需要设置一下 vuex，请求头字段是从这里边取的
      this.TheaterModule.SET_theaterId_PERSIST(String(toTheaterId));
      return null;
    }
    const fromTheaterId = from.query.theaterId || this.TheaterModule.theaterId;
    if (!fromTheaterId) {
      // 如果 from 上没有剧院 ID，则不用加参数了
      return null;
    }
    return String(fromTheaterId);
  }

  /**
   * 如果 from 的 url 上或 sessionStorage 中有渠道分销信息而 to 的 url 上没有
   * 则在 to 的 url 参数上加上渠道分销信息后重定向
   *
   * 渠道分销的信息，既在 url 上维护，也在 sessionStorage 中维护
   * url 上维护是为了分享出去时能带上
   * sessionStorage 中维护是为了从三方系统（如微信授权、支付、三方项目等）跳转回来时能继续保持渠道分销 ID
   * 如果三方系统都在对接时把渠道分销信息传来传去，对接和维护成本太高
   */
  getRedirectQueryDataForChannelDistribution(
    to: Route,
    from: Route
  ): {
    distributionChannelId: string;
    distributionSeriesId: string;
    templateType: string;
  } | null {
    if (from.name == "per-order" && to.name == "projectdetail") {
      // 如果是从演出订单页跳转到演出详情页，则不在 url 上带上渠道分销的信息（袁秀和甲方确认的需求）
      return null;
    }
    const toDistributionChannelId = to.query.distributionChannelId;
    const toDistributionSeriesId = to.query.distributionSeriesId;
    if (!!toDistributionChannelId && !!toDistributionSeriesId) {
      // 如果 to 上已经有渠道分销信息了，则不用加参数了
      return null;
    }
    const fromDistributionChannelId = String(
      from.query.distributionChannelId ||
        this.ChannelDistributionModule.distributionChannelId
    );
    const fromDistributionSeriesId = String(
      from.query.distributionSeriesId ||
        this.ChannelDistributionModule.distributionSeriesId
    );
    if (!fromDistributionChannelId || !fromDistributionSeriesId) {
      // 如果 from 上没有完整的渠道分销信息，则不用加参数了
      return null;
    }
    // 渠道分销模板类型
    const fromTemplateType = String(
      from.query.templateType || this.ChannelDistributionModule.templateType
    );
    const toTemplateType = to.query.templateType;
    return {
      distributionChannelId: String(
        toDistributionChannelId || fromDistributionChannelId || ""
      ),
      distributionSeriesId: String(
        toDistributionSeriesId || fromDistributionSeriesId || ""
      ),
      templateType: String(toTemplateType || fromTemplateType || ""),
    };
  }

  // 获取地址中的channel，添加到请求头header的Channel-From中
  getChannelData(): void {
    let channel = (this.$route.query.channel as string) || "";
    this.ChannelFromModule.SET_ChannelFrom_PERSIST(channel);
    console.log("打印日志");
  }

  updated(): void {
    Persistor();
  }
}
