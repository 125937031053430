/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 会员信息
 */
import getQRCode from "./getQRCode";
import assetCenter from "./assetCenter";
import getMemberCaptcha from "./getMemberCaptcha";
import memberEquities from "./memberEquities";
import browseFlaghipStore from "./browseFlaghipStore";
import memberInfo from "./memberInfo";
import growthScores, { GrowthScoresParams } from "./growthScores";
import growthTask from "./growthTask";
import signIn from "./signIn";
import shoppingMallInfo from "./shoppingMallInfo";
import registerTheaterMember from "./registerTheaterMember";
import getTheaterRegisterCaptcha from "./getTheaterRegisterCaptcha";

export class CuUserApi {
  constructor(
    public getQRCode: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public assetCenter: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.AssetCenter;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMemberCaptcha: (
      phoneCaptchaRequest: member.PhoneCaptchaRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public memberEquities: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.MemberEquitiesDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public browseFlaghipStore: (
      browseTaskReq: member.BrowseTaskReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public memberInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.MemberInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public growthScores: (
      params: GrowthScoresParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.MemberGrowthValueRecordDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public growthTask: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.GrowthTaskDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public signIn: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shoppingMallInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.MemberShoppingInfoRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public registerTheaterMember: (
      theaterRegisterDto: member.TheaterRegisterDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterRegisterCaptcha: (
      theaterRegisterReq: member.TheaterRegisterReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getQRCode,
  assetCenter,
  getMemberCaptcha,
  memberEquities,
  browseFlaghipStore,
  memberInfo,
  growthScores,
  growthTask,
  signIn,
  shoppingMallInfo,
  registerTheaterMember,
  getTheaterRegisterCaptcha,
} as CuUserApi;
