import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";

export type ChannelType =
  | "plat_h5"
  | "plat_ios"
  | "plat_android"
  | "plat_wechat_miniapp"
  | "douyin_miniapp";

export interface ChannelState {
  /**
   * ChannelModule.channel 只用来表示环境是 H5 还是 APP 还是小程序
   * 不用来表示渠道是平台还是剧院
   * 在 axios 的请求拦截器中组装 Header 时
   * 再根据配置和 chennel 来组装 Header 中的 channel 字段，将渠道和环境融为一个字段
   */
  channel: ChannelType;
}

@Module({ dynamic: true, store, name: "channel" })
class Channel extends VuexModule implements ChannelState {
  channel: ChannelType = "plat_h5";

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromSessionStorage("vuex", "channel"));
  }

  @Mutation
  resetAllData_PERSIST() {
    this.channel = "plat_h5";
  }

  @Mutation
  SET_channel_PERSIST(channel: ChannelType) {
    this.channel = channel;
  }
}

export const ChannelModule = getModule(Channel);
