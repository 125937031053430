/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 翼支付回调相关接口
 */
import notify from "./notify";

export class BastPayNotifyApi {
  constructor(
    public notify: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: any;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  notify,
} as BastPayNotifyApi;
