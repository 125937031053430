/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 校验绑定邮箱的验证码
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  checkEmailDto: member.CheckEmailDto,

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: string;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "post",
    url: `/member/email/captcha/checking`,
    data: checkEmailDto,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
