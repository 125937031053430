/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 活动基本信息
 */
import findActivityHistoryInfo, {
  FindActivityHistoryInfoParams,
} from "./findActivityHistoryInfo";
import findActivityHelpInfo from "./findActivityHelpInfo";
import findActivityHelpTopTen from "./findActivityHelpTopTen";
import findActivityBaseInfoById from "./findActivityBaseInfoById";
import findActivityInfoById from "./findActivityInfoById";

export class ActivityInfoApi {
  constructor(
    public findActivityHistoryInfo: (
      params: FindActivityHistoryInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ActivityHistoryDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findActivityHelpInfo: (
      activityQuery: cms.activityQuery,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ActivityUserHelpDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findActivityHelpTopTen: (
      activityQuery: cms.activityQuery,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ActivityInvitationInfoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findActivityBaseInfoById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.ActivityInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findActivityInfoById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.LotteryActivityDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findActivityHistoryInfo,
  findActivityHelpInfo,
  findActivityHelpTopTen,
  findActivityBaseInfoById,
  findActivityInfoById,
} as ActivityInfoApi;
