/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 广告
 */
import getAdvertTypeEnum from "./getAdvertTypeEnum";
import secondaryBanners from "./secondaryBanners";
import getLinkTypeEnum from "./getLinkTypeEnum";
import cityAdverts from "./cityAdverts";

export class AdvertApi {
  constructor(
    public getAdvertTypeEnum: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.Codebook>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public secondaryBanners: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.SecondBannerDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getLinkTypeEnum: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.Codebook>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cityAdverts: (
      type:
        | "APP_BANNER"
        | "APP_POPUP"
        | "APP_RECOMMEND_ADVERT"
        | "APP_NOTICE"
        | "APP_PROJECT_ADVERT"
        | "PAY_SUCCESS"
        | "MALL_BANNER"
        | "PC_POPUP"
        | "PC_BOTTOM_FLOAT_LAYER"
        | "PC_BANNER"
        | "FLAGSHIP_STORE_BANNER"
        | "FLAGSHIP_STORE_FALLS_ADVERT"
        | "FLAGSHIP_STORE_SLIDE_SHOW"
        | "FLAGSHIP_STORE_LATEST_NEWS"
        | "FLAGSHIP_STORE_NEWS_FOCUS"
        | "FLAGSHIP_STORE_AFFICHE"
        | "FLAGSHIP_STORE_PRODUCT"
        | "FLAGSHIP_STORE_MALL_BANNER"
        | "PC_NOTICE"
        | "CLOUD_THEATER_BANNER"
        | "CLOUD_THEATER_TOP"
        | "CLOUD_THEATER_BOTTOM"
        | "CLOUD_THEATER_VIDEO"
        | "APP_OPEN_SCREEN"
        | "LIMIT_COUPON"
        | "APP_SEARCH",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.TheaterAdvertDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getAdvertTypeEnum,
  secondaryBanners,
  getLinkTypeEnum,
  cityAdverts,
} as AdvertApi;
