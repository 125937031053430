import bestPayPaymentOrder, {
  BestPayPaymentOrderApi,
} from "./bestPayPaymentOrder";

import invoke, { InvokeApi } from "./invoke";
import bastPayNotify, { BastPayNotifyApi } from "./bastPayNotify";

import myTest, { MyTestApi } from "./myTest";

import paymentOrder, { PaymentOrderApi } from "./paymentOrder";

export class PaymentApi {
  constructor(
    public bestPayPaymentOrder: BestPayPaymentOrderApi,
    public invoke: InvokeApi,
    public myTest: MyTestApi,
    public bastPayNotify: BastPayNotifyApi,
    public paymentOrder: PaymentOrderApi
  ) {}
}

export default {
  bestPayPaymentOrder,
  invoke,
  myTest,
  bastPayNotify,
  paymentOrder,
} as PaymentApi;
