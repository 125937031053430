import Vue from "vue";
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // 外部（小程序、APP 等）跳转的页面
  {
    path: "/externalaccess",
    component: () =>
      import(
        /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/ExternalAccessLayout.vue"
      ),
    children: [
      // 外部跳转，阿里滑动验证
      {
        path: "usercheck",
        name: "externalaccessUserCheck",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/UserCheck/UserCheck.vue"
          ),
        meta: { title: "选择座位" },
      },
      // 外部跳转，文章页
      {
        path: "article",
        name: "externalaccessArticle",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Article/ExternalAccessArticlePage.vue"
          ),
      },
      // 外部跳转，联系我们
      {
        path: "personal-data/contactm",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Mine/Set/ExternalContactmPage.vue"
          ),
        meta: { title: "联系我们" },
      },
      {
        path: "projectdetail/:productId/:tourId",
        name: "externalaccessProjectDetail",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/ProjectDetail/Detail/ExternalAccessProjectDetailPage.vue"
          ),
        meta: { title: "演出详情" },
      },
      // 外部跳转，选座页
      {
        path: "selectseat",
        component: () =>
          import(
            /* webpackChunkName: "external-access-select" */ "@/views/ExternalAccess/ProjectDetail/Select/ExternalAccessSelectSeatPage.vue"
          ),
        meta: { title: "选择座位" },
      },
      // 外部跳转，选分区页
      {
        path: "selectarea/:showId/:productId",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/ProjectDetail/Select/ExternalAccessSelectAreaPage.vue"
          ),
        meta: { title: "选择区域" },
      },
      {
        path: "performance/per-order/:orderId/:productSourceEnum",
        name: "externalPerOrder",
        component: () =>
          import(
            /* webpackChunkName: "OrderList" */ "@/views/ExternalAccess/Order/ExternalAccessPreOrderPage.vue"
          ),
        meta: { title: "演出订单详情" },
      },
      // 限时抢购
      {
        path: "flash-sale",
        name: "external-flash-sale",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Activities/ExternalFlashSale.vue"
          ),
        meta: { title: "限时抢购" },
      },
      // 限时抢券
      {
        path: "limit-coupon",
        name: "external-rob-volume",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Activities/ExternalLimitCoupon.vue"
          ),
        meta: { title: "限时抢券" },
      },
      //裂变活动
      {
        path: "fission-activity",
        name: "external-fission-activity",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Activities/ExternalFissionActivity.vue"
          ),
        meta: { title: "" },
      },
      //抽奖
      {
        path: "lottery",
        name: "external-lottery",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Activities/ExternalLottery.vue"
          ),
        meta: { title: "抽奖活动" },
      },
      //红包雨首页
      {
        path: "red-envelope",
        name: "external-red-envelope",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Activities/ExternalRedEnvelope.vue"
          ),
        meta: { title: "红包雨" },
      },
      //专题管理
      {
        path: "project-management",
        name: "external-project-management",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/ProjectManagement/ExternalProjectManagement.vue"
          ),
        meta: { title: "..." },
      },
      //新专题
      {
        path: "special-theme",
        name: "external-special-theme",
        component: () =>
          import(
            /* webpackChunkName: "Special" */ "@/views/SpecialTheme/Index.vue"
          ),
        meta: { title: "..." },
      },
      //戏剧节新专题
      {
        path: "drama-theme",
        name: "external-drama-theme",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/SpecialTheme/dramaFestival.vue"
          ),
        meta: { title: "..." },
      },
      {
        path: "rank-list",
        name: "external-ranklist",
        component: () =>
          import(
            /* webpackChunkName: "search" */ "@/views/Rank/HotRankList.vue"
          ),
      },
      {
        path: "rank-temporary",
        name: "external-ranktemporary",
        component: () =>
          import(/* webpackChunkName: "search" */ "@/views/Rank/temporary.vue"),
      },
      {
        path: "project-management/special-subject",
        name: "special-subject",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/SpecialSubject/SpecialSubject.vue"
          ),
        meta: { title: "中国国际大学生时装周" },
      },
      {
        path: "project-management/drama-theme-flashSale",
        name: "external-drama-theme",
        component: () =>
          import(
            /* webpackChunkName: "SpecialSubject" */ "@/views/ExternalAccess/DramaThemeFlash/ExternalAccessDramaThemeFlashPage.vue"
          ),
        meta: { title: "保利戏聚节·聚力开票" },
      },
      {
        path: "special-subject",
        name: "special-subject",
        component: () =>
          import(
            /* webpackChunkName: "SpecialSubject" */ "@/views/ExternalAccess/SpecialSubject/SpecialSubject.vue"
          ),
        meta: { title: "中国国际大学生时装周" },
      },
      //活动报名首页
      {
        path: "club",
        name: "club",
        component: () =>
          import(
            /* webpackChunkName: "Club" */ "@/views/Mine/MoreServices/Club/Club.vue"
          ),
        meta: { title: "剧乐部活动" },
      },
      //活动报名详情页
      {
        path: "club-detail",
        name: "club-detail",
        component: () =>
          import(
            /* webpackChunkName: "Club" */ "@/views/ExternalAccess/Activities/ExternalClubDetail.vue"
          ),
        meta: { title: "活动详情" },
      },
      //活动报名成功
      {
        path: "club-success",
        name: "club-success",
        component: () =>
          import(
            /* webpackChunkName: "Club" */ "@/views/Mine/MoreServices/Club/EnterSuccess.vue"
          ),
        meta: { title: "活动报名成功" },
      },
      //集印花活动列表页
      {
        path: "print-collect",
        name: "print-collect",
        component: () =>
          import(
            /* webpackChunkName: "print" */ "@/views/ExternalAccess/Activities/ExternalPrintCollect.vue"
          ),
        meta: { title: "集印花活动" },
      },
      //集印花活动详情页
      {
        path: "print-activity-detail",
        name: "printActivity",
        component: () =>
          import(
            /* webpackChunkName: "print" */ "@/views/ExternalAccess/Activities/ExternalPrintActDetail.vue"
          ),
        meta: { title: "集印花活动" },
      },
      // 链接型优惠券
      {
        path: "coupon-link",
        name: "couponLink",
        component: () =>
          import(
            /* webpackChunkName: "externalaccess" */ "@/views/ExternalAccess/Activities/ExternalLinkCoupon.vue"
          ),
        meta: { title: "..." },
      },
      {
        path: "coupons-center",
        name: "externalaccess-coupons-center",
        component: () =>
          import(
            /* webpackChunkName: "MyCoupons" */ "@/views/ExternalAccess/Mine/ExternalCouponCenterPage.vue"
          ),
      },
      // 票夹列表
      {
        path: "wallet",
        name: "externalaccesswallet",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "@/views/ExternalAccess/Mine/Wallet/ExternalWalletPage.vue"
          ),
      },
      {
        path: "wallet/details/:id",
        name: "externalaccessWalletDetails",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "@/views/ExternalAccess/Mine/Wallet/ExternalWalletDetailPage.vue"
          ),
      },
      // 收货地址
      {
        path: "address",
        name: "externalaccessaddress",
        component: () =>
          import(
            /* webpackChunkName: "Address" */ "@/views/ExternalAccess/Mine/ExternalAddressPage.vue"
          ),
        meta: { title: "收货地址" },
      },
      {
        path: "add-address",
        name: "externalaccess-add-address",
        component: () =>
          import(
            /* webpackChunkName: "Address" */ "@/views/ExternalAccess/Mine/ExternalAddAddressPage.vue"
          ),
        meta: { title: "添加收货地址" },
      },
      {
        path: "chose-address",
        name: "externalaccess-chose-address",
        component: () =>
          import(
            /* webpackChunkName: "Address" */ "@/views/ExternalAccess/Mine/ExternalChoseAddressPage.vue"
          ),
        meta: { title: "选择收货地址" },
      },
      // 添加观演人
      {
        path: "add-audience",
        name: "externalaccess-add-audience",
        component: () =>
          import(
            /* webpackChunkName: "AudienceList" */ "@/views/ExternalAccess/Mine/ExternalAddAudiencePage.vue"
          ),
        meta: { title: "添加观演人" },
      },
      // 常用观演人
      {
        path: "audience-list",
        name: "externalaccess-audience-list",
        component: () =>
          import(
            /* webpackChunkName: "AudienceList" */ "@/views/ExternalAccess/Mine/ExternalAudienceListPage.vue"
          ),
        meta: { title: "常用观演人" },
      },
      // 收藏订阅
      {
        path: "mycollections",
        name: "externalaccess-mycollections",
        component: () =>
          import(
            /* webpackChunkName: "collect" */ "@/views/ExternalAccess/Mine/ExternalCollectionPage.vue"
          ),
        meta: { title: "收藏订阅" },
      },
      {
        path: "account",
        name: "externalaccess-account",
        component: () =>
          import(
            /* webpackChunkName: "personal" */ "@/views/ExternalAccess/Mine/ExternalAccountPage.vue"
          ),
        meta: { title: "账号与安全" },
      },
      // 优惠券使用
      {
        path: "my-coupons",
        name: "externalaccess-mycoupons",
        component: () =>
          import(
            /* webpackChunkName: "personal" */ "@/views/ExternalAccess/Mine/ExternalMyCouponsPage.vue"
          ),
        meta: { title: "我的优惠券" },
      },
      {
        path: "write-comment/:productId",
        name: "externalaccess-write-comment",
        component: () =>
          import(
            /* webpackChunkName: "MyCommnet" */ "@/views/ExternalAccess/Mine/ExternalWriteCommentPage.vue"
          ),
      },
    ],
  },
  // 渠道分销
  {
    path: "/channeldistribution",
    name: "channelDistributionLayout",
    component: () =>
      import(
        /* webpackChunkName: "channelDistributionLayout" */ "@/layout/ChannelDistributionLayout.vue"
      ),
    children: [
      // 渠道分销-通用模板
      {
        path: "common",
        name: "common",
        component: () =>
          import(
            /* webpackChunkName: "channelDistributionCommonPage" */ "@/views/ChannelDistribution/ChannelDistributionCommonPage.vue"
          ),
      },
      // 渠道分销-自定义模板
      {
        path: "custom",
        name: "custom",
        component: () =>
          import(
            /* webpackChunkName: "channelDistributionCustomPage" */ "@/views/ChannelDistribution/ChannelDistributionCustomPage.vue"
          ),
        meta: { title: "保利票务" },
      },
      {
        path: "mine",
        name: "mine",
        component: () =>
          import(/* webpackChunkName: "mine" */ "@/views/Mine.vue"),
        meta: { title: "个人中心" },
      },
    ],
  },
  // 渠道分销-搜索页
  {
    path: "/channeldistributionsearch",
    name: "channeldistributionsearch",
    component: () =>
      import(
        /* webpackChunkName: "channeldistributionsearch" */ "@/views/ChannelDistribution/ChannelDistributionSearchPage.vue"
      ),
  },
  // 文章页
  {
    path: "/article",
    name: "article",
    component: () =>
      import(
        /* webpackChunkName: "article" */ "@/views/Article/ArticlePage.vue"
      ),
  },
  {
    path: "/",
    redirect: "/home",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/layout/MainLayout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
      // 商城
      {
        path: "/mall",
        name: "mall",
        component: () =>
          import(/* webpackChunkName: "mall" */ "@/views/Mall.vue"),
      },
      // 我的
      {
        path: "/mine",
        name: "mine",
        component: () =>
          import(/* webpackChunkName: "mine" */ "@/views/Mine.vue"),
        meta: { title: "个人中心" },
      },
      // 分类页
      {
        path: "/classify",
        name: "classify",
        component: () =>
          import(
            /* webpackChunkName: "classify" */ "@/views/Classify/ClassFication.vue"
          ),
      },
    ],
  },
  {
    path: "/wechat/oauth",
    name: "wechatOauth",
    component: () =>
      import(
        /* webpackChunkName: "wechatOauth" */ "@/views/Login/WechatOauthMiddlePage.vue"
      ),
  },
  // 通用联合登录中间页
  {
    path: "/other/oauth",
    name: "otherCommonOauth",
    component: () =>
      import(
        /* webpackChunkName: "otherCommonOauth" */ "@/views/Login/OtherCommonOauthMiddlePage.vue"
      ),
  },
  // 搜索页
  {
    path: "/search/:sign",
    name: "search",
    component: () =>
      import(
        /* webpackChunkName: "searchPage" */ "@/views/Searchs/SearchPage.vue"
      ),
  },
  {
    path: "/paysuccess/:sign",
    name: "paysuccess",
    component: () =>
      import(
        /* webpackChunkName: "paySuccess" */ "@/views/Payment/PaySuccess.vue"
      ),
    meta: { title: "支付结果" },
  },
  {
    path: "/rank-detail/:detailId/:rankIndex",
    name: "rankdetail",
    component: () =>
      import(/* webpackChunkName: "rank" */ "@/views/Rank/RankDetail.vue"),
  },
  {
    path: "/rank-temporary",
    name: "ranktemporary",
    component: () =>
      import(/* webpackChunkName: "search" */ "@/views/Rank/temporary.vue"),
  },
  {
    path: "/rank-list",
    name: "ranklist",
    component: () =>
      import(/* webpackChunkName: "rank" */ "@/views/Rank/HotRankList.vue"),
  },
  //  娱伯牙
  {
    path: "/Yoboya-list",
    name: "Yoboyalist",
    component: () =>
      import(/* webpackChunkName: "yuboya" */ "@/views/Yuboya/YuboyaList.vue"),
  },
  //  娱伯牙
  //   {
  //     path: "/Yoboya-detail/:id",
  //     name: "YoboyaDetail",
  //     component: () =>
  //       import(
  //         /* webpackChunkName: "yuboya" */ "@/views/Yuboya/YuboyaDetail.vue"
  //       ),
  //   },
  // 演出详情
  {
    path: "/projectdetail/:productId/:tourId",
    name: "projectdetail",
    component: () =>
      import(
        /* webpackChunkName: "ProjectDetail" */ "@/views/ProjectDetail/ProDetail.vue"
      ),
  },
  {
    path: "/orderconfirm/:uuid",
    name: "orderconfirm",
    component: () =>
      import(
        /* webpackChunkName: "orderConfirm" */ "@/views/TicketOrder/OrderConfirm.vue"
      ),
    meta: { title: "确认订单", keepAlive: false },
  },
  {
    path: "/payconfirm",
    name: "payconfirm",
    component: () =>
      import(
        /* webpackChunkName: "payConfirm" */ "@/views/Payment/PayConfirm.vue"
      ),
    meta: { title: "确认支付" },
  },
  // 其他支付方式（翼支付）
  {
    path: "/other/pay",
    name: "otherCommonPay",
    component: () =>
      import(
        /* webpackChunkName: "otherCommonPay" */ "@/views/Payment/PayWay/OtherCommonPay.vue"
      ),
    meta: { title: "确认支付" },
  },
  // 会员中心
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(
        /* webpackChunkName: "membership" */ "@/views/Mine/Membership/Membership.vue"
      ),
    meta: { title: "会员中心" },
  },
  // 会员中心成长值
  {
    path: "/member-records",
    name: "memberRecords",
    component: () =>
      import(
        /* webpackChunkName: "membership" */ "@/views/Mine/Membership/growValue.vue"
      ),
  },
  // 领券中心
  {
    path: "/coupon-center",
    name: "coupon-center",
    component: () =>
      import(
        /* webpackChunkName: "couponCenter" */ "@/views/Mine/AssetCenter/Coupon/CouponCenter.vue"
      ),
  },
  // 个人中心--我的优惠券
  {
    path: "/my-coupons",
    name: "my-coupons",
    component: () =>
      import(
        /* webpackChunkName: "myCoupons" */ "@/views/Mine/AssetCenter/Coupon/MyCoupons.vue"
      ),
    meta: { title: "我的优惠券" },
  },
  {
    path: "/personal-data/order",
    name: "orderList", //
    component: () =>
      import(
        /* webpackChunkName: "orderList" */ "@/views/Mine/MyOrderList/OrderList.vue"
      ),
    meta: {
      title: "我的订单",
    },
  },
  // 票夹
  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */ "@/views/Mine/MoreServices/Wallet/Wallet.vue"
      ),
  },
  {
    path: "/wallet/details/:id",
    name: "walletDetails",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */ "@/views/Mine/MoreServices/Wallet/Details.vue"
      ),
  },
  // 个人中心--我的积分
  {
    path: "/integral",
    name: "Integral",
    component: () =>
      import(
        /* webpackChunkName: "integral" */ "@/views/Mine/AssetCenter/PlatIntegral/integral.vue"
      ),
  },

  // 限时抢购详情页
  {
    path: "/activity/:activityId/:productId/:tourId",
    name: "projectdetail",
    component: () =>
      import(
        /* webpackChunkName: "ProjectDetail" */ "@/views/ProjectDetail/ActivityDetail.vue"
      ),
  },
  {
    path: "/map",
    name: "map",
    component: () =>
      import(/* webpackChunkName: "other" */ "@/components/Map.vue"),
  },
  // 巡演聚合页
  {
    path: "/tour/:tourId",
    name: "tour",
    component: () =>
      import(/* webpackChunkName: "tour" */ "@/views/Tour/Tour.vue"),
    meta: { title: "巡演详情" },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/Login.vue"),
  },
  // 选座城市页
  {
    path: "/selectcity",
    name: "selectcity",
    component: () =>
      import(
        /* webpackChunkName: "selectcity" */ "@/components/SelectCity.vue"
      ),
  },

  {
    path: "/mobileLogin",
    name: "mobileLogin",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/MobileLogin.vue"),
  },
  // {
  //   path: "/wechat/login",
  //   name: "wechatlogin",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "wechatlogin" */ "@/views/Login/MobileLogin.vue"
  //     ),
  // },
  // 隐私政策
  // {
  //   path: "/policy",
  //   name: "policy",
  //   component: () =>
  //     import(/* webpackChunkName: "policy" */ "@/views/Reminder/Policy.vue"),
  // },
  // 服务政策
  // {
  //   path: "/service",
  //   name: "service",
  //   component: () =>
  //     import(/* webpackChunkName: "policy" */ "@/views/Reminder/Policy.vue"),
  // },
  // 点击金刚区更多进入的页面
  {
    path: "/more",
    name: "more",
    component: () =>
      import(/* webpackChunkName: "more" */ "@/views/More/More.vue"),
  },
  {
    path: "/select/:showId/:productId",
    name: "select",
    component: () =>
      import(
        /* webpackChunkName: "select" */ "@/views/Select/SelectAreaPage.vue"
      ),
    meta: { title: "选择区域" },
  },
  {
    path: "/select-seat",
    name: "select-seat",
    component: () =>
      import(
        /* webpackChunkName: "select" */ "@/views/Select/SelectSeatPage.vue"
      ),
    meta: { title: "选择座位" },
  },
  {
    path: "/select-seat-middle-page",
    name: "selectSeatMiddlePage",
    component: () =>
      import(
        /* webpackChunkName: "selectSeatMiddlePage" */ "@/views/Select/SelectSeatMiddlePage.vue"
      ),
  },
  {
    path: "/wechatbind",
    name: "wechatbind",
    component: () =>
      import(
        /* webpackChunkName: "wechatbind" */ "@/views/Login/WechatBind.vue"
      ),
  },
  // 收货地址
  {
    path: "/address",
    name: "address",
    component: () =>
      import(
        /* webpackChunkName: "Address" */ "@/views/Mine/MoreServices/Address/MyAddressList.vue"
      ),
    meta: { title: "收货地址" },
  },
  // 常用观演人
  {
    path: "/audience-list",
    name: "audience-list",
    component: () =>
      import(
        /* webpackChunkName: "AudienceList" */ "@/views/Mine/MoreServices/Audience/AudienceList.vue"
      ),
    meta: { title: "常用观演人" },
  },
  // 开具发票
  {
    path: "/invoice/:orderId/:type",
    name: "incoice",
    component: () =>
      import(/* webpackChunkName: "Incoice" */ "@/views/Invoice/Invoice.vue"),
    meta: { title: "开具发票" },
  },
  // 发票信息
  {
    path: "/invoice-detail",
    name: "incoice-detail",
    component: () =>
      import(
        /* webpackChunkName: "IncoiceDetail" */ "@/views/Invoice/Detail.vue"
      ),
    meta: { title: "发票信息" },
  },
  // 礼品卡
  {
    path: "/gift-card",
    name: "giftCard",
    component: () =>
      import(
        /* webpackChunkName: "giftCard" */ "@/views/Mine/AssetCenter/GiftCard/GiftCard.vue"
      ),
    meta: { title: "礼品卡" },
  },
  // 绑定卡
  {
    path: "/bind-card",
    name: "bind-card",
    component: () =>
      import(
        /* webpackChunkName: "giftCard" */ "@/views/Mine/AssetCenter/GiftCard/BindGiftCard.vue"
      ),
  },
  // 可用礼品卡
  {
    path: "/available-card/:theaterId",
    name: "availableCard",
    component: () =>
      import(
        /* webpackChunkName: "giftCard" */ "@/views/Mine/AssetCenter/GiftCard/AvailableGiftCard.vue"
      ),
  },
  // 优惠券详情
  {
    path: "/coupon-detail",
    name: "coupon-detail",
    component: () =>
      import(
        /* webpackChunkName: "CouponDetail" */ "@/views/Mine/AssetCenter/Coupon/Detail.vue"
      ),
  },
  // 个人中心--可用优惠券列表
  {
    path: "/coupons-use",
    name: "coupons-use",
    component: () =>
      import(
        /* webpackChunkName: "MyCoupons" */ "@/views/Mine/AssetCenter/Coupon/CouponUseItem.vue"
      ),
  },
  // 商品分享
  {
    path: "/share",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "Share" */ "@/views/Share/Share.vue"),
  },
  {
    path: "/personal-data/index",
    name: "personal-data",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/PersonalData/Index.vue"
      ),
    meta: { title: "个人资料" },
  },
  {
    path: "/personal-data/editor-name",
    name: "editor-name",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/PersonalData/EditorName.vue"
      ),
  },
  {
    path: "/personal-data/edit-photo",
    name: "edit-photo",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/PersonalData/EditPhoto.vue"
      ),
  },
  // 设置
  {
    path: "/personal-data/set",
    name: "set",
    component: () =>
      import(/* webpackChunkName: "Address" */ "@/views/Mine/Set/Set.vue"),
    meta: { title: "设置" },
  },

  // 账户与安全
  {
    path: "/personal-data/account",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "personal" */ "@/views/Mine/Set/Account.vue"),
    meta: { title: "账号与安全" },
  },
  // 实名认证
  {
    path: "/personal-data/real-name/:type",
    name: "real-name",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/Set/Account/RealName.vue"
      ),
    meta: { title: "实名认证" },
  },
  // 更换手机号
  {
    path: "/personal-data/change-phone",
    name: "change-phone",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/Set/Account/ChangePhone.vue"
      ),
    meta: { title: "更换手机号" },
  },
  // 联系我们
  {
    path: "/personal-data/contactm",
    name: "contactm",
    component: () =>
      import(
        /* webpackChunkName: "cntactmPage" */ "@/views/Mine/Set/ContactmPage.vue"
      ),
    meta: { title: "联系我们" },
  },
  {
    path: "/personal-data/feedback",
    name: "feedback",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/Set/Feedback.vue"
      ),
  },

  {
    path: "/personal-data/opinion",
    name: "opinion",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/Set/Feedback/Opinion.vue"
      ),
  },
  {
    path: "/personal-data/complain",
    name: "complain",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/Set/Feedback/Complain.vue"
      ),
  },
  {
    path: "/personal-data/logout",
    name: "logout",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/Mine/Set/Feedback/Logout.vue"
      ),
  },
  // 关于
  {
    path: "/personal-data/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "personal" */ "@/views/Mine/Set/About.vue"),
  },

  {
    path: "/externalaccess/personal-data/notice",
    name: "externalaccessnotice",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ "@/views/ExternalAccess/Notice/ExternalAccessNoticePage.vue"
      ),
  },
  {
    path: "/personal-data/notice",
    name: "notice",
    component: () =>
      import(
        /* webpackChunkName: "noticePage" */ "@/views/Mine/Set/About/NoticePage.vue"
      ),
  },
  {
    path: "/my-comment",
    name: "my-comment",
    component: () =>
      import(
        /* webpackChunkName: "MyCommnet" */ "@/views/Mine/MoreServices/Comment/MyCommentList.vue"
      ),
    meta: { title: "我的评价" },
  },
  {
    path: "/write-comment/:productId",
    name: "write-comment",
    component: () =>
      import(
        /* webpackChunkName: "MyCommnet" */ "@/views/Mine/MoreServices/Comment/WriteComment.vue"
      ),
  },
  {
    path: "/product-comment/:productId",
    name: "product-comment",
    component: () =>
      import(
        /* webpackChunkName: "MyCommnet" */ "@/views/Mine/MoreServices/Comment/ProCommentList.vue"
      ),
  },
  // 余额
  {
    path: "/electronicWallet/index",
    name: "electronicWallet",
    component: () =>
      import(
        /* webpackChunkName: "electronicWallet" */ "@/views/Mine/AssetCenter/ElectronicWallet/index.vue"
      ),
    meta: { title: "余额" },
  },
  // 余额身份校验
  {
    path: "/electronicWallet/check",
    name: "checkUser",
    component: () =>
      import(
        /* webpackChunkName: "electronicWallet" */ "@/views/Mine/AssetCenter/ElectronicWallet/checkUse.vue"
      ),
    meta: { title: "余额" },
  },
  // 设置支付密码
  {
    path: "/set-password/:uuid",
    name: "setPassword",
    component: () =>
      import(
        /* webpackChunkName: "electronicWallet" */ "@/views/Mine/AssetCenter/ElectronicWallet/setPassWord.vue"
      ),
    meta: { title: "余额" },
  },
  // 余额充值结果页面（即：后端配置的 return url）
  {
    path: "/recharge-status",
    name: "rechargeStatus",
    component: () =>
      import(
        /* webpackChunkName: "electronicWallet" */ "@/views/Mine/AssetCenter/ElectronicWallet/Components/RechargeResult.vue"
      ),
    meta: { title: "余额" },
  },
  // 余额卡申请提现
  {
    path: "/wallet-apply-cash",
    name: "walletApplyCash",
    component: () =>
      import(
        /* webpackChunkName: "electronicWallet" */ "@/views/Mine/AssetCenter/ElectronicWallet/walletApplyCash.vue"
      ),
    meta: { title: "余额" },
  },
  // 个人中心--集印花
  {
    path: "/collect-printing",
    name: "collectPrinting",
    component: () =>
      import(
        /* webpackChunkName: "collectPrinting" */ "@/views/Mine/MoreServices/CollectionPrinting/CollectionPrinting.vue"
      ),
  },
  // 集印花-活动页
  {
    path: "/print-activity",
    name: "printActivity",
    component: () =>
      import(
        /* webpackChunkName: "printActivity" */ "@/views/Mine/MoreServices/CollectionPrinting/ActivityDetail.vue"
      ),
  },
  // 集印花-兑换商品
  {
    path: "/print-exchange",
    name: "printExchange",
    component: () =>
      import(
        /* webpackChunkName: "printActivity" */ "@/views/Mine/MoreServices/CollectionPrinting/ExchangeGood.vue"
      ),
    meta: { title: "兑换好礼" },
  },
  {
    path: "/print-records",
    name: "printRecords",
    component: () =>
      import(
        /* webpackChunkName: "printActivity" */ "@/views/Mine/MoreServices/CollectionPrinting/PrintRecords.vue"
      ),
    meta: { title: "印花记录" },
  },

  // 集印花-商品-订单详情
  {
    path: "/print-order-detail",
    name: "printOrderDetail",
    component: () =>
      import(
        /* webpackChunkName: "printActivity" */ "@/views/Mine/MoreServices/CollectionPrinting/printOrderDetail.vue"
      ),
  },
  //会员专享
  {
    path: "/mall/vip-exclusive",
    name: "vip-exclusive",
    component: () =>
      import(
        /* webpackChunkName: "mall" */ "@/views/Mall/MallPage/VipExclusive.vue"
      ),
  },
  // 商品详情
  {
    path: "/mall/details",
    name: "mall-details",
    component: () =>
      import(/* webpackChunkName: "mall" */ "@/views/Mall/MallDetails.vue"),
    meta: { title: "商品详情" },
  },
  {
    path: "/mall/details-img",
    name: "mall-details-img",
    component: () =>
      import(/* webpackChunkName: "mall" */ "@/views/Mall/imgDetails.vue"),
  },
  //商城订单各种状态等待付款
  {
    path: "/mall/m-order/:orderId",
    name: "m-order",
    component: () =>
      import(/* webpackChunkName: "mall" */ "@/views/Mall/MallOrder.vue"),
    meta: { title: "商品订单详情" },
  },
  //商城预订单
  {
    path: "/mall/confirm-order",
    name: "confirm-order",
    component: () =>
      import(/* webpackChunkName: "mall" */ "@/views/Mall/ConfirmOrder.vue"),
    meta: { title: "确认订单" },
  },
  //演出订单各种状态等待付款
  {
    path: "/performance/per-order/:orderId/:productSourceEnum",
    name: "per-order",
    component: () =>
      import(
        /* webpackChunkName: "OrderList" */ "@/views/TicketOrder/PerOrderDetails/PerOrder.vue"
      ),
    meta: { title: "演出订单详情" },
  },
  // 收藏订阅
  {
    path: "/personal-data/mycollections",
    name: "mycollections",
    component: () =>
      import(
        /* webpackChunkName: "collect" */ "@/views/myCollections/myCollections.vue"
      ),
    meta: { title: "我的收藏" },
  },
  // 限时抢卷
  {
    path: "/limit-coupon",
    name: "rob-volume",
    component: () =>
      import(
        /* webpackChunkName: "limitCoupon" */ "@/views/LimitActivities/LimitCoupon.vue"
      ),
    meta: { title: "限时抢券" },
  },
  //周边商品
  {
    path: "/peripheral-goods/:id/:type/:title/:num",
    name: "peripheral-goods",
    component: () =>
      import(
        /* webpackChunkName: "peripheralGoods" */ "@/views/Tour/PeripheralGoods.vue"
      ),
  },
  //app下载
  {
    path: "/mine/download-app",
    name: "download-app",
    component: () =>
      import(
        /* webpackChunkName: "downloadApp" */ "@/views/Mine/DownloadApp.vue"
      ),
  },
  // 限时抢购
  {
    path: "/flash-sale",
    name: "flash-sale",
    component: () =>
      import(
        /* webpackChunkName: "flashSale" */ "@/views/LimitActivities/FlashSale.vue"
      ),
    meta: { title: "限时抢购" },
  },
  //裂变活动
  {
    path: "/fission-activity",
    name: "fission-activity",
    component: () =>
      import(
        /* webpackChunkName: "fission" */ "@/views/LimitActivities/FissionActivity.vue"
      ),
    meta: { title: "" },
  },
  //活动报名
  {
    path: "/enter-activity",
    name: "enterActivity",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/Club.vue"
      ),
    meta: { title: "活动报名" },
  },
  //活动报名详情
  {
    path: "/enter-activity-detail",
    name: "enter-activity-detail",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/ClubDetail.vue"
      ),
    meta: { title: "活动详情" },
  },
  {
    path: "/apply-enter-activity",
    name: "apply-enter-activity",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/ClubSignUp.vue"
      ),
    meta: { title: "活动报名" },
  },
  //活动报名成功
  {
    path: "/apply-success",
    name: "apply-success",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/EnterSuccess.vue"
      ),
    meta: { title: "活动报名成功" },
  },
  // 我的活动报名详情
  {
    path: "/apply-list",
    name: "apply-list",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/ClubSignDetail.vue"
      ),
    meta: { title: "活动报名详情" },
  },
  // 活动入选名单
  {
    path: "/apply-selected-list",
    name: "apply-selected-list",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/ClubRoster.vue"
      ),
    meta: { title: "活动入选名单" },
  },
  // 活动报名票夹
  {
    path: "/apply-wallet",
    name: "apply-wallet",
    component: () =>
      import(
        /* webpackChunkName: "club" */ "@/views/Mine/MoreServices/Club/ClubWallet.vue"
      ),
    meta: { title: "活动报名票夹" },
  },
  //裂变活动的历史记录
  {
    path: "/fission-activity/fission-history",
    name: "fission-history",
    component: () =>
      import(
        /* webpackChunkName: "fission" */ "@/views/LimitActivities/FissionHistory.vue"
      ),
    meta: { title: "历史活动" },
  },
  //裂变助力结果页
  {
    path: "/fission-activity/help-results",
    name: "help-results",
    component: () =>
      import(
        /* webpackChunkName: "fission" */ "@/views/LimitActivities/FissionHelpResults.vue"
      ),
    meta: { title: "每天锦鲤就是你" },
  },
  //抽奖
  {
    path: "/lottery",
    name: "lottery",
    component: () =>
      import(
        /* webpackChunkName: "lottery" */ "@/views/LimitActivities/Lottery.vue"
      ),
    meta: { title: "抽奖活动" },
  },
  //红包雨首页
  {
    path: "/red-envelope",
    name: "red-envelope",
    component: () =>
      import(
        /* webpackChunkName: "redEnvelope" */ "@/views/LimitActivities/RedEnvelope.vue"
      ),
    meta: { title: "红包雨" },
  },
  //红包雨
  {
    path: "/red-envelope-rain",
    name: "red-envelope-rain",
    component: () =>
      import(
        /* webpackChunkName: "redEnvelope" */ "@/views/LimitActivities/RedEnvelopeRain.vue"
      ),
    meta: { title: "狂下红包雨-你准备好了吗？" },
  },
  //兑换商品详情
  {
    path: "/exchange-good-detail",
    name: "exchange-good-detail",
    component: () =>
      import(
        /* webpackChunkName: "redEnvelope" */ "../components/ExchangeGood/GoodDetail.vue"
      ),
  },
  //活动确认兑换页
  {
    path: "/exchange-confirm",
    name: "exchange-confirm",
    component: () =>
      import(
        /* webpackChunkName: "redEnvelope" */ "../components/ExchangeGood/ExchangeConfirm.vue"
      ),
  },
  //新专题
  {
    path: "/special-theme",
    name: "special-theme",
    component: () =>
      import(
        /* webpackChunkName: "Special" */ "@/views/SpecialTheme/Index.vue"
      ),
    meta: { title: "..." },
  },
  //戏剧节新专题
  {
    path: "/drama-theme",
    name: "drama-theme",
    component: () =>
      import(
        /* webpackChunkName: "Special" */ "@/views/SpecialTheme/dramaFestival.vue"
      ),
    meta: { title: "..." },
  },
  //专题管理
  {
    path: "/project-management",
    name: "project-management",
    component: () =>
      import(
        /* webpackChunkName: "Activity" */ "@/views/ProjectManagement/ProjectManagement.vue"
      ),
    meta: { title: "..." },
  },
  //优惠券链接活动
  {
    path: "/coupon-link",
    name: "coupon-link",
    component: () =>
      import(
        /* webpackChunkName: "Activity" */ "@/views/Mine/MoreServices/LinkCoupon/LinkCoupon.vue"
      ),
    meta: { title: "..." },
  },
  //云剧院
  {
    path: "/cloud-theatre-details",
    name: "cloud-theatre-details",
    component: () =>
      import(
        /* webpackChunkName: "cloud" */ "@/views/CloudTheatreDetails/CloudTheatreDetails.vue"
      ),
    meta: { title: "云剧院详情" },
  },
  //云剧院分类列表页
  {
    path: "/cloud-theatre-category-list",
    name: "cloud-theatre-category-list",
    component: () =>
      import(
        /* webpackChunkName: "cloud" */ "@/views/CloudTheatreCategoryList/CloudTheatreCategoryList.vue"
      ),
    meta: { title: "" },
  },
  //活动兑现商品
  {
    path: "/commodity-exchange",
    name: "commodity-exchange",
    component: () =>
      import(
        /* webpackChunkName: "OrderList" */ "@/views/LimitActivities/CommodityExchange.vue"
      ),
    meta: { title: "兑换商品" },
  },
  // 专题
  {
    path: "/project-management/special-subject",
    name: "special-subject",
    component: () =>
      import(
        /* webpackChunkName: "SpecialSubject" */ "@/views/Mine/MoreServices/SpecialSubject/SpecialSubject.vue"
      ),
    meta: { title: "中国国际大学生时装周" },
  },
  // 专题
  {
    path: "/project-management/drama-theme-flashSale",
    name: "drama-theme",
    component: () =>
      import(
        /* webpackChunkName: "SpecialSubject" */ "@/views/Mine/MoreServices/DramaThemeFlash/DramaThemeFlashPage.vue"
      ),
    meta: { title: "保利戏聚节·聚力开票" },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.name === "cloud-theatre-details") {
    window.location.replace(
      location.origin + "/third/#/cloud-detail/" + to.query.id
    );
    return;
  }
  if (to.name === "special-theme" || to.name === "external-special-theme") {
    window.location.replace(
      location.origin + "/third/#/special-theme/" + to.query.id
    );
    return;
  }
  // 根据路由的 meta 设置一下浏览器标题
  const title = to.meta ? to.meta.title : null;
  window.document.title = title || "爱演出 爱生活-保利票务";
  next();
});

export default router;
