/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 品牌会员卡
 */
import insMemberCard from "./insMemberCard";
import getUserClubCardList from "./getUserClubCardList";
import memberEquitiesClub, {
  MemberEquitiesClubParams,
} from "./memberEquitiesClub";

export class MemberCardApi {
  constructor(
    public insMemberCard: (
      memberCardReq: member.MemberCardReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getUserClubCardList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.ClubCardRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public memberEquitiesClub: (
      params: MemberEquitiesClubParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.MemberEquitiesDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  insMemberCard,
  getUserClubCardList,
  memberEquitiesClub,
} as MemberCardApi;
