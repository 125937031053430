/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 集印花
 */
import browseTask from "./browseTask";
import exchangeGiftsList, {
  ExchangeGiftsListParams,
} from "./exchangeGiftsList";
import getExchangePrintingList from "./getExchangePrintingList";
import deleteExchangeRecord, {
  DeleteExchangeRecordParams,
} from "./deleteExchangeRecord";
import myPrintingList, { MyPrintingListParams } from "./myPrintingList";
import myPrintingRecord, { MyPrintingRecordParams } from "./myPrintingRecord";
import selectPrintingDetail, {
  SelectPrintingDetailParams,
} from "./selectPrintingDetail";

export class PrintingApi {
  constructor(
    public browseTask: (
      request: member.BrowseTaskRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.BrowseTaskRpcResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public exchangeGiftsList: (
      params: ExchangeGiftsListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.GetExchangeGiftsListResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getExchangePrintingList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.PrintingExchangeDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public deleteExchangeRecord: (
      params: DeleteExchangeRecordParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public myPrintingList: (
      params: MyPrintingListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.JoinPrintingActivityListResponse>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public myPrintingRecord: (
      params: MyPrintingRecordParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.GetMyPrintingRecordResponse>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selectPrintingDetail: (
      params: SelectPrintingDetailParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.selectPrintingDetailResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  browseTask,
  exchangeGiftsList,
  getExchangePrintingList,
  deleteExchangeRecord,
  myPrintingList,
  myPrintingRecord,
  selectPrintingDetail,
} as PrintingApi;
