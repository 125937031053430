/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 取消王府井支付订单
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class CancelOrderParams {
  constructor(
    public orderId?: string,
    public orderType?:
      | "RECHARGE"
      | "SHOW"
      | "COMMODITY"
      | "VIDEO"
      | "CLOUD_THEATER_VIDEO"
  ) {}
}

export default async function (
  params: CancelOrderParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: boolean;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/payment/cancel`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
