import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromStorage } from "@/store/utils";

export interface LocationState {
  isChangeCity: boolean; // 是否切换了城市
  defaultCityName: string; // 默认城市名
  defaultCityCode: string; // 默认城市编码
  cityName: string; // 城市名称
  cityCode: string; // 城市编码，请求头需要使用
  longitude: number; // 经度
  latitude: number; // 纬度
}

@Module({ dynamic: true, store, name: "location" })
class Location extends VuexModule implements LocationState {
  isChangeCity = false;
  defaultCityName = "北京";
  defaultCityCode = "110100";
  cityName = "北京";
  cityCode = "110100";
  longitude = -1; // -1 表示没有定位
  latitude = -1; // -1 表示没有定位

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromStorage("vuex", "location"));
  }

  @Mutation
  setDefaultCity_PERSIST(): void {
    this.cityName = this.defaultCityName;
    this.cityCode = this.defaultCityCode;
  }

  @Mutation
  SET_isChangeCity_PERSIST(isChangeCity: boolean): void {
    this.isChangeCity = isChangeCity;
  }

  @Mutation
  SET_defaultCityCode_PERSIST(defaultCityCode: string): void {
    this.defaultCityCode = defaultCityCode;
  }

  @Mutation
  SET_city_PERSIST({
    cityName,
    cityCode,
  }: {
    cityName?: string;
    cityCode?: string;
  }) {
    this.cityName = cityName || "";
    this.cityCode = cityCode && cityCode != "0" ? cityCode : "";
  }

  @Mutation
  SET_longitude_PERSIST(longitude: number) {
    this.longitude = longitude;
  }

  @Mutation
  SET_latitude_PERSIST(latitude: number) {
    this.latitude = latitude;
  }
}

export const LocationModule = getModule(Location);
