/* eslint-disable @typescript-eslint/no-explicit-any */
import { PluginObject } from "vue";
import wx from "weixin-js-sdk";

//TODO 需要用到哪一部分API请自行添加声明文件
export interface WechatJsSdk {
  config: (option: {
    debug: boolean; // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: string; // 必填，公众号的唯一标识
    timestamp: number; // 必填，生成签名的时间戳
    nonceStr: string; // 必填，生成签名的随机串
    signature: string; // 必填，签名
    jsApiList: Array<string>; // 必填，需要使用的JS接口列表
  }) => void;
  ready: (callback: () => void) => void;
  error: (callback: (res: any) => void) => void;
  checkJsApi: (option: {
    jsApiList: Array<string>;
    success: (res: any) => void;
  }) => void;
  updateAppMessageShareData: (option: {
    title: string; // 分享标题
    desc: string; // 分享描述
    link: string; // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: string; // 分享图标
    success: () => void;
  }) => void;
}

const Plugin: PluginObject<WechatJsSdk> = {
  install: (Vue) => {
    Object.defineProperties(Vue.prototype, {
      wx: {
        get() {
          return wx as WechatJsSdk;
        },
      },
      $wechat: {
        get() {
          return wx as WechatJsSdk;
        },
      },
    });
  },
};

export default Plugin;
