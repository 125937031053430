/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 修复数据
 */
import saveOrUpdate from "./saveOrUpdate";

export class RepairApi {
  constructor(
    public saveOrUpdate: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  saveOrUpdate,
} as RepairApi;
