/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description OSS文件管理
 */
import remove from "./remove";
import getFileACL, { GetFileACLParams } from "./getFileACL";
import setFileACL from "./setFileACL";
import deleteBatch from "./deleteBatch";
import getObjectMetadata, {
  GetObjectMetadataParams,
} from "./getObjectMetadata";
import getAllFileNameByBucket from "./getAllFileNameByBucket";
import getAllFileNameByDir, {
  GetAllFileNameByDirParams,
} from "./getAllFileNameByDir";
import getFileNameAndSubDir, {
  GetFileNameAndSubDirParams,
} from "./getFileNameAndSubDir";
import isFileExist, { IsFileExistParams } from "./isFileExist";
import getSimplifiedObjectMeta, {
  GetSimplifiedObjectMetaParams,
} from "./getSimplifiedObjectMeta";

export class ManagementApi {
  constructor(
    public remove: (
      filePath: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getFileACL: (
      params: GetFileACLParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public setFileACL: (
      fileACLDto: oss.FileACLDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oss.FileACLDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public deleteBatch: (
      filesPath: Array<string>,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getObjectMetadata: (
      params: GetObjectMetadataParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oss.FileMetaDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getAllFileNameByBucket: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getAllFileNameByDir: (
      params: GetAllFileNameByDirParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getFileNameAndSubDir: (
      params: GetFileNameAndSubDirParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public isFileExist: (
      params: IsFileExistParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSimplifiedObjectMeta: (
      params: GetSimplifiedObjectMetaParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oss.SimplifiedFileMetaDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  remove,
  getFileACL,
  setFileACL,
  deleteBatch,
  getObjectMetadata,
  getAllFileNameByBucket,
  getAllFileNameByDir,
  getFileNameAndSubDir,
  isFileExist,
  getSimplifiedObjectMeta,
} as ManagementApi;
