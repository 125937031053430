/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 云剧院视频
 */
import paidVideoAll from "./paidVideoAll";
import paidVideoZone from "./paidVideoZone";
import searchVideos from "./searchVideos";
import getVideoInfoAndPatchInfoById from "./getVideoInfoAndPatchInfoById";
import videoViewingLog from "./videoViewingLog";
import getVideoInfoById from "./getVideoInfoById";
import getRecommendVideos from "./getRecommendVideos";
import getIndexRecommendVideos from "./getIndexRecommendVideos";
import getRecommendVideosNew from "./getRecommendVideosNew";

export class CloundTheaterVideoApi {
  constructor(
    public paidVideoAll: (
      condition: cloundtheater.SearchCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cloundtheater.Page<cloundtheater.VideoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public paidVideoZone: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cloundtheater.VideoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchVideos: (
      condition: cloundtheater.SearchCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cloundtheater.Page<cloundtheater.VideoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getVideoInfoAndPatchInfoById: (
      videoId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cloundtheater.patchAndVideoResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public videoViewingLog: (
      videoPlayLogRequest: cloundtheater.videoPlayLogRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getVideoInfoById: (
      videoId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cloundtheater.VideoInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getRecommendVideos: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cloundtheater.VideoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getIndexRecommendVideos: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cloundtheater.VideoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getRecommendVideosNew: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cloundtheater.VideoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  paidVideoAll,
  paidVideoZone,
  searchVideos,
  getVideoInfoAndPatchInfoById,
  videoViewingLog,
  getVideoInfoById,
  getRecommendVideos,
  getIndexRecommendVideos,
  getRecommendVideosNew,
} as CloundTheaterVideoApi;
