/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 抽奖活动接口
 */
import lottery from "./lottery";

export class LotteryApi {
  constructor(
    public lottery: (
      orderId: string,
      orderType:
        | "SHOW"
        | "RECHARGE"
        | "COMMODITY"
        | "VIDEO"
        | "ACTIVITYAPPLY"
        | "MOVIE",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.LotteryWinDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  lottery,
} as LotteryApi;
