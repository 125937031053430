import download, { DownloadApi } from "./download";

import management, { ManagementApi } from "./management";

import sts, { StsApi } from "./sts";

import upload, { UploadApi } from "./upload";

export class OssApi {
  constructor(
    public download: DownloadApi,
    public management: ManagementApi,
    public sts: StsApi,
    public upload: UploadApi
  ) {}
}

export default {
  download,
  management,
  sts,
  upload,
} as OssApi;
