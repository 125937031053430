var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"wrap"},[_c('keep-alive',{attrs:{"include":[
        'mainLayout',
        'orderList',
        'login',
        'mobileLogin',
        'wallet',
        'enterActivity',
        'ExternalClub',
        'mall',
        'searchPage' ]}},[_c('router-view')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }