/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 服务之间调用的相关接口
 */
import bind from "./bind";
import isAttention, { IsAttentionParams } from "./isAttention";
import attention, { AttentionParams } from "./attention";
import bindingInfo from "./bindingInfo";
import getProductComment, {
  GetProductCommentParams,
} from "./getProductComment";
import getIntegral from "./getIntegral";
import byKey, { ByKeyParams } from "./byKey";
import fastRegisterMember from "./fastRegisterMember";
import addOrEditMember from "./addOrEditMember";
import saveMemberLoginLog from "./saveMemberLoginLog";
import registerMemberInfoPc from "./registerMemberInfoPc";
import getThirdOauthUser, {
  GetThirdOauthUserParams,
} from "./getThirdOauthUser";
import relationThirdUser, {
  RelationThirdUserParams,
} from "./relationThirdUser";
import pointsMallRedemption from "./pointsMallRedemption";
import getTheaterMember from "./getTheaterMember";
import getTheaterName, { GetTheaterNameParams } from "./getTheaterName";
import updateTheaterGrade from "./updateTheaterGrade";
import setUserThteater from "./setUserThteater";
import getGrowthValueAfterConsumption from "./getGrowthValueAfterConsumption";
import updateTicketPurchaseGrowthValue from "./updateTicketPurchaseGrowthValue";
import currentUser from "./currentUser";
import getAllTheaterMember from "./getAllTheaterMember";
import getTheaterMemberGrade from "./getTheaterMemberGrade";
import getUserTheatreByMemberIdByTheaterId from "./getUserTheatreByMemberIdByTheaterId";
import getViewerList from "./getViewerList";
import bindWxUser from "./bindWxUser";

export class InvokeApi {
  constructor(
    public bind: (
      wxUserReq: member.WxUserReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public isAttention: (
      params: IsAttentionParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public attention: (
      params: AttentionParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.AttentionDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public bindingInfo: (
      wxUserReq: member.WxUserReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.WxUserDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductComment: (
      params: GetProductCommentParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.ProductCommentDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getIntegral: (
      integralReq: member.IntegralReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.IntegralRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public byKey: (
      params: ByKeyParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public fastRegisterMember: (
      memberRegisterDto: member.MemberRegisterDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public addOrEditMember: (
      cuUser: member.CuUser,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public saveMemberLoginLog: (
      cuMemberLoginLogRequest: member.CuMemberLoginLogRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public registerMemberInfoPc: (
      memberRegisterPCDto: member.MemberRegisterPCDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getThirdOauthUser: (
      params: GetThirdOauthUserParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUserThirdoauth;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public relationThirdUser: (
      params: RelationThirdUserParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public pointsMallRedemption: (
      pointsMallRedemptionReq: member.PointsMallRedemptionReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterMember: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.TheaterMemberDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterName: (
      params: GetTheaterNameParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public updateTheaterGrade: (
      theaterLevelReq: member.TheaterLevelReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: any;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public setUserThteater: (
      memberId: string,
      theaterId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUserTheatre;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getGrowthValueAfterConsumption: (
      ticketPurchaseGrowthValueReq: member.TicketPurchaseGrowthValueReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: number;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public updateTicketPurchaseGrowthValue: (
      ticketPurchaseGrowthValueDTO: member.TicketPurchaseGrowthValueDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public currentUser: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getAllTheaterMember: (
      memberId: string,
      theaterId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUserTheatre;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterMemberGrade: (
      memberId: string,
      theaterId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getUserTheatreByMemberIdByTheaterId: (
      memberId: string,
      theaterId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CuUserTheatre;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getViewerList: (
      viewerIds: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.Commonly>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public bindWxUser: (
      wxUserReq: member.WxUserReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  bind,
  isAttention,
  attention,
  bindingInfo,
  getProductComment,
  getIntegral,
  byKey,
  fastRegisterMember,
  addOrEditMember,
  saveMemberLoginLog,
  registerMemberInfoPc,
  getThirdOauthUser,
  relationThirdUser,
  pointsMallRedemption,
  getTheaterMember,
  getTheaterName,
  updateTheaterGrade,
  setUserThteater,
  getGrowthValueAfterConsumption,
  updateTicketPurchaseGrowthValue,
  currentUser,
  getAllTheaterMember,
  getTheaterMemberGrade,
  getUserTheatreByMemberIdByTheaterId,
  getViewerList,
  bindWxUser,
} as InvokeApi;
