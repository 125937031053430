/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 视频点播订单接口
 */
import getOrderDetailVideo from "./getOrderDetailVideo";

export class VideoOrderApi {
  constructor(
    public getOrderDetailVideo: (
      videoId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.VideoOrderInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getOrderDetailVideo,
} as VideoOrderApi;
