import Vue from "vue";
import { MenuModule } from "@/store/modules/menu";
import config from "./config";

export function Initializer(): void {
  MenuModule.init_PERSIST(Vue.ls.get("menus", config.menus));
}

export function Persistor(): void {
  Vue.ls.set("menus", MenuModule.menus);
}
