/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 节假日
 */
import findHolidaysByYearOrMoon, {
  FindHolidaysByYearOrMoonParams,
} from "./findHolidaysByYearOrMoon";

export class HolidaysApi {
  constructor(
    public findHolidaysByYearOrMoon: (
      year: string,

      params: FindHolidaysByYearOrMoonParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.Holidays>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findHolidaysByYearOrMoon,
} as HolidaysApi;
