import appletsUser, { AppletsUserApi } from "./appletsUser";

import socialLogin, { SocialLoginApi } from "./socialLogin";

import token, { TokenApi } from "./token";

export class Oauth2Api {
  constructor(
    public appletsUser: AppletsUserApi,
    public socialLogin: SocialLoginApi,
    public token: TokenApi
  ) {}
}

export default {
  appletsUser,
  socialLogin,
  token,
} as Oauth2Api;
