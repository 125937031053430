/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 商城订单接口
 */
import createGoodAdvance from "./createGoodAdvance";
import getGoodAdvance from "./getGoodAdvance";
import createGoodOrder from "./createGoodOrder";
import getOrderDetailGood from "./getOrderDetailGood";
import cancelOrder from "./cancelOrder";
import orderConfirmReceipt from "./orderConfirmReceipt";
import deleteGoodOrder from "./deleteGoodOrder";
import verifyOrder from "./verifyOrder";
import createIntegralmallOrderNew from "./createIntegralmallOrderNew";

export class GoodOrderApi {
  constructor(
    public createGoodAdvance: (
      goodAdvanceReq: order.GoodAdvanceReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getGoodAdvance: (
      uuid: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.GoodAdvanceRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public createGoodOrder: (
      createGoodOrder: order.CreateGoodOrder,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.CreateGoodOrderRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderDetailGood: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.EsOrderDetailGoodInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cancelOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderConfirmReceipt: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public deleteGoodOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public verifyOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public createIntegralmallOrderNew: (
      goodAdvanceReq: order.GoodAdvanceReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  createGoodAdvance,
  getGoodAdvance,
  createGoodOrder,
  getOrderDetailGood,
  cancelOrder,
  orderConfirmReceipt,
  deleteGoodOrder,
  verifyOrder,
  createIntegralmallOrderNew,
} as GoodOrderApi;
