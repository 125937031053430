import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface SpecialManagementState {
  commodityId: string; // 商品 ID
  commodityKeywords: string; //商品关键词
  productId: string; // 票务 ID
  productKeywords: string; //票务关键词
  subjectCommodity: string; //关联商品类型 = ['COMMODITY_CLASSIFY', 'COMMODITY', 'COMMODITY_KEYWORD'],
  subjectProduct: string; // 关联项目类型 = ['PRODUCT_CLASSIFY', 'PRODUCT', 'PRODUCT_KEYWORD'],
  openButtonImg: string; //展开图
  closeButtonImg: string; //收取图
}

@Module({ dynamic: true, store, name: "SpecialManagement" })
class Special extends VuexModule implements SpecialManagementState {
  commodityId = "";
  commodityKeywords = "";
  productId = "";
  productKeywords = "";
  subjectCommodity = "";
  subjectProduct = "";
  openButtonImg = "";
  closeButtonImg = "";
  @Mutation
  SET_Special({
    commodityId,
    commodityKeywords,
    productId,
    productKeywords,
    subjectCommodity,
    subjectProduct,
    openButtonImg,
    closeButtonImg,
  }: {
    commodityId?: string;
    commodityKeywords?: string;
    productId?: string;
    productKeywords?: string;
    subjectCommodity?: string;
    subjectProduct?: string;
    openButtonImg?: string;
    closeButtonImg?: string;
  }) {
    this.commodityId = commodityId || "";
    this.commodityKeywords = commodityKeywords || "";
    this.productId = productId || "";
    this.productKeywords = productKeywords || "";
    this.subjectCommodity = subjectCommodity || "";
    this.subjectProduct = subjectProduct || "";
    this.openButtonImg = openButtonImg || "";
    this.closeButtonImg = closeButtonImg || "";
  }
}

export const SpecialManagementModule = getModule(Special);
