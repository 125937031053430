import Vue from "vue";
import Vuex from "vuex";
import { MenuState } from "./modules/menu";
import { UserState } from "./modules/user";
// import { productState } from "./modules/rProductId";

import { SelectSeatState } from "./modules/selectSeat";
import { SpecialManagementState } from "./modules/SpecialManagement";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export interface RootState {
  menu: MenuState;
  user: UserState;
  selectSeat: SelectSeatState;
  SpecialManagementState: SpecialManagementState;
  // productId: productState;
}

export default new Vuex.Store<RootState>({
  // plugins: [vuexLocal],
  getters: {
    menus: (state: RootState) => state.menu.menus,
    // productId: (state: RootState) => state.productId.productId,
    // token: (state: RootState) => state.user.token,
  },
  plugins: [
    createPersistedState({
      paths: [
        "menu",
        "user",
        "location",
        "dictionary",
        "deleteLockSeat",
        "rProductId",
        "interfaceCache", // 接口缓存
        "projectDetailCache",
      ],
      filter: ({ type }: { type: string }) => {
        return /^.*_PERSIST$/.test(type);
      },
    }),
    createPersistedState({
      paths: [
        "routeRecord",
        "homeData",
        "externalAccess",
        "channel",
        "theater",
        "selectSection",
        "channelDistribution",
        "rProductId",
        "channelFrom",
      ],
      filter: ({ type }: { type: string }) => {
        return /^.*_PERSIST$/.test(type);
      },
      storage: window.sessionStorage,
    }),
  ],
});
