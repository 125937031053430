import dayjs from "dayjs";

/**
 * 计算过期时间
 * @param expireInterval 过期间隔，默认 600 秒=
 * @param randNum 随机数 过期间隔 默认是 0秒
 */
export const getExpireTime = (expireInterval = 600, randNum = 0): number => {
  // const rand = randNum;
  return dayjs()
    .add(expireInterval + randNum, "second")
    .unix();
};

// 是否过期
export const isExpire = (expireTime?: number): boolean => {
  return !expireTime || dayjs().unix() >= expireTime;
};

// 根据字段名生成缓存过期时间字段的名称
export const genExpireKeyName = (name: string): string => {
  return name + "Expire";
};
