import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromStorage } from "@/store/utils";

export interface ProjectDetailCacheState {
  cacheProductIds: Array<number>;
  projectDetail: good.ProjectDto;
}

@Module({ dynamic: true, store, name: "projectDetailCache" })
class ProjectDetailCache extends VuexModule implements ProjectDetailCacheState {
  cacheProductIds: Array<number> = [];
  projectDetail: good.ProjectDto = {};

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromStorage("vuex", "projectDetailCache"));
  }

  @Mutation
  SET_projectDetail(projectDetail: good.ProjectDto) {
    this.projectDetail = projectDetail;
  }
}

export const ProjectDetailCacheModule = getModule(ProjectDetailCache);
