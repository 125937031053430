/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 剧院城市接口
 */
import getPlaceCities from "./getPlaceCities";
import getCitiesNotFirstKey from "./getCitiesNotFirstKey";
import getPlaceCityProduct from "./getPlaceCityProduct";

export class PlaceCityApi {
  constructor(
    public getPlaceCities: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: dictionary.CityGroupDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCitiesNotFirstKey: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.PlaceCityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPlaceCityProduct: (
      cityCode: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getPlaceCities,
  getCitiesNotFirstKey,
  getPlaceCityProduct,
} as PlaceCityApi;
