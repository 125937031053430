/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description OSS文件下载
 */
import pointDownload from "./pointDownload";
import download from "./download";

export class DownloadApi {
  constructor(
    public pointDownload: (
      pointDownloadDto: oss.PointDownloadDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public download: (
      downloadDto: oss.DownloadDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  pointDownload,
  download,
} as DownloadApi;
