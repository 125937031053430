/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 提交商品订单
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  createGoodOrder: order.CreateGoodOrder,

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: order.CreateGoodOrderRes;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "post",
    url: `/order/good-order`,
    data: createGoodOrder,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
