/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 旗舰店 广告, 公告
 */
import findBottomContentById from "./findBottomContentById";
import findBottomContentInfo from "./findBottomContentInfo";
import findBottomHeadInfo from "./findBottomHeadInfo";
import findShopHelpCenter from "./findShopHelpCenter";
import findInformationInfo from "./findInformationInfo";
import findInformation from "./findInformation";
import findFlagshipStoreAdvert from "./findFlagshipStoreAdvert";

export class ShopApi {
  constructor(
    public findBottomContentById: (
      documentsId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findBottomContentInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.BottomInfoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findBottomHeadInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.BottomInfoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findShopHelpCenter: (
      documentsId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ShopHelpCenterDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findInformationInfo: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findInformation: (
      type:
        | "APP_BANNER"
        | "APP_POPUP"
        | "APP_RECOMMEND_ADVERT"
        | "APP_NOTICE"
        | "APP_PROJECT_ADVERT"
        | "PAY_SUCCESS"
        | "MALL_BANNER"
        | "PC_POPUP"
        | "PC_BOTTOM_FLOAT_LAYER"
        | "PC_BANNER"
        | "FLAGSHIP_STORE_BANNER"
        | "FLAGSHIP_STORE_FALLS_ADVERT"
        | "FLAGSHIP_STORE_SLIDE_SHOW"
        | "FLAGSHIP_STORE_LATEST_NEWS"
        | "FLAGSHIP_STORE_NEWS_FOCUS"
        | "FLAGSHIP_STORE_AFFICHE"
        | "FLAGSHIP_STORE_PRODUCT"
        | "FLAGSHIP_STORE_MALL_BANNER"
        | "PC_NOTICE"
        | "CLOUD_THEATER_BANNER"
        | "CLOUD_THEATER_TOP"
        | "CLOUD_THEATER_BOTTOM"
        | "CLOUD_THEATER_VIDEO"
        | "APP_OPEN_SCREEN"
        | "LIMIT_COUPON"
        | "APP_SEARCH",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ArticleInformationDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findFlagshipStoreAdvert: (
      type:
        | "APP_BANNER"
        | "APP_POPUP"
        | "APP_RECOMMEND_ADVERT"
        | "APP_NOTICE"
        | "APP_PROJECT_ADVERT"
        | "PAY_SUCCESS"
        | "MALL_BANNER"
        | "PC_POPUP"
        | "PC_BOTTOM_FLOAT_LAYER"
        | "PC_BANNER"
        | "FLAGSHIP_STORE_BANNER"
        | "FLAGSHIP_STORE_FALLS_ADVERT"
        | "FLAGSHIP_STORE_SLIDE_SHOW"
        | "FLAGSHIP_STORE_LATEST_NEWS"
        | "FLAGSHIP_STORE_NEWS_FOCUS"
        | "FLAGSHIP_STORE_AFFICHE"
        | "FLAGSHIP_STORE_PRODUCT"
        | "FLAGSHIP_STORE_MALL_BANNER"
        | "PC_NOTICE"
        | "CLOUD_THEATER_BANNER"
        | "CLOUD_THEATER_TOP"
        | "CLOUD_THEATER_BOTTOM"
        | "CLOUD_THEATER_VIDEO"
        | "APP_OPEN_SCREEN"
        | "LIMIT_COUPON"
        | "APP_SEARCH",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.TheaterAdvertDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findBottomContentById,
  findBottomContentInfo,
  findBottomHeadInfo,
  findShopHelpCenter,
  findInformationInfo,
  findInformation,
  findFlagshipStoreAdvert,
} as ShopApi;
