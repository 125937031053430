/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description My Test
 */
import es, { EsParams } from "./es";
import test from "./test";

export class MyTestApi {
  constructor(
    public es: (
      params: EsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public test: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  es,
  test,
} as MyTestApi;
