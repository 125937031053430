/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 用户定位地区信息
 */
import saveRegion from "./saveRegion";
import getMemberRecordRegion, {
  GetMemberRecordRegionParams,
} from "./getMemberRecordRegion";

export class CuMemberRecordRegionApi {
  constructor(
    public saveRegion: (
      regionRequest: member.MemberRecordRegionRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMemberRecordRegion: (
      params: GetMemberRecordRegionParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.GetMemberRecordRegionResponse>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  saveRegion,
  getMemberRecordRegion,
} as CuMemberRecordRegionApi;
