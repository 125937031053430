import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";

export interface TheaterState {
  theaterId: string; // 剧院 ID
}

@Module({ dynamic: true, store, name: "theater" })
class Theater extends VuexModule implements TheaterState {
  theaterId = "40";

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromSessionStorage("vuex", "theater"));
  }

  @Mutation
  resetAllData_PERSIST() {
    this.theaterId = "40";
  }

  @Mutation
  SET_theaterId_PERSIST(theaterId: string) {
    this.theaterId = theaterId;
  }
}

export const TheaterModule = getModule(Theater);
