import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromStorage } from "@/store/utils";
import { getExpireTime, isExpire, genExpireKeyName } from "@/utils/cacheUtils";
import { api } from "@/api";

export type Interface = {
  productId?: number;
  cacheKey: string;
  apiMethod: any;
  params?: Array<string | number | Record<string, unknown>>;
  expireInterval?: number; // 过期时间间隔
  randInterval?: number; // 过期随机数时间
  callback?: (data: ResponseData) => void;
};

export type ResponseData = {
  data: any;
  ext: ObjectMap;
  state: "SUCCESS" | "FAIL" | "EXCEPTION";
  errors?: Array<string>;
};

export interface InterfaceCacheState {
  cacheProductIds: Array<number>; // 需要缓存的项目 ID
  cacheProductIdsExpire: number; // 项目 ID 是否过期
  cacheData: Record<string, any>; // 缓存的数据与缓存过期时间
}

@Module({ dynamic: true, store, name: "interfaceCache" })
class InterfaceCache extends VuexModule implements InterfaceCacheState {
  cacheProductIds: Array<number> = [];
  cacheProductIdsExpire = 0;
  cacheData: Record<string, any> = {};

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromStorage("vuex", "interfaceCache"));
  }

  // 设置需要缓存的项目 ID，如果为空则清空缓存
  @Mutation
  SET_cacheProductIds_PERSIST(cacheProductIds: Array<number>) {
    this.cacheProductIds = cacheProductIds;
    if (!cacheProductIds || cacheProductIds.length < 1) {
      // 清空缓存
      this.cacheData = {};
    } else {
      // 如果返回不为空，则更新过期时间，未过期时不调用接口
      this.cacheProductIdsExpire = getExpireTime(60, 0);
    }
  }

  // 设置缓存与缓存过期时间
  @Mutation
  SET_cache_PERSIST({
    cacheKey,
    value,
    expireInterval,
    randInterval,
  }: {
    cacheKey: string;
    value?: any;
    expireInterval?: number;
    randInterval?: number; //过期间隔 随机数
  }) {
    // 产生过期时间字段的 storage 的 key 名称
    const cacheExpireKey = genExpireKeyName(cacheKey);
    if (value) {
      // 设置缓存
      this.cacheData[cacheKey] = value;
      // 设置缓存过期时间
      this.cacheData[cacheExpireKey] = expireInterval
        ? randInterval
          ? getExpireTime(expireInterval, randInterval)
          : getExpireTime(expireInterval)
        : getExpireTime();
    } else {
      delete this.cacheData[cacheKey];
      delete this.cacheData[cacheExpireKey];
    }
  }

  /**
   * 获取需要缓存的项目 ID，过期了才调用
   *
   * @param success 成功设置项目 ID 后的回调函数
   */
  @Action
  getCacheProductIds(success?: () => void): void {
    // 获取需要缓存的项目 ID，过期了才调用
    if (isExpire(this.cacheProductIdsExpire)) {
      api.goodApi.cleintCache.clientCacheProducts(
        ({ data }: { data: Array<number> }) => {
          // 缓存一下，其它要缓存的页面会用来判断是否需要从缓存获取数据
          this.SET_cacheProductIds_PERSIST(data);
          if (success) {
            success();
          }
        },
        (error: string) => {
          console.error(error);
          if (success) {
            success();
          }
        }
      );
    } else if (success) {
      success();
    }
  }

  /**
   * 调用接口获取数据
   *
   * @param interfaceInfos 需要缓存的接口
   */
  @Action
  getDatas(interfaceInfos: Array<Interface>): void {
    /**
     * 依次对需要缓存的接口进行如下操作：
     * 如果 isCache 为 true 则：
     *    1）判断接口是否有缓存（如过期则清空该接口的缓存，视为没有缓存），如果有则用缓存设置用于显示的数据
     *    2）如果没有缓存，则调用接口、设置缓存及过期时间、设置用于显示的数据
     *    3）如有回调函数则执行回调函数
     * 如果 isCache 为 false 则：
     *    1）调用接口，设置展示数据
     *    2）如有回调函数则执行回调函数
     */
    interfaceInfos.forEach((interfaceInfo) => {
      this.getData(interfaceInfo);
    });
  }

  // 获取某个接口的数据
  @Action
  getData({
    productId,
    cacheKey,
    apiMethod,
    params,
    expireInterval,
    randInterval,
    callback,
  }: Interface): void {
    /**
     * 如果没有 productId，则 cacheProductIds 不为空时缓存（对应首页）
     * 如果 productId 存在，则 cacheProductIds 包含 productId 时缓存（对应项目详情、选座、锁座、确认订单、提交订单）
     */
    const cacheProductIds: Array<number> = this.cacheProductIds || [];
    let isCache = cacheProductIds.length > 0;
    if (isCache && productId) {
      isCache = cacheProductIds.indexOf(productId) > -1;
    }
    if (params) {
      params.forEach((param) => {
        if (typeof param == "object") {
          for (const key in param) {
            const value = param[key];
            // 如果是分页查询，则只缓存第一页
            if (key == "page" && value != 1) {
              isCache = false; // 分页查询，不是第一页的不缓存
            }
            cacheKey += value ? "-" + value : "";
          }
        } else {
          cacheKey += param ? "-" + param : "";
        }
      });
    }
    const cacheData = this.cacheData[cacheKey];
    if (isCache && !!cacheData) {
      // 判断缓存是否过期
      if (!isExpire(this.cacheData[genExpireKeyName(cacheKey)])) {
        // 缓存命中且未过期
        if (!!callback) {
          callback(cacheData);
        }
        return;
      }
      // 过期，清空该接口的缓存，视为缓存未命中
      this.SET_cache_PERSIST({ cacheKey, expireInterval, randInterval });
    }
    const success = (res: ResponseData) => {
      // 调用接口的成功回调，设置缓存，如果为 undefined 则会清空对应缓存
      this.SET_cache_PERSIST({
        cacheKey,
        value: isCache ? res : undefined,
        expireInterval,
        randInterval,
      });
      if (!!callback) {
        callback(res);
      }
    };
    // 根据参数的数量调用不同的方法，目前只支持最多 3 个参数
    if (params) {
      if (params.length == 1) {
        apiMethod(params[0], success);
      } else if (params.length == 2) {
        apiMethod(params[0], params[1], success);
      } else if (params.length == 3) {
        apiMethod(params[0], params[1], params[2], success);
      }
    } else {
      apiMethod(success);
    }
  }
}

export const InterfaceCacheModule = getModule(InterfaceCache);
