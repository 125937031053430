/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 查询文案详情
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  type:
    | "PC_CUSTOMER_SERVICE_INFORMATION"
    | "APP_CUSTOMER_SERVICE_INFORMATION"
    | "PC_COPYRIGHT_DESCRIPTION"
    | "COMPANY_INTRODUCTION"
    | "CONTACT_COOPERATION"
    | "WALLET_AGREEMENT"
    | "TICKET_PURCHASE"
    | "TIPS_USING"
    | "PRIVACY_CLAUSE"
    | "APP_REMINDER"
    | "ABOUT_POLY_TICKETING"
    | "COUPON"
    | "COMMODITY_PRICE_DESCRIPTION"
    | "INTEGRAL_PROMPT"
    | "CANCELLATION_NOTICE"
    | "GIFT_CARD_TIPS"
    | "WHAT_ARE_POINTS"
    | "ORDER_WHAT_ARE_POINTS"
    | "PC_ORDER"
    | "APP_ORDER"
    | "DOOR_TO_DOOR_ORDERING"
    | "KEY_CUSTOMER_ORDER"
    | "ONLINE_PAYMENT"
    | "DOOR_TO_DOOR_PAYMENT"
    | "ELECTRONIC_WALLET"
    | "SCENE_TICKET"
    | "EXPRESS_DELIVERY"
    | "PERSONAL_PRIVACY"
    | "INVOICE_DESCRIPTION"
    | "SMS_HELP"
    | "INTEGRAL_HELP"
    | "POLY_TICKET_USER_SERVICES_AGREEMENT"
    | "STATEMENT_DESCRIPTION"
    | "GRADE_GUIDE"
    | "LICENSE_INFO"
    | "WITHDRAWAL_DESC"
    | "APP_CONTACT_US"
    | "SHOPPING_CLAUSE"
    | "JUJU_COMPANY_USER_PROTOCOL"
    | "CULTURE_COUPON"
    | "MEMBER_INFO_COLLECT"
    | "THIRD_INFO_TABLE"
    | "THIRD_SDK_DESC"
    | "PERSONALIZED_RECOMMENDATIONS"
    | "BASIC_FUNCTION"
    | "PERSONAL_INFORMATION_DESC"
    | "FRAUD_PREVENTION_REMINDER"
    | "PAGE_FRAUD_PREVENTION_REMINDER",

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: string;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/cms/document/${type}`,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
