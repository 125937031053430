import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";
export interface productState {
  productId: string;
}

@Module({ dynamic: true, store, name: "rProductId" })
class productId extends VuexModule implements productState {
  productId = "";

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromSessionStorage("vuex", "rProductId"));
  }

  @Mutation
  resetProductId_PERSIST() {
    this.productId = "";
  }

  @Mutation
  SET_ProductId_PERSIST(productId: string) {
    this.productId = productId;
  }
}

export const productIdModule = getModule(productId);
