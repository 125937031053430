import { PluginObject } from "vue";

import { default as cmsApi, CmsApi } from "./cms/mods";

import { default as paymentApi, PaymentApi } from "./payment/mods";

import { default as memberApi, MemberApi } from "./member/mods";

import { default as goodApi, GoodApi } from "./good/mods";

import { default as oauth2Api, Oauth2Api } from "./oauth2/mods";

import { default as dictionaryApi, DictionaryApi } from "./dictionary/mods";

import { default as orderApi, OrderApi } from "./order/mods";

import { default as ossApi, OssApi } from "./oss/mods";

import {
  default as cloundtheaterApi,
  CloundtheaterApi,
} from "./cloundtheater/mods";

export interface Api {
  cmsApi: CmsApi;
  paymentApi: PaymentApi;
  memberApi: MemberApi;
  goodApi: GoodApi;
  oauth2Api: Oauth2Api;
  dictionaryApi: DictionaryApi;
  orderApi: OrderApi;
  ossApi: OssApi;
  cloundtheaterApi: CloundtheaterApi;
}

export const api = {
  cmsApi,
  paymentApi,
  memberApi,
  goodApi,
  oauth2Api,
  dictionaryApi,
  orderApi,
  ossApi,
  cloundtheaterApi,
};

export const ApiPlugin: PluginObject<Api> = {
  install: (Vue) => {
    Object.defineProperties(Vue.prototype, {
      $api: {
        get() {
          return api;
        },
      },
    });
  },
};
