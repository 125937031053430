import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import dayjs from "dayjs";

import store from "@/store";
import { loadFromStorage } from "@/store/utils";

export interface UserState {
  headImg: string; // 用户头像
  idCardApprove: string;
  nickName: string; // 昵称
  phone: string; // 电话
  userId: string; // 用户 ID
  username: string; // 用户名
  memberbackground: string; //会员背景图片

  isLogin: boolean; // 用户是否登录
  accessToken: string; // 可以直接用的 Token
  expirationTime: number; // Token 过期时间
  refreshToken: string; // 用于刷新 Token 的 Token
  isRealName: boolean;
  fileImg: string; //
  memberLevelId: string;
  searchHistoryList: Array<string>; // 用户搜索历史

  isClearLoginCode: boolean; // 在进入登录页时，是否清理验证码
}

@Module({ dynamic: true, store, name: "user" })
class User extends VuexModule implements UserState {
  headImg = "";
  idCardApprove = "";
  nickName = "";
  phone = "";
  userId = "";
  username = "";
  memberbackground = "";

  isLogin = false;
  accessToken = "";
  expirationTime = 0;
  refreshToken = "";
  fileImg = "";
  isRealName = false;
  memberLevelId = "0";
  searchHistoryList: Array<string> = [];
  isClearLoginCode = false;

  @Mutation
  setStateFromStorage() {
    Object.assign(this, loadFromStorage("vuex", "user"));
  }

  /**
   * 是否需要刷新 Token
   * 1）Token 存在
   * 2）Token 即将过期或已过期，即将过期指的是过期时间 5 分钟之前
   * 3）expirationTime 为 0 视为其他端跳转，这时不刷新 Token
   * 提前 5 分钟，是为了在请求响应后不再决策刷新 Token 这件事
   * 如果不提前，发的时候没过期，到服务器的时候过期了，那么就会在响应时登出，浪费了刷新的机会
   * 超出 5 分钟都到不了服务器的特殊情况比较少，浪费了刷新机会问题也不严重
   */
  get needRefreshToken(): boolean {
    if (this.accessToken && this.expirationTime) {
      return dayjs().isAfter(dayjs(this.expirationTime).subtract(5, "minute"));
    }
    return false;
  }

  @Mutation
  SET_IS_LOGIN_PERSIST(isLogin: boolean) {
    this.isLogin = isLogin;
  }

  @Mutation
  SET_ACCESS_TOKEN_PERSIST(accessToken: string) {
    this.accessToken = accessToken;
  }
  //替换用户头像
  @Mutation
  IMG_CHANGE_PERSIST(img?: string) {
    if (img) this.headImg = img;
  }
  //替换用户昵称
  @Mutation
  NICKNAME_CHANGE(name?: string) {
    if (name) this.nickName = name;
  }
  //缓存个人中心-会员等级背景图片
  @Mutation
  MEMBER_BG(url?: string) {
    if (url) this.memberbackground = url;
  }
  /**
   * 登录
   * @param oAuth2AccessToken 登录获取的 Token 信息
   */
  @Mutation
  UPDATE_TOKEN_PERSIST(token: oauth2.Token): void {
    const accessToken = token.access_token;
    const expirationTime = token.expirationTime;
    const refreshToken = token.refresh_token;
    if (
      accessToken &&
      accessToken != "undefined" &&
      accessToken != "null" &&
      expirationTime &&
      !isNaN(expirationTime) &&
      refreshToken &&
      refreshToken != "undefined" &&
      refreshToken != "null"
    ) {
      const info = token.info;
      this.headImg = info && info.headImg ? info.headImg : "";
      this.idCardApprove = info && info.idCardApprove ? info.idCardApprove : "";
      this.nickName = info && info.nickName ? info.nickName : "";
      this.phone = info && info.phone ? info.phone : "";
      this.userId = info && info.userId ? info.userId : "";
      this.username = info && info.username ? info.username : "";
      this.isLogin = true;
      this.accessToken = accessToken;
      this.expirationTime = expirationTime;
      this.refreshToken = refreshToken;
    }
  }

  /**
   * 查询实名制信息
   *
   */
  @Mutation
  REALNAME_PERSIST(val: boolean): void {
    this.isRealName = val;
  }
  /**
   *
   * 登录的同时拿到cuUser-memberInfo
   */
  @Mutation
  MEMBERINFO_PERSIST(val: member.MemberInfo): void {
    this.memberLevelId = val.memberLevelId || "0";
  }

  @Mutation
  SET_isClearLoginCode_PERSIST(isClearLoginCode: boolean): void {
    this.isClearLoginCode = isClearLoginCode;
  }

  /**
   * 登出
   */
  @Mutation
  LOGOUT_PERSIST(): void {
    this.isClearLoginCode = true; // 登出后，下次再进入登录页，清理一下验证码
    this.headImg = "";
    this.idCardApprove = "";
    this.nickName = "";
    this.phone = "";
    this.userId = "";
    this.username = "";
    this.memberbackground = "";
    this.isLogin = false;
    this.accessToken = "";
    this.expirationTime = 0;
    this.refreshToken = "";
    this.fileImg = "";
    this.isRealName = false;
    this.memberLevelId = "0";
    this.searchHistoryList = []; // 登出时清空本地搜索历史记录
  }

  @Mutation
  SET_searchHistoryList_PERSIST(searchHistoryList: Array<string>): void {
    this.searchHistoryList = searchHistoryList;
  }

  /**
   * 存储用户头像信息
   */
  @Mutation
  USERIMG_PERSIST(value: string): void {
    this.fileImg = value;
  }

  @Mutation
  mergeSearchHistory_PERSIST(searchKeyword: string): void {
    if (searchKeyword) {
      const searchHistoryList = this.searchHistoryList;
      if (searchHistoryList.includes(searchKeyword)) {
        searchHistoryList.splice(searchHistoryList.indexOf(searchKeyword), 1);
      }
      const newSearchHistoryList = [searchKeyword, ...searchHistoryList];
      if (newSearchHistoryList.length > 30) {
        newSearchHistoryList.splice(30);
      }
      this.searchHistoryList = newSearchHistoryList;
    }
  }

  @Mutation
  SET_HarmonyOS_UserPhone_PERSIST(num: string): void {
    this.phone = num;
  }
}

export const UserModule = getModule(User);
