/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 频道
 */
import searchChannelDetail from "./searchChannelDetail";

export class ChannelDetailApi {
  constructor(
    public searchChannelDetail: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.ChannelDetailDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  searchChannelDetail,
} as ChannelDetailApi;
