/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 批量查询旗舰店是否被我订阅
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class AttentionParams {
  constructor(public theaterIds?: Array<string>) {}
}

export default async function (
  params: AttentionParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: Array<member.AttentionDTO>;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/member/attention/theaters/exist`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
