/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 券
 */
import findAppPopupInfo from "./findAppPopupInfo";
import findCouponDetails from "./findCouponDetails";
import getCouponProducts from "./getCouponProducts";
import scene from "./scene";
import getCouponDetail from "./getCouponDetail";
import coupons from "./coupons";
import getCentreCoupons, { GetCentreCouponsParams } from "./getCentreCoupons";
import getProductCoupons from "./getProductCoupons";
import findShopCoupons from "./findShopCoupons";
import cultureCoupons from "./cultureCoupons";
import exchangeCoupon from "./exchangeCoupon";
import exchangeCultureCoupons from "./exchangeCultureCoupons";
import receiveCoupon, { GetReceiveCouponParams } from "./receiveCoupon";
import receivePOPCoupon from "./receivePOPCoupon";
import getSimpleCoupons from "./getSimpleCoupons";
import getActivityCouponsByTheaterId from "./getActivityCouponsByTheaterId";
import unLockCultureCoupons from "./unLockCultureCoupons";

export class CouponApi {
  constructor(
    public findAppPopupInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.AppPopupInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findCouponDetails: (
      coupon: good.CouponDetailsCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.CouponDetailDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCouponProducts: (
      couCondition: good.CouponProductsCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public scene: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.Codebook>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCouponDetail: (
      couponId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.CouponDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public coupons: (
      couponsReqDto: good.CouponsReqDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.CouponInfoResDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCentreCoupons: (
      params: GetCentreCouponsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.ProductCouponDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductCoupons: (
      productId: number,
      productTypeId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.ProductCouponDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findShopCoupons: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.SimpleCouponDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cultureCoupons: (
      cultureCouponId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.OrderTicketCouponDetail>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public exchangeCoupon: (
      couponCode: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public exchangeCultureCoupons: (
      couponCode: string,
      cultureCouponId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public receiveCoupon: (
      couponId: number,
      params: GetReceiveCouponParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public receivePOPCoupon: (
      activityId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSimpleCoupons: (
      productId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.SimpleCouponDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getActivityCouponsByTheaterId: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.CouponDetailDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public unLockCultureCoupons: (
      couponId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findAppPopupInfo,
  findCouponDetails,
  getCouponProducts,
  scene,
  getCouponDetail,
  coupons,
  getCentreCoupons,
  getProductCoupons,
  findShopCoupons,
  cultureCoupons,
  exchangeCoupon,
  exchangeCultureCoupons,
  receiveCoupon,
  receivePOPCoupon,
  getSimpleCoupons,
  getActivityCouponsByTheaterId,
  unLockCultureCoupons,
} as CouponApi;
