/* eslint-disable @typescript-eslint/no-explicit-any */
/**
     * @desc 初始化获取小程序配置信息
返回小程序配置信息初始化查询结果
     */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  appletsConfigInfoRequest: oauth2.AppletsConfigInfoRequest,

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: oauth2.AppletsConfigInfo;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "post",
    url: `/oauth2/theater/ma/init-config`,
    data: appletsConfigInfoRequest,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
