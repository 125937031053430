/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 娱伯牙文章内容
 */
import searchProducts, { SearchProductsParams } from "./searchProducts";

export class YbyContentApi {
  constructor(
    public searchProducts: (
      params: SearchProductsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.YbyContentSearchListVO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  searchProducts,
} as YbyContentApi;
