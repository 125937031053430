import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";

export interface ExternalAccessState {
  isExternalAccessReady: boolean; // 外部跳转 H5 页面准备工作是否完成
  code: string;
}

@Module({ dynamic: true, store, name: "externalAccess" })
class ExternalAccess extends VuexModule implements ExternalAccessState {
  isExternalAccessReady = false;
  code = "";

  @Mutation
  setStateFromStorage() {
    Object.assign(this, loadFromSessionStorage("vuex", "externalAccess"));
  }

  @Mutation
  SET_isExternalAccessReady_PERSIST(isExternalAccessReady: boolean) {
    this.isExternalAccessReady = isExternalAccessReady;
  }

  @Mutation
  SET_CODE(code: string) {
    this.code = code;
  }
}

export const ExternalAccessModule = getModule(ExternalAccess);
