/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 旗舰店信息以及是否被我订阅
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class SelFlagShipStoreParams {
  constructor(public pageNum: number, public pageSize: number) {}
}

export default async function (
  params: SelFlagShipStoreParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: member.Page<member.FlagshipStoreDto>;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/member/flagship/store`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
