/* eslint-disable @typescript-eslint/no-explicit-any */
import { PluginObject } from "vue";
import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  CancelTokenSource,
  AxiosResponse,
  AxiosError,
} from "axios";
import config from "@/core/config";
import md5 from "md5";
import { UserModule } from "@/store/modules/user";
import { TheaterModule } from "@/store/modules/theater";
import { RouteRecordModule } from "@/store/modules/routeRecord";
import { LocationModule } from "@/store/modules/location";
import { ChannelModule } from "@/store/modules/channel";
import { ChannelFromModule } from "@/store/modules/channelFrom";
import router from "@/router";
import { Toast } from "vant";
import { wechatLogin } from "@/utils/weChatLogin";
import { isCustomChannelDistribution } from "@/utils/index";
import { Vue } from "vue-property-decorator";
export function defaultSuccess(data: unknown): void {
  console.log(data);
}
export function defaultError(error: string): void {
  console.error("defaultError >>> error", error);
  if (error) {
    let message = error;
    if (typeof error !== "object" && error.includes("@_@")) {
      const messageArray = error.split("@_@");
      message = messageArray.length > 1 ? messageArray[1] : messageArray[0];
    }
    Toast(message);
  }
}

const axiosConfig = {
  baseURL: config.http.prefix,
  timeout: config.http.timeout,
};
// 会校验和处理 Token 失效
const _axios = axios.create(axiosConfig);
// 不会校验和处理 Token 失效，并且会把 Authorization 设置为空字符串，且不会放到 cancelTokenSources 中
const _axiosNoTokenVerify = axios.create(axiosConfig);

_axiosNoTokenVerify.interceptors.request.use(
  (cfg: AxiosRequestConfig) => {
    UserModule.SET_ACCESS_TOKEN_PERSIST("");
    cfg.headers = getHeaders();
    return cfg;
  },
  (err: unknown) => {
    console.warn(err);
    return Promise.reject("配置错误");
  }
);
_axiosNoTokenVerify.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response.data);
  },
  (error: AxiosError) => {
    const errors =
      error.response &&
      (error.response.data as { state: string; errors: Array<string> }).errors;

    return Promise.reject(error.response && errors && errors[0]);
  }
);

const REFRESH_TOKEN_FAIL_FLAG = "refresh_token_fail";
const cancelTokenSources: Array<CancelTokenSource> = [];
const removeCanceler = (cfg: AxiosRequestConfig) => {
  if (cfg) {
    cancelTokenSources.splice(
      cancelTokenSources.findIndex((s) => s.token === cfg.cancelToken),
      1
    );
  }
};
const getHeaders = () => {
  let location = "";
  const longitude = LocationModule.longitude;
  const latitude = LocationModule.latitude;
  if (longitude >= 0 && latitude >= 0) {
    location = longitude + "," + latitude;
  }
  /**
   * ChannelModule.channel 只用来表示环境是 H5 还是 APP 或小程序跳转
   * 不用来表示渠道是平台还是剧院
   * 在 axios 的请求拦截器中组装 Header 时
   * 再根据配置和 chennel 来组装 Header 中的 Channel 字段，将渠道和环境融为一个字段
   * 配置 config.platOrTheater 在平台时等于 "plat"，剧院时等于 "theatre"
   *
   * 例：
   * 平台 H5 的请求头 Channel 为 plat_h5
   * 平台小程序跳转的请求头 Channel 为 plat_wechat_miniapp
   * 剧院 H5 的请求头 Channel 为 theatre_wx
   * 剧院小程序跳转的请求头 Channel 为 theatre_wechat_miniapp
   */
  const channel = ChannelModule.channel;
  let headerChannel = config.platOrTheater + channel.replace("plat", "");
  if (channel === "douyin_miniapp") {
    headerChannel = channel;
  }
  if (channel == "plat_h5" && config.platOrTheater == "theatre") {
    headerChannel = "theatre_wx";
  }

  const ChannelFrom = ChannelFromModule.channelFrom;
  const ua = window.navigator.userAgent;
  let PlatForm = "";
  if (ua.indexOf("iPhone") > -1) {
    PlatForm = "ios_4.0";
  } else {
    PlatForm = "android_4.0";
  }
  console.log(ua);

  return {
    Channel: headerChannel,
    Location: location,
    Authorization: UserModule.accessToken, // Token
    Theater: "40", // TheaterModule.theaterId ? TheaterModule.theaterId : "",
    "City-Code": isCustomChannelDistribution(router.currentRoute)
      ? "" // 渠道分销，自定义模板，把请求头的 City-Code 设置为空字符串
      : LocationModule.cityCode,
    Atgc: md5(channel + new Date().getTime()),
    "Channel-From": ChannelFrom, //来源 默认空字符串 BESTPAY翼支付
    // "Plat-Form": ChannelFrom == "" ? "" : PlatForm, //翼支付使用，区分ios和android
    "Plat-Form": PlatForm,
  };
};

function setHeaderAndCancelToken(cfg: AxiosRequestConfig): void {
  const source = axios.CancelToken.source();
  cfg.headers = getHeaders();
  cfg.cancelToken = source.token;
  cancelTokenSources.push(source);
}

// 刷新 Token，请求拦截器中可能执行
const refreshToken = (
  cfg: AxiosRequestConfig,
  resolve: (value: unknown) => void
) => {
  _axiosNoTokenVerify({
    method: "post",
    url: `/oauth2/token-refresh`,
    data: {
      clientId: "PLAT_H5",
      clientSecret: "1234567",
      grantType: "REFRESH_TOKEN",
      loginIp: "10.0.0.1",
      loginPhoneModel: "iphone13",
      loginPhoneSystem: "IOS",
      loginTriggerPage: "personCenter",
      refreshToken: UserModule.refreshToken,
    },
  })
    .then((res) => {
      const { data, state } = res as unknown as {
        data: oauth2.Token;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
      };
      isDoingRefreshToken = false;
      if (state == "SUCCESS") {
        // 刷新 Token 成功
        UserModule.UPDATE_TOKEN_PERSIST(data);
      }
      /**
       * 刷新 Token 失败后也放过继续请求，避免那些不需要登录的页面也被拦截到登录页
       * 需要登录的接口，会在请求失败后被拦截到登录页
       */
      setHeaderAndCancelToken(cfg);
      resolve(cfg);
    })
    .catch((error: string) => {
      isDoingRefreshToken = false;
      // 刷新 Token 失败
      console.error("刷新 Token 失败 >>> error =", error);
      /**
       * 刷新 Token 失败后也放过继续请求，避免那些不需要登录的页面也被拦截到登录页
       * 需要登录的接口，会在请求失败后被拦截到登录页
       */
      setHeaderAndCancelToken(cfg);
      resolve(cfg);
    });
};

const tryLogin = (complete?: () => void) => {
  // 登出
  UserModule.LOGOUT_PERSIST();
  Toast.clear(); // 清理一下加载动画，有些请求可能会开启
  RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({ router });
  if (complete) {
    complete();
  }
};

/*
是否有别的人正在处理 Token 失效，如果有自己就不处理了
不放到 vuex 中持久化，这样万一没有正确重置还能通过刷新页面解决
*/
let isDoingHandleInvalidToken = false;
/**
 * 判断是否是 Token 失效的情况
 * 如果是则做相应的处理并返回 true，如果不是则什么都不做，返回 false
 * 响应拦截器中可能执行
 *
 * @param code 系统生成的状态码（不是 HTTP 状态码）
 * @returns 是否：Token 失效
 */
const handleInvalidToken = (code?: string): boolean => {
  // code === "0001" 表示没有登陆；code === "0002" 表示 Token 无效，需要重新登录
  if (code == "0001" || code == "0002") {
    if (!isDoingHandleInvalidToken) {
      isDoingHandleInvalidToken = true;
      console.error('code === "' + code + '"');
      // 先取消所有其它请求
      cancelTokenSources.forEach((cancelTokenSource) =>
        cancelTokenSource.cancel("已在登录页，取消请求")
      );
      tryLogin(() => {
        isDoingHandleInvalidToken = false;
      });
    }
    return true;
  }
  return false;
};

// 是否正在刷新 Token，不放到 vuex 中持久化，这样万一没有正确重置还能通过刷新页面解决
let isDoingRefreshToken = false;
_axios.interceptors.request.use(
  (cfg: AxiosRequestConfig) => {
    if (
      UserModule.needRefreshToken && // 需要刷新 Token
      !isDoingRefreshToken // 没有其它人在刷新 Token
    ) {
      isDoingRefreshToken = true;
      // 刷新 Token
      // 刷新失败登出，刷新成功则存储 Token 信息并且用新的 Token 进行请求
      return new Promise((resolve) => {
        // 刷新 Token
        refreshToken(cfg, resolve);
      });
    }
    setHeaderAndCancelToken(cfg);
    return cfg;
  },
  (err: unknown) => {
    console.warn(err);
    return Promise.reject("配置错误");
  }
);

_axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // 从队列移除当前的 Canceler
    removeCanceler(response.config);
    const data = response.data as {
      state: string;
      code: string;
      errors: Array<string>;
    };
    if (data.state == "SUCCESS") {
      // 数据成功
      return Promise.resolve(data);
    } else {
      Toast.clear();
    }
    console.error(
      'HTTP 成功，但返回的 data.state != "SUCCESS" >>> response.request',
      response.request
    );
    if (handleInvalidToken(data.code)) {
      // reject 空字符串，这样不会弹出错误提示
      return Promise.reject("");
    }
    const code = data.code;
    if (
      code == "1244" ||
      code == "1258" ||
      code == "1261" ||
      code == "1260" ||
      code == "1129" ||
      code == "1259" ||
      code == "1128" ||
      code == "1003"
    ) {
      return Promise.reject(code + "@_@" + data.errors[0]);
    } else if (code == "0003") {
      /* 限流降级弹出错误提示 */
      if (
        response.config.url?.includes("/order/advance/") ||
        response.config.url?.includes("/order/discount-amt")
      ) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(data.errors[0]);
      }
    } else if (code == "9065") {
      // 翼支付：ACCESSTOKEN验证过期，跳转到联合登陆空白页，重新授权
      router.replace(
        "https://itempfwechat.yuboya.cn/#/other/oauth?theaterId=40&channel=BEST_PAY&distributionChannelId=279&distributionSeriesId=1658727679176417282"
      );
      // return Promise.reject("请重新授权登录");
    }
    // 数据失败直接reject
    return Promise.reject(data.errors[0]);
  },
  (error: AxiosError) => {
    console.error("HTTP 报错 >>> error", JSON.parse(JSON.stringify(error)));
    if (
      error.message.indexOf("Network Error") > -1 ||
      (error.code == "ECONNABORTED" && error.message.indexOf("timeout") > -1)
    ) {
      // 超时提示
      Toast("您的网络状态不佳");
    }
    // 从队列移除当前的 Canceler
    removeCanceler(error.config);
    if (error && error.code == REFRESH_TOKEN_FAIL_FLAG) {
      // 刷新 Token 失败，尝试三方登录或跳转到登录页
      tryLogin();
      // 返回时 reject 空字符串，这样不会弹出错误提示
      return Promise.reject("");
    }
    switch (Number(error.response && error.response.status)) {
      case 403 | 401:
        return Promise.reject("您无权限进行操作");
      case 404:
        return Promise.reject("请求资源不存在");
      case 500:
      default:
        const errors =
          error.response &&
          (
            error.response.data as {
              state: string;
              errors: Array<string>;
            }
          ).errors;
        const code =
          error.response &&
          (error.response.data as { state: string; code: string }).code;

        if (handleInvalidToken(code)) {
          // reject 空字符串，这样不会弹出错误提示
          return Promise.reject("");
        }
        return Promise.reject(error.response && errors && errors[0]);
    }
  }
);

const Plugin: PluginObject<AxiosInstance> = {
  install: (Vue) => {
    Object.defineProperties(Vue.prototype, {
      $axios: {
        get() {
          return _axios;
        },
      },
    });
  },
};

export {
  _axios as http,
  _axiosNoTokenVerify as httpNoTokenVerify,
  Plugin as axiosPlugin,
};
