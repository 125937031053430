/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 查询票价列表,购买数量
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  showId: number,

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: good.ShowPriceDto;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/good/show/price/${showId}`,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
