/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 优惠券
 */
import addCoupon from "./addCoupon";
import coupons, { CouponsParams } from "./coupons";
import couponsStatus from "./couponsStatus";
import myCoupons, { MyCouponsParams } from "./myCoupons";

export class CouponsApi {
  constructor(
    public addCoupon: (
      couponCode: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public coupons: (
      params: CouponsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.CouponDetailMessageRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public couponsStatus: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.Codebook>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public myCoupons: (
      params: MyCouponsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.CouponRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  addCoupon,
  coupons,
  couponsStatus,
  myCoupons,
} as CouponsApi;
