/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 订单发票接口
 */
import getEnterpriseInvoiceAdvance from "./getEnterpriseInvoiceAdvance";
import getPersonalInvoiceAdvance from "./getPersonalInvoiceAdvance";
import submitOrderInvoice from "./submitOrderInvoice";
import getOrderInvoice from "./getOrderInvoice";

export class OrderInvoiceApi {
  constructor(
    public getEnterpriseInvoiceAdvance: (
      orderId: string,
      orderType: "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.InvoiceAdvanceInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPersonalInvoiceAdvance: (
      orderId: string,
      orderType: "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.InvoiceAdvanceInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public submitOrderInvoice: (
      confirmInfo: order.InvoiceConfirmReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.InvoiceConfirmInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderInvoice: (
      orderId: string,
      orderType: "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.InvoiceConfirmInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getEnterpriseInvoiceAdvance,
  getPersonalInvoiceAdvance,
  submitOrderInvoice,
  getOrderInvoice,
} as OrderInvoiceApi;
