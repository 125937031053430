/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 商城分类
 */
import findCommodityType from "./findCommodityType";

export class CommodityApi {
  constructor(
    public findCommodityType: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.CommodityTypeDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findCommodityType,
} as CommodityApi;
