/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description token相关的接口
 */
import h5Token, { H5TokenParams } from "./h5Token";
import performencePhone, { PerformencePhoneParams } from "./performencePhone";
import performenceToken, { PerformenceTokenParams } from "./performenceToken";
import removeToken from "./removeToken";
import removeTokenByUserId from "./removeTokenByUserId";
import getMgmtTokenByPassword from "./getMgmtTokenByPassword";
import getTokenByPassword from "./getTokenByPassword";
import refreshToken from "./refreshToken";
import getTokenBySms from "./getTokenBySms";
import getMemberInfoByToken from "./getMemberInfoByToken";
import validateToken from "./validateToken";

export class TokenApi {
  constructor(
    public h5Token: (
      params: H5TokenParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.H5Token;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public performencePhone: (
      params: PerformencePhoneParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public performenceToken: (
      params: PerformenceTokenParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.Token;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public removeToken: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public removeTokenByUserId: (
      userId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMgmtTokenByPassword: (
      mgmtTokenDto: oauth2.MgmtTokenDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.MgmtToken;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTokenByPassword: (
      oauthPasswordDto: oauth2.OauthPasswordDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.Token;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public refreshToken: (
      oauthRefreshDto: oauth2.OauthRefreshDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.Token;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTokenBySms: (
      oauthPhoneCaptchaDto: oauth2.OauthPhoneCaptchaDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.Token;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMemberInfoByToken: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.CuUser;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public validateToken: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.OAuth2Authentication;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  h5Token,
  performencePhone,
  performenceToken,
  removeToken,
  removeTokenByUserId,
  getMgmtTokenByPassword,
  getTokenByPassword,
  refreshToken,
  getTokenBySms,
  getMemberInfoByToken,
  validateToken,
} as TokenApi;
