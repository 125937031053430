/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 商城->积分区间
 */
import findCommodityRedeemCode from "./findCommodityRedeemCode";

export class CommodityIntegralRangeApi {
  constructor(
    public findCommodityRedeemCode: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.IntegralRangeDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findCommodityRedeemCode,
} as CommodityIntegralRangeApi;
