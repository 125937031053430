/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 验证码(目前只支持PC端)
 */
import checkImageCaptcha, {
  CheckImageCaptchaParams,
} from "./checkImageCaptcha";
import sendEmail, { SendEmailParams } from "./sendEmail";
import verifyEmail, { VerifyEmailParams } from "./verifyEmail";
import getEmailCaptcha from "./getEmailCaptcha";
import checkEmailCaptcha from "./checkEmailCaptcha";
import imageCaptcha from "./imageCaptcha";
import verify from "./verify";
import postRetrievingPasswordCheckingCaptcha from "./postRetrievingPasswordCheckingCaptcha";
import postRetrievingPasswordUpdatingPassword from "./postRetrievingPasswordUpdatingPassword";
import checkUserName from "./checkUserName";
import scene from "./scene";
import sms, { SmsParams } from "./sms";
import postVerify from "./postVerify";

export class CaptchaApi {
  constructor(
    public checkImageCaptcha: (
      params: CheckImageCaptchaParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public sendEmail: (
      params: SendEmailParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public verifyEmail: (
      params: VerifyEmailParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getEmailCaptcha: (
      bindEmailCaptchaDto: member.BindEmailCaptchaDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public checkEmailCaptcha: (
      checkEmailDto: member.CheckEmailDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public imageCaptcha: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.ImageCodeInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public verify: (
      retrievePasswordCaptchaDto: member.RetrievePasswordCaptchaDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public postRetrievingPasswordCheckingCaptcha: (
      retrievePasswordDto: member.RetrievePasswordDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public postRetrievingPasswordUpdatingPassword: (
      updateRetrievePasswordReq: member.UpdateRetrievePasswordReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public checkUserName: (
      retrievePasswordDto: member.RetrievePasswordDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public scene: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.Codebook>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public sms: (
      params: SmsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public postVerify: (
      verifyDTO: member.VerifyDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  checkImageCaptcha,
  sendEmail,
  verifyEmail,
  getEmailCaptcha,
  checkEmailCaptcha,
  imageCaptcha,
  verify,
  postRetrievingPasswordCheckingCaptcha,
  postRetrievingPasswordUpdatingPassword,
  checkUserName,
  scene,
  sms,
  postVerify,
} as CaptchaApi;
