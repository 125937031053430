import { UserModule } from "@/store/modules/user";
import { InterfaceCacheModule } from "@/store/modules/interfaceCache";
import { Api } from "@/api/index";
import { Route } from "vue-router";

// 是否是渠道分销
export function isChannelDistribution(route: Route): boolean {
  const query = route.query;
  const distributionChannelId = query.distributionChannelId;
  const distributionSeriesId = query.distributionSeriesId;
  return (
    String(distributionChannelId || "") != "" &&
    String(distributionSeriesId || "") != ""
  );
}

// 是否是渠道分销自定义模板
export function isCustomChannelDistribution(route: Route): boolean {
  return (
    isChannelDistribution(route) &&
    String(route.query.templateType || "") == "custom"
  );
}

export function getPayMethodName(payMethodEnum: string): string {
  switch (payMethodEnum) {
    case "WXPAY":
      return "微信";
    case "ALIPAY":
      return "支付宝";
    case "UNIONPAY":
      return "银联";
    case "WALLET":
      return "余额";
  }
  return "未知";
}

export function loginCommon(oauth2AccessToken: oauth2.Token, api: Api): void {
  UserModule.UPDATE_TOKEN_PERSIST(oauth2AccessToken);
  api.memberApi.setting.getAccountInfo(({ data }) => {
    UserModule.REALNAME_PERSIST(data.authentication!);
  });
  InterfaceCacheModule.getData({
    cacheKey: "cuUser.memberInfo",
    apiMethod: api.memberApi.cuUser.memberInfo,
    callback: ({ data }) => {
      UserModule.MEMBERINFO_PERSIST(data);
    },
  });
  // 同步并更新搜索历史
  api.goodApi.search.setSearchSyncList(
    { keyword: UserModule.searchHistoryList },
    (data) => {
      let searchHistoryList: Array<string> = [];
      if (data && data.data) {
        const keyword = data.data.keyword;
        if (keyword && keyword.length > 0) {
          searchHistoryList = keyword;
        }
      }
      UserModule.SET_searchHistoryList_PERSIST(searchHistoryList);
    }
  );
}

/**
 * 兼容 IOS，IOS 上，必须用 new Date('2020/01/01') 这种格式
 *
 * @param dateString 时间字符串，如 "2020-01-01"
 * @returns 日期
 */
export function getDateByString(dateString: string): Date {
  return new Date(dateString.replace(/-/g, "/"));
}

// 根据区县的 adcode 得到城市的 code
export function getCityCodeByAdcode(adcode?: string): string {
  if (!adcode) {
    return "";
  }
  const adcodeLength = adcode.length;
  if (adcodeLength > 1) {
    return adcode.substring(0, adcodeLength - 2) + "00";
  }
  return adcode;
}

// 计算两个经纬度之间的距离
export function calculateDiscount(
  lng1: number,
  lat1: number,
  lng2: number,
  lat2: number
): string {
  const radLat1 = (lat1 * Math.PI) / 180.0;
  const radLat2 = (lat2 * Math.PI) / 180.0;
  const a = radLat1 - radLat2;
  const b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  let s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000;
  s = s * 1000;
  if (isNaN(s)) {
    return 0 + "m";
  }
  if (s > 100000) {
    // 大于100Km时
    s = Math.floor((s / 1000) * 100) / 100;
    return s.toFixed() + "km";
  }
  if (s > 1000 && s < 100000) {
    // 大于1000米时
    s = Math.floor((s / 1000) * 100) / 100;
    return s.toFixed(1) + "km";
  }
  // 小于1000米直接返回
  return s.toFixed() + "m";
}

// 判断手机号是否正确
export function isPhoneNum(phoneNumber: string, areaCode = "86"): boolean {
  if (!phoneNumber) {
    return false;
  }
  let phoneReg = null;
  switch (areaCode) {
    case "86":
      phoneReg = /^1[3456789]\d{9}$/g;
      break;
    case "852":
      // 香港  8位数字
      phoneReg = /^\d{8}$/g;
      break;
    case "853":
      // 澳门 6开头  八位数字
      phoneReg = /^6\d{7}$/g;
      break;
    case "886":
      // 台湾 10位数字
      phoneReg = /^[0][9]\d{8}$/g;
      break;
    default:
      return false;
  }
  return phoneReg.test(phoneNumber);
}

// 时间格式化
export function dateFormat(
  time: string | number | Date,
  fmt = "YYYY-MM-dd"
): string {
  // ios无法处理yyyy-mm-dd，处理为yyyy/mm/dd
  if (typeof time === "string") {
    time = time.replace(/-/g, "/");
  }
  const date = new Date(time);
  if (/(y+)/i.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  return fmt;
}

// 判断是否在微信中打开
export function isWechat(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua);
}

export function isWeiBo(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  return /WeiBo/i.test(ua);
}
//将时间戳转为'2020.01.03'
export function formateDate(time: string | number | Date): string | Date {
  const t = new Date(time);
  const year = t.getFullYear();
  // 由于 getMonth 返回值会比正常月份小 1
  const month = t.getMonth() + 1;
  const day = t.getDate();
  const hour = t.getHours();
  const minute = t.getMinutes();
  const hash = {
    Y: year,
    m: month,
    d: day,
    h: hour,
    s: minute,
  };
  return `${fillZero(hash.Y) + "."}${fillZero(hash.m) + "."}${fillZero(
    hash.d
  )}`;
}
//将时间戳转为年月日
export function formates(time: string | number | Date): string | Date {
  const t = new Date(time);
  const year = t.getFullYear();
  // 由于 getMonth 返回值会比正常月份小 1
  const month = t.getMonth() + 1;
  const day = t.getDate();
  const hour = t.getHours();
  const minute = t.getMinutes();
  const hash = {
    Y: year,
    m: month,
    d: day,
    h: hour,
    s: minute,
  };
  return `${fillZero(hash.Y) + "年"}${fillZero(hash.m) + "月"}${
    fillZero(hash.d) + "日"
  }`;
}
// 将时间毫秒数转月日
export function formate(time: string | number | Date): string | Date {
  const t = new Date(time);
  // 日期格式
  const format = "Y-m-d ";
  const year = t.getFullYear();
  // 由于 getMonth 返回值会比正常月份小 1
  const month = t.getMonth() + 1;
  const day = t.getDate();
  const hour = t.getHours();
  const minute = t.getMinutes();
  const hash = {
    Y: year,
    m: month,
    d: day,
    h: hour,
    s: minute,
  };
  return `${fillZero(hash.m) + "月"}${fillZero(hash.d) + "日"} ${fillZero(
    hash.h
  )}:${fillZero(hash.s)}`;
}

/*
 * 补零处理
 */
export function fillZero(num: number): string {
  const value = num < 10 ? "0" + num.toString() : num.toString();
  return value;
}

// 身份证，军官证等检验
// 判断手机号是否正确
export function checkIdCard(type: string, areaCode = "86"): boolean {
  if (!type) {
    return false;
  }
  let IdCardReg = null;
  switch (type) {
    // 身份证：18位数字；
    case "86":
      IdCardReg = /^\d{18}$/g;
      break;
    case "852":
      // 军官证  8位数字
      IdCardReg = /^\d{8}$/g;
      break;
    case "853":
      // 士兵证 7位数字
      IdCardReg = /^6\d{7}$/g;
      break;
    case "854":
      // 台胞证：8位数字
      IdCardReg = /^\d{8}$/g;
      break;
    case "886":
      // 台湾 10位数字
      IdCardReg = /^[0][9]\d{8}$/g;
      break;
    default:
      return false;
  }
  return IdCardReg.test(type);
}
