/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 大麦项目
 */
import getSeatTokent from "./getSeatTokent";

export class DaMaiApi {
  constructor(
    public getSeatTokent: (
      productId: number,
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.DaMaiSeatTokenDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getSeatTokent,
} as DaMaiApi;
