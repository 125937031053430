/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 微信推送测试
 */
import expireIntegralTest from "./expireIntegralTest";

export class WxPushApi {
  constructor(
    public expireIntegralTest: (
      key: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  expireIntegralTest,
} as WxPushApi;
