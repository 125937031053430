/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 演出场次票价缺货登记
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class UserLackTicketRecordParams {
  constructor(
    public distributionChannelId?: string,
    public distributionSeriesId?: string
  ) {}
}

export default async function (
  priceId: number,
  productId: number,
  showId: number,

  params: UserLackTicketRecordParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: string;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "put",
    url: `/member/record/lack-ticket/${productId}/${showId}/${priceId}`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
