/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 剧院相关信息
 */
import getTheaterLevels from "./getTheaterLevels";
import getTheaterMember from "./getTheaterMember";
import getTheatersIntegral from "./getTheatersIntegral";

export class TheaterMemberApi {
  constructor(
    public getTheaterLevels: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.MemberLevelsRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterMember: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.TheaterMemberRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheatersIntegral: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.TheaterMemberIntegralDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getTheaterLevels,
  getTheaterMember,
  getTheatersIntegral,
} as TheaterMemberApi;
