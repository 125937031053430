import address, { AddressApi } from "./address";

import attentionAndCollection, {
  AttentionAndCollectionApi,
} from "./attentionAndCollection";

import captcha, { CaptchaApi } from "./captcha";

import comment, { CommentApi } from "./comment";

import coupons, { CouponsApi } from "./coupons";

import cuMemberLoginLog, { CuMemberLoginLogApi } from "./cuMemberLoginLog";

import cuMemberRecordRegion, {
  CuMemberRecordRegionApi,
} from "./cuMemberRecordRegion";

import cuUser, { CuUserApi } from "./cuUser";

import difference, { DifferenceApi } from "./difference";

import eWallet, { EWalletApi } from "./eWallet";

import giftCard, { GiftCardApi } from "./giftCard";

import integral, { IntegralApi } from "./integral";

import invoke, { InvokeApi } from "./invoke";

import memberCard, { MemberCardApi } from "./memberCard";

import messageAndPerson, { MessageAndPersonApi } from "./messageAndPerson";

import printing, { PrintingApi } from "./printing";

import record, { RecordApi } from "./record";

import repair, { RepairApi } from "./repair";

import setting, { SettingApi } from "./setting";

import theaterMember, { TheaterMemberApi } from "./theaterMember";

import viewer, { ViewerApi } from "./viewer";

import wxPush, { WxPushApi } from "./wxPush";

export class MemberApi {
  constructor(
    public address: AddressApi,
    public attentionAndCollection: AttentionAndCollectionApi,
    public captcha: CaptchaApi,
    public comment: CommentApi,
    public coupons: CouponsApi,
    public cuMemberLoginLog: CuMemberLoginLogApi,
    public cuMemberRecordRegion: CuMemberRecordRegionApi,
    public cuUser: CuUserApi,
    public difference: DifferenceApi,
    public eWallet: EWalletApi,
    public giftCard: GiftCardApi,
    public integral: IntegralApi,
    public invoke: InvokeApi,
    public memberCard: MemberCardApi,
    public messageAndPerson: MessageAndPersonApi,
    public printing: PrintingApi,
    public record: RecordApi,
    public repair: RepairApi,
    public setting: SettingApi,
    public theaterMember: TheaterMemberApi,
    public viewer: ViewerApi,
    public wxPush: WxPushApi
  ) {}
}

export default {
  address,
  attentionAndCollection,
  captcha,
  comment,
  coupons,
  cuMemberLoginLog,
  cuMemberRecordRegion,
  cuUser,
  difference,
  eWallet,
  giftCard,
  integral,
  invoke,
  memberCard,
  messageAndPerson,
  printing,
  record,
  repair,
  setting,
  theaterMember,
  viewer,
  wxPush,
} as MemberApi;
