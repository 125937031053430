import collegeStudentCertification, {
  CollegeStudentCertificationApi,
} from "./collegeStudentCertification";

import douYinOrder, { DouYinOrderApi } from "./douYinOrder";

import goodOrder, { GoodOrderApi } from "./goodOrder";

import lottery, { LotteryApi } from "./lottery";

import movieOrder, { MovieOrderApi } from "./movieOrder";

import orderDetailSeat, { OrderDetailSeatApi } from "./orderDetailSeat";

import orderInvoice, { OrderInvoiceApi } from "./orderInvoice";

import productCommonly, { ProductCommonlyApi } from "./productCommonly";

import ticketFolder, { TicketFolderApi } from "./ticketFolder";

import ticketOrder, { TicketOrderApi } from "./ticketOrder";

import videoOrder, { VideoOrderApi } from "./videoOrder";

import walletOrder, { WalletOrderApi } from "./walletOrder";

export class OrderApi {
  constructor(
    public collegeStudentCertification: CollegeStudentCertificationApi,
    public douYinOrder: DouYinOrderApi,
    public goodOrder: GoodOrderApi,
    public lottery: LotteryApi,
    public movieOrder: MovieOrderApi,
    public orderDetailSeat: OrderDetailSeatApi,
    public orderInvoice: OrderInvoiceApi,
    public productCommonly: ProductCommonlyApi,
    public ticketFolder: TicketFolderApi,
    public ticketOrder: TicketOrderApi,
    public videoOrder: VideoOrderApi,
    public walletOrder: WalletOrderApi
  ) {}
}

export default {
  collegeStudentCertification,
  douYinOrder,
  goodOrder,
  lottery,
  movieOrder,
  orderDetailSeat,
  orderInvoice,
  productCommonly,
  ticketFolder,
  ticketOrder,
  videoOrder,
  walletOrder,
} as OrderApi;
