import activityInfo, { ActivityInfoApi } from "./activityInfo";

import advert, { AdvertApi } from "./advert";

import applyActivity, { ApplyActivityApi } from "./applyActivity";

import article, { ArticleApi } from "./article";

import cloundTheaterComment, {
  CloundTheaterCommentApi,
} from "./cloundTheaterComment";

import commonInfo, { CommonInfoApi } from "./commonInfo";

import dongYiCms, { DongYiCmsApi } from "./dongYiCms";

import flashSale, { FlashSaleApi } from "./flashSale";

import lucky, { LuckyApi } from "./lucky";

import shop, { ShopApi } from "./shop";

import subject, { SubjectApi } from "./subject";

import theaterCms, { TheaterCmsApi } from "./theaterCms";

import ybyContent, { YbyContentApi } from "./ybyContent";

export class CmsApi {
  constructor(
    public activityInfo: ActivityInfoApi,
    public advert: AdvertApi,
    public applyActivity: ApplyActivityApi,
    public article: ArticleApi,
    public cloundTheaterComment: CloundTheaterCommentApi,
    public commonInfo: CommonInfoApi,
    public dongYiCms: DongYiCmsApi,
    public flashSale: FlashSaleApi,
    public lucky: LuckyApi,
    public shop: ShopApi,
    public subject: SubjectApi,
    public theaterCms: TheaterCmsApi,
    public ybyContent: YbyContentApi
  ) {}
}

export default {
  activityInfo,
  advert,
  applyActivity,
  article,
  cloundTheaterComment,
  commonInfo,
  dongYiCms,
  flashSale,
  lucky,
  shop,
  subject,
  theaterCms,
  ybyContent,
} as CmsApi;
