/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description OSS文件上传
 */
import pointUpload from "./pointUpload";
import upload from "./upload";
import uploadFileByBase64 from "./uploadFileByBase64";
import uploadWithPath from "./uploadWithPath";
import updatefileProvider from "./updatefileProvider";
import uploadFileForReturn from "./uploadFileForReturn";
import uploadFiles from "./uploadFiles";
import filesProvider from "./filesProvider";
import toZipUploadByListFile from "./toZipUploadByListFile";
import multipartUpload from "./multipartUpload";
import getSignature from "./getSignature";
import uploadTextToFile from "./uploadTextToFile";

export class UploadApi {
  constructor(
    public pointUpload: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public upload: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public uploadFileByBase64: (
      base64FileUploadDto: oss.Base64FileUploadDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public uploadWithPath: (
      fileUploadDto: oss.FileUploadDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public updatefileProvider: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public uploadFileForReturn: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oss.UploadFileReturnDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public uploadFiles: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public filesProvider: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public toZipUploadByListFile: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public multipartUpload: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSignature: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oss.SignatureDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public uploadTextToFile: (
      textFileVo: oss.TextFileVo,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  pointUpload,
  upload,
  uploadFileByBase64,
  uploadWithPath,
  updatefileProvider,
  uploadFileForReturn,
  uploadFiles,
  filesProvider,
  toZipUploadByListFile,
  multipartUpload,
  getSignature,
  uploadTextToFile,
} as UploadApi;
