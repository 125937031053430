/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 东艺剧院相关接口
 */
import getDongYiArticle from "./getDongYiArticle";
import getDongYiProductInfoLabels from "./getDongYiProductInfoLabels";
import getDongYiBanners from "./getDongYiBanners";
import getTheaterFloorProducts from "./getTheaterFloorProducts";
import getDongYiFooters from "./getDongYiFooters";
import getTheaterNavs from "./getTheaterNavs";
import getDongYiNewsOrNoticePage, {
  GetDongYiNewsOrNoticePageParams,
} from "./getDongYiNewsOrNoticePage";
import getDongYiNewsOrNotice, {
  GetDongYiNewsOrNoticeParams,
} from "./getDongYiNewsOrNotice";

export class DongYiCmsApi {
  constructor(
    public getDongYiArticle: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Article;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getDongYiProductInfoLabels: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ProductInfoLabelDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getDongYiBanners: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.TheaterBannerDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterFloorProducts: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.TheaterFloorDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getDongYiFooters: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.FooterArticlesDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterNavs: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.TheaterNavigationDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getDongYiNewsOrNoticePage: (
      params: GetDongYiNewsOrNoticePageParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.IPage<cms.TheaterNewsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getDongYiNewsOrNotice: (
      params: GetDongYiNewsOrNoticeParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.TheaterNewsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getDongYiArticle,
  getDongYiProductInfoLabels,
  getDongYiBanners,
  getTheaterFloorProducts,
  getDongYiFooters,
  getTheaterNavs,
  getDongYiNewsOrNoticePage,
  getDongYiNewsOrNotice,
} as DongYiCmsApi;
