/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 收货地址
 */
import saveOrUpdate from "./saveOrUpdate";
import remove from "./remove";
import list from "./list";
import getAddressWithPrice from "./getAddressWithPrice";

export class AddressApi {
  constructor(
    public saveOrUpdate: (
      addrReq: member.AddrReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public remove: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public list: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.MyAddrRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getAddressWithPrice: (
      addrWithPriceDTO: member.AddrWithPriceDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.AddrWithPriceRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  saveOrUpdate,
  remove,
  list,
  getAddressWithPrice,
} as AddressApi;
