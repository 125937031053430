import Vue from "vue";

import Storage from "vue-ls";
import config from "@/core/config";

import { default as WechatPlugin } from "./wechat";
import { axiosPlugin } from "@/plugins/axios";
import { ApiPlugin } from "@/api/index";
// import "@/plugins/vconsole";

import "./video";

// import "./vant";

Vue.use(WechatPlugin);
Vue.use(Storage, config.storageOptions);
Vue.use(axiosPlugin);
Vue.use(ApiPlugin);
