/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 各端有差异的接口
 */
import identify from "./identify";
import password from "./password";
import payPassword from "./payPassword";

export class DifferenceApi {
  constructor(
    public identify: (
      identifyDTO: member.IdentifyDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public password: (
      userLoginPasswordDTO: member.UserLoginPasswordDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public payPassword: (
      payPasswordDTO: member.PayPasswordDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  identify,
  password,
  payPassword,
} as DifferenceApi;
