/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 座位
 */
import getAvailableSeat, { GetAvailableSeatParams } from "./getAvailableSeat";
import getSeatsInfo from "./getSeatsInfo";

export class SeatApi {
  constructor(
    public getAvailableSeat: (
      productId: string,
      sectionId: number,
      showId: number,

      params: GetAvailableSeatParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<number>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSeatsInfo: (
      sectionId: number,
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.SeatInfoDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getAvailableSeat,
  getSeatsInfo,
} as SeatApi;
