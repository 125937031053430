/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 缓存预热
 */
import eventDelay from "./eventDelay";
import gateway from "./gateway";
import listenerDelay from "./listenerDelay";
import lockCount from "./lockCount";
import productPreCache from "./productPreCache";
import deleteProductPreCache from "./deleteProductPreCache";
import sellout from "./sellout";

export class PreCacheApi {
  constructor(
    public eventDelay: (
      listenerDelayDto: good.ListenerDelayDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public gateway: (
      listenerDelayDto: good.ListenerDelayDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public listenerDelay: (
      listenerDelayDto: good.ListenerDelayDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public lockCount: (
      listenerLockCountDto: good.ListenerLockCountDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public productPreCache: (
      productIds: Array<number>,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public deleteProductPreCache: (
      productId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public sellout: (
      productId: string,
      sellout: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  eventDelay,
  gateway,
  listenerDelay,
  lockCount,
  productPreCache,
  deleteProductPreCache,
  sellout,
} as PreCacheApi;
