/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 用code进行oauth登录,各端根据前置操作获取到code调用此接口,如果已经绑定,返回token,如果没有绑定则返回openid
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class OauthParams {
  constructor(
    public code: string,
    public clientId?: string,
    public clientSecret?: string,
    public grantType?: string,
    public loginPhoneModel?: string,
    public loginPhoneSystem?: string
  ) {}
}

export default async function (
  channel: "MP" | "MINIAPP" | "WECHAT_SCAN" | "WECHAT" | "BEST_PAY",

  params: OauthParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: oauth2.OAuth2AccessTokenDto;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/oauth2/${channel}/oauth`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
