/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 支付订单接口
 */
import cancelPayOrder, { CancelPayOrderParams } from "./cancelPayOrder";
import channel from "./channel";
import numberPayStatus, { NumberPayStatusParams } from "./numberPayStatus";
import paymentOrder from "./paymentOrder";
import orderInfo, { OrderInfoParams } from "./orderInfo";
import paymentOrderRefund, {
  PaymentOrderRefundParams,
} from "./paymentOrderRefund";
import payInfo, { PayInfoParams } from "./payInfo";
import zeroBuy, { ZeroBuyParams } from "./zeroBuy";
import payStatus, { PayStatusParams } from "./payStatus";
import payByWallet from "./payByWallet";
import balance from "./balance";
import paymentWays, { PaymentWaysParams } from "./paymentWays";

export class PaymentOrderApi {
  constructor(
    public cancelPayOrder: (
      params: CancelPayOrderParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public channel: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<payment.Codebook>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public numberPayStatus: (
      params: NumberPayStatusParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public paymentOrder: (
      paymentOrderDTO: payment.PaymentOrderDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: payment.PaymentOrderResponseDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderInfo: (
      params: OrderInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: payment.OrderInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public paymentOrderRefund: (
      params: PaymentOrderRefundParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public payInfo: (
      params: PayInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: payment.PayInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public zeroBuy: (
      params: ZeroBuyParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public payStatus: (
      params: PayStatusParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public payByWallet: (
      walletPaymentDTO: payment.WalletPaymentDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public balance: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: payment.PaymentWayDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public paymentWays: (
      params: PaymentWaysParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<payment.PaymentWayDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  cancelPayOrder,
  channel,
  numberPayStatus,
  paymentOrder,
  orderInfo,
  paymentOrderRefund,
  payInfo,
  zeroBuy,
  payStatus,
  payByWallet,
  balance,
  paymentWays,
} as PaymentOrderApi;
