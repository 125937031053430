/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 剧院小程序授权
 */
import getAppletInitConfig from "./getAppletInitConfig";
import authorization from "./authorization";

export class AppletsUserApi {
  constructor(
    public getAppletInitConfig: (
      appletsConfigInfoRequest: oauth2.AppletsConfigInfoRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.AppletsConfigInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public authorization: (
      miniProgramAuth: oauth2.MiniAppAuthorizationDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.OAuth2AccessTokenDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getAppletInitConfig,
  authorization,
} as AppletsUserApi;
