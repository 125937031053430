/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 收藏和关注
 */
import isAttention, { IsAttentionParams } from "./isAttention";
import updateTheaterShopFollow from "./updateTheaterShopFollow";
import getTheaterShopFollowList, {
  GetTheaterShopFollowListParams,
} from "./getTheaterShopFollowList";
import upFollowIsDelInfo from "./upFollowIsDelInfo";
import add from "./add";
import getCollectProjectList, {
  GetCollectProjectListParams,
} from "./getCollectProjectList";
import selFlagShipStore, { SelFlagShipStoreParams } from "./selFlagShipStore";
import collectProductAndSellingReminds from "./collectProductAndSellingReminds";
import upCollectionIsDelInfo from "./upCollectionIsDelInfo";
import editShowRemind from "./editShowRemind";
import userSaleRemind from "./userSaleRemind";
import getMemberShow from "./getMemberShow";
import addOrDelete from "./addOrDelete";
import isCollect from "./isCollect";
import getCollectTours from "./getCollectTours";
import delCollectTours from "./delCollectTours";

export class AttentionAndCollectionApi {
  constructor(
    public isAttention: (
      params: IsAttentionParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public updateTheaterShopFollow: (
      theaterId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterShopFollowList: (
      params: GetTheaterShopFollowListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.TheaterDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public upFollowIsDelInfo: (
      attentionsDelDTO: member.AttentionsDelDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public add: (
      productId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCollectProjectList: (
      params: GetCollectProjectListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.CollectProjectRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selFlagShipStore: (
      params: SelFlagShipStoreParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.FlagshipStoreDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public collectProductAndSellingReminds: (
      productId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.ProductInfoDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public upCollectionIsDelInfo: (
      projectDelDTO: member.ProjectDelDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public editShowRemind: (
      isRemind: string,
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.RemindDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public userSaleRemind: (
      productId: number,
      saleBeginTime: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMemberShow: (
      productId: number,
      saleBeginTime: number,
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.ProductSaleShowRemindDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public addOrDelete: (
      tourId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public isCollect: (
      tourId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCollectTours: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.CollectTourDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public delCollectTours: (
      tourIds: member.ToursDelDTo,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  isAttention,
  updateTheaterShopFollow,
  getTheaterShopFollowList,
  upFollowIsDelInfo,
  add,
  getCollectProjectList,
  selFlagShipStore,
  collectProductAndSellingReminds,
  upCollectionIsDelInfo,
  editShowRemind,
  userSaleRemind,
  getMemberShow,
  addOrDelete,
  isCollect,
  getCollectTours,
  delCollectTours,
} as AttentionAndCollectionApi;
