/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 限时抢购
 */
import queryFlashSaleActivityProductInfo, {
  QueryFlashSaleActivityProductInfoParams,
} from "./queryFlashSaleActivityProductInfo";
import getFlashSaleActivityTimes from "./getFlashSaleActivityTimes";
import getLimitCouponActivity, {
  GetLimitCouponActivityParams,
} from "./getLimitCouponActivity";
import getLimitCouponActivityTimes from "./getLimitCouponActivityTimes";

export class FlashSaleApi {
  constructor(
    public queryFlashSaleActivityProductInfo: (
      params: QueryFlashSaleActivityProductInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.flashSaleProjectDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getFlashSaleActivityTimes: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ActivityLimitCouponTimeDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getLimitCouponActivity: (
      params: GetLimitCouponActivityParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.ActivityLimitCouponDetailDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getLimitCouponActivityTimes: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ActivityLimitCouponTimeDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  queryFlashSaleActivityProductInfo,
  getFlashSaleActivityTimes,
  getLimitCouponActivity,
  getLimitCouponActivityTimes,
} as FlashSaleApi;
