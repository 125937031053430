export interface StorageOptions {
  namespace: string;
  name: string;
  storage: "session" | "local" | "memory";
}

export interface Menu {
  name: string;
  icon: string | { active: string; inactive: string };
  title: string;
  to: string;
  info: number;
}

export interface App {
  name: string;
  title: string;
  description: string;
  copyright: string;
}

export interface Http {
  prefix: string;
  timeout: number;
}

export interface Config {
  isProduction: boolean;
  storageOptions: StorageOptions;
  menus: Array<Menu>;
  app: App;
  http: Http;
  platOrTheater: string;
  payment: {
    channelEnumH5: "PLAT_H5" | "PLAT_H5_WX" | "THEATRE_H5" | "THEATRE_H5_WX";
    channelEnumH5WX: "PLAT_H5" | "PLAT_H5_WX" | "THEATRE_H5" | "THEATRE_H5_WX";
  };
}

// 是否是生产环境
const isProduction = process.env.VUE_APP_IS_PRODUCTION == "1";

export default {
  isProduction,
  storageOptions: {
    namespace: "van__", // key prefix
    name: "ls", // name variable Vue.[ls] or this.[$ls],
    storage: "session", // storage name session, local, memory}
  },
  menus: [
    {
      name: "home",
      icon: "require('@/assets/icon-newHome-icon-1@3x.png')",
      title: "首页",
      to: "home",
      info: 0,
    },
    { name: "show", icon: "search", title: "演出", to: "mine", info: 0 },
    {
      name: "friends",
      icon: "friends-o",
      title: "我的",
      to: "mine",
      // info: 5,
    },
    // {
    //   name: "setting",
    //   icon: "setting-o",
    //   title: "设置",
    //   to: "setting",
    //   info: 20,
    // },
  ],
  app: {
    name: "Vant Wechat",
    title: "Vant Wechat",
    description: "基于Vant 开发的微信公众号",
    copyright: "Copyright &copy;  vang.kerbores.com",
  },
  http: {
    prefix: process.env.VUE_APP_PREFIX_URL,
    timeout: 10 * 1000,
  },
  platOrTheater: "plat", // 是平台还是剧院
  // 支付时需要传的参数，用来表示：渠道（平台还是剧院）-环境（是不是微信浏览器）
  payment: {
    channelEnumH5: "PLAT_H5",
    channelEnumH5WX: "PLAT_H5_WX",
  },
} as Config;
