import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface MenuState {
  show: boolean;
  menus: Array<{
    name: string;
    icon: string | { active: string; inactive: string };
    title: string;
    to: string;
    info: number;
  }>;
}

@Module({ dynamic: true, store, name: "menu" })
class Menu extends VuexModule implements MenuState {
  show = true;
  menus: Array<{
    name: string;
    icon: string | { active: string; inactive: string };
    title: string;
    to: string;
    info: number;
  }> = [{ name: "test", icon: "", title: "", to: "", info: 0 }];

  @Mutation
  public init_PERSIST(
    menus: Array<{
      name: string;
      icon: string | { active: string; inactive: string };
      title: string;
      to: string;
      info: number;
    }>
  ) {
    this.menus = menus;
    this.show = true;
  }

  @Mutation
  public showMenu_PERSIST() {
    this.show = true;
  }

  @Mutation
  public hiddenMenu_PERSIST() {
    this.show = false;
  }
  @Mutation
  public setInfo_PERSIST(option: { name: string; info: number }) {
    const menu = this.menus.find((menu) => menu.name === option.name);
    if (menu) {
      menu.info = option.info;
    }
  }
}

export const MenuModule = getModule(Menu);
