/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 娱伯牙文章内容
 */
import getYbyContentDetail from "./getYbyContentDetail";
import searchYbyModuleInfo from "./searchYbyModuleInfo";
import searchYbyContentListPage, {
  SearchYbyContentListPageParams,
} from "./searchYbyContentListPage";
import getYbyRelationList from "./getYbyRelationList";
import getYbyProductScoreRes from "./getYbyProductScoreRes";

export class YbyContentApi {
  constructor(
    public getYbyContentDetail: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.YbyContentDetailVO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchYbyModuleInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.YbyModuleVO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchYbyContentListPage: (
      params: SearchYbyContentListPageParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.YbyContentListVO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getYbyRelationList: (
      businessId: string,
      businessType: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.YbyContentListVO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getYbyProductScoreRes: (
      businessId: string,
      businessType: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.productScoreRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getYbyContentDetail,
  searchYbyModuleInfo,
  searchYbyContentListPage,
  getYbyRelationList,
  getYbyProductScoreRes,
} as YbyContentApi;
