/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 获取短信验证码并返回uuid
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class SmsParams {
  constructor(
    public code?: string,
    public mobile?: string,
    public scene?: "PASSWORD" | "IDENTITY" | "WALLET" | "MOBILE" | "IDENTIFY",
    public uuid?: string
  ) {}
}

export default async function (
  params: SmsParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: string;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/member/sms-captcha`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
