/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 设置
 */
import bind from "./bind";
import getAccountInfo from "./getAccountInfo";
import unbind, { UnbindParams } from "./unbind";
import checkingIfUpdate from "./checkingIfUpdate";
import getVersionGuide from "./getVersionGuide";
import add from "./add";
import graphicCaptcha, { GraphicCaptchaParams } from "./graphicCaptcha";
import saveIdentify from "./saveIdentify";
import getMemberCaptcha from "./getMemberCaptcha";
import getIdentifyInfo from "./getIdentifyInfo";
import getPasswordCaptcha from "./getPasswordCaptcha";
import selUserInfo from "./selUserInfo";
import updateUserInfo from "./updateUserInfo";
import updatePhone from "./updatePhone";
import getPhoneCaptcha from "./getPhoneCaptcha";
import getPhoneCaptchaNoSlide from "./getPhoneCaptchaNoSlide";
import checkCaptcha from "./checkCaptcha";

export class SettingApi {
  constructor(
    public bind: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.AccountBindingDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getAccountInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.AccountInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public unbind: (
      params: UnbindParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public checkingIfUpdate: (
      versionNum: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.AppVersionDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getVersionGuide: (
      pictureType: string,
      versionNum: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.GuideResponseOutObjects;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public add: (
      feedbackDto: member.FeedbackDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public graphicCaptcha: (
      params: GraphicCaptchaParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.GraphicCaptchaDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public saveIdentify: (
      identifyRequest: member.IdentifyRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMemberCaptcha: (
      identifyCaptchaReq: member.IdentifyCaptchaReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getIdentifyInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.IdentifyInfoRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPasswordCaptcha: (
      updatePasswordRes: member.UpdatePasswordRes,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selUserInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.UserInformationRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public updateUserInfo: (
      userInformationDTO: member.UserInformationDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.UserInformationRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public updatePhone: (
      phone: member.PhoneUpdateInfoDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPhoneCaptcha: (
      phoneCaptchaRequest: member.PhoneCaptchaRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPhoneCaptchaNoSlide: (
      phoneCaptchaRequest: member.PhoneCaptchaRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public checkCaptcha: (
      phoneCaptchaRequest: member.PhoneUpdateInfoDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  bind,
  getAccountInfo,
  unbind,
  checkingIfUpdate,
  getVersionGuide,
  add,
  graphicCaptcha,
  saveIdentify,
  getMemberCaptcha,
  getIdentifyInfo,
  getPasswordCaptcha,
  selUserInfo,
  updateUserInfo,
  updatePhone,
  getPhoneCaptcha,
  getPhoneCaptchaNoSlide,
  checkCaptcha,
} as SettingApi;
