/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 翼支付相关-支付订单接口
 */
import bestPayPaymentOrder from "./bestPayPaymentOrder";

export class BestPayPaymentOrderApi {
  constructor(
    public bestPayPaymentOrder: (
      paymentOrderDTO: payment.PaymentOrderDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: payment.BestPayResponseBO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  bestPayPaymentOrder,
} as BestPayPaymentOrderApi;
