/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 订单座位详情表
 */
import captcha from "./captcha";
import orderDetailSeat from "./orderDetailSeat";
import registerTicket from "./registerTicket";
import viewTicket from "./viewTicket";

export class OrderDetailSeatApi {
  constructor(
    public captcha: (
      phoneCaptchaRequest: order.PhoneCaptchaRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderDetailSeat: (
      orderDetailSeatDto: order.OrderDetailSeatDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.OrderDetailExpertResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public registerTicket: (
      orderDetailSeatDto: order.OrderDetailSeatDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public viewTicket: (
      orderDetailSeatDto: order.OrderDetailSeatDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.OrderDetailSeatTicket;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  captcha,
  orderDetailSeat,
  registerTicket,
  viewTicket,
} as OrderDetailSeatApi;
