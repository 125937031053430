/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 票夹接口
 */
import dataSyncTicketFolder from "./dataSyncTicketFolder";
import getTicketFolderDetailById from "./getTicketFolderDetailById";
import getTicketFolderDetailByIdForTest from "./getTicketFolderDetailByIdForTest";
import getETicketCode, { GetETicketCodeParams } from "./getETicketCode";
import orderETicketQrcode, {
  OrderETicketQrcodeParams,
} from "./orderETicketQrcode";
import getTicketFolder from "./getTicketFolder";
import getTicketFolderNum, {
  GetTicketFolderNumParams,
} from "./getTicketFolderNum";
import TicketFolderRefresh from "./TicketFolderRefresh";
import getShowRemind from "./getShowRemind";
import verifyTicketFolderUser from "./verifyTicketFolderUser";

export class TicketFolderApi {
  constructor(
    public dataSyncTicketFolder: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTicketFolderDetailById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.TicketFolderDetailDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTicketFolderDetailByIdForTest: (
      id: string,
      memberId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.TicketFolderDetailDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getETicketCode: (
      params: GetETicketCodeParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public orderETicketQrcode: (
      params: OrderETicketQrcodeParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.QrcodeDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTicketFolder: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.TicketFolderDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTicketFolderNum: (
      params: GetTicketFolderNumParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.TicketFolderDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public TicketFolderRefresh: (
      orderDetailId: string,
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.TicketItemRepository;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getShowRemind: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.ShowRemindRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public verifyTicketFolderUser: (
      ticketFolderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  dataSyncTicketFolder,
  getTicketFolderDetailById,
  getTicketFolderDetailByIdForTest,
  getETicketCode,
  orderETicketQrcode,
  getTicketFolder,
  getTicketFolderNum,
  TicketFolderRefresh,
  getShowRemind,
  verifyTicketFolderUser,
} as TicketFolderApi;
