/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 服务之间调用的接口
 */
import cancelOrder, { CancelOrderParams } from "./cancelOrder";
import zeroToBuy, { ZeroToBuyParams } from "./zeroToBuy";

export class InvokeApi {
  constructor(
    public cancelOrder: (
      params: CancelOrderParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public zeroToBuy: (
      params: ZeroToBuyParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  cancelOrder,
  zeroToBuy,
} as InvokeApi;
