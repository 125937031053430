/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 字典服务类接口
 */
import getCertType from "./getCertType";
import listDictByDictKey from "./listDictByDictKey";
import getPayWayDesc, { GetPayWayDescParams } from "./getPayWayDesc";

export class DictApi {
  constructor(
    public getCertType: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.GBdDict>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public listDictByDictKey: (
      codeKey: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<dictionary.GBdDictResponse>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPayWayDesc: (
      params: GetPayWayDescParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getCertType,
  listDictByDictKey,
  getPayWayDesc,
} as DictApi;
