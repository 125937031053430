import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";

export interface HomeDataState {
  homeCategoryTabName: string; // 首页，分类 Tab
  classFicationCategoryTabName: string; // 分类页，分类 Tab
  advertImages: Array<cms.TheaterAdvertDto> | null; // 首页弹框广告
  showNotice: boolean; // 首页公告是否关闭
  theme: cms.ThemeDto | undefined;
}

@Module({ dynamic: true, store, name: "homeData" })
class HomeData extends VuexModule implements HomeDataState {
  homeCategoryTabName = "0";
  classFicationCategoryTabName = "";
  advertImages: Array<cms.TheaterAdvertDto> | null = null;
  showNotice = true;
  theme: cms.ThemeDto | undefined = {}; // 主题

  @Mutation
  setStateFromStorage() {
    Object.assign(this, loadFromSessionStorage("vuex", "homeData"));
  }

  @Mutation
  SET_showNotice_PERSIST(showNotice: boolean): void {
    this.showNotice = showNotice;
  }

  @Mutation
  SET_homeCategoryTabName_PERSIST(homeCategoryTabName: string): void {
    this.homeCategoryTabName = homeCategoryTabName;
  }

  @Mutation
  SET_classFicationCategoryTabName_PERSIST(
    classFicationCategoryTabName: string
  ): void {
    this.classFicationCategoryTabName = classFicationCategoryTabName;
  }

  @Mutation
  SET_advertImages_PERSIST(advertImages: Array<cms.TheaterAdvertDto>): void {
    this.advertImages = advertImages;
  }

  // 设置用于显示的【主题】数据
  @Mutation
  SET_theme(theme: cms.ThemeDto | undefined | null) {
    this.theme = theme || undefined;
  }
}

export const HomeDataModule = getModule(HomeData);
