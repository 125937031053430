import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import VueRouter from "vue-router";
import { ChannelModule } from "@/store/modules/channel";
import wx from "weixin-js-sdk";
import { loadFromSessionStorage } from "@/store/utils";

declare type Dictionary<T> = { [key: string]: T };

export interface RouteRecordState {
  /**
   * 进入登录页前的路由
   * 关闭登录页或登录成功后跳转时，用来做判断
   * 如果为 null 则跳转到首页（比如用户直接复制粘贴登录页 URL 的情况）
   */
  beforeLoginPage: {
    path: string;
    query: Dictionary<string | (string | null)[]>;
  } | null;
  /**
   * 在登录页登录成功后，是否后退：router.go(-1)
   * 如果为 false，则会替换当前页面：router.replace({
   *    path: beforeLoginPage.path,
   *    query: beforeLoginPage.query
   * })
   */
  goBackAfterLogin: boolean;
}

@Module({ dynamic: true, store, name: "routeRecord" })
class RouteRecord extends VuexModule implements RouteRecordState {
  beforeLoginPage: {
    path: string;
    query: Dictionary<string | (string | null)[]>;
  } | null = null;
  goBackAfterLogin = false;

  @Mutation
  setStateFromStorage() {
    Object.assign(this, loadFromSessionStorage("vuex", "routeRecord"));
  }

  @Mutation
  SET_beforeLoginPage_PERSIST(router: VueRouter): void {
    this.beforeLoginPage = {
      path: router.currentRoute.path,
      query: router.currentRoute.query,
    };
  }

  @Mutation
  GO_TO_LOGIN_PAGE_PERSIST({
    router,
    newPage,
    goBackAfterLogin,
  }: {
    router: VueRouter;
    newPage?: boolean;
    goBackAfterLogin?: boolean;
  }): void {
    switch (ChannelModule.channel) {
      case "plat_ios": // IOS
      case "plat_android": // Android
      case "plat_h5": // H5
        this.beforeLoginPage = {
          path: router.currentRoute.path,
          query: router.currentRoute.query,
        };
        this.goBackAfterLogin = goBackAfterLogin || false;
        if (newPage) {
          router.push("/login");
        } else {
          router.replace("/login");
        }
        break;
      case "plat_wechat_miniapp": // 小程序
        if (newPage) {
          wx.miniProgram.navigateTo({
            url: "/pagesCenter/bindPhone/bindPhone",
          });
        } else {
          wx.miniProgram.redirectTo({
            url: "/pagesCenter/bindPhone/bindPhone",
          });
        }
        break;
    }
  }
}

export const RouteRecordModule = getModule(RouteRecord);
