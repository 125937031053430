/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 评论
 */
import comment from "./comment";
import praise, { PraiseParams } from "./praise";
import getProductComments, {
  GetProductCommentsParams,
} from "./getProductComments";
import delCommentInfoController from "./delCommentInfoController";
import praiseCommentList, {
  PraiseCommentListParams,
} from "./praiseCommentList";

export class CommentApi {
  constructor(
    public comment: (
      projectCommentDTO: member.ProjectCommentDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.ProductCommentDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public praise: (
      params: PraiseParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.CommentLikeDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductComments: (
      params: GetProductCommentsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.ProductCommentDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public delCommentInfoController: (
      commentsDelDTO: member.CommentsDelDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public praiseCommentList: (
      businessType: string,
      productId: string,
      params: PraiseCommentListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.ProductCommentDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  comment,
  praise,
  getProductComments,
  delCommentInfoController,
  praiseCommentList,
} as CommentApi;
