import commodity, { CommodityApi } from "./commodity";

import commodityIntegralRange, {
  CommodityIntegralRangeApi,
} from "./commodityIntegralRange";

import dict, { DictApi } from "./dict";

import holidays, { HolidaysApi } from "./holidays";

import performance, { PerformanceApi } from "./performance";

import placeCity, { PlaceCityApi } from "./placeCity";

import region, { RegionApi } from "./region";

export class DictionaryApi {
  constructor(
    public commodity: CommodityApi,
    public commodityIntegralRange: CommodityIntegralRangeApi,
    public dict: DictApi,
    public holidays: HolidaysApi,
    public performance: PerformanceApi,
    public placeCity: PlaceCityApi,
    public region: RegionApi
  ) {}
}

export default {
  commodity,
  commodityIntegralRange,
  dict,
  holidays,
  performance,
  placeCity,
  region,
} as DictionaryApi;
