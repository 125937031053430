/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 会员积分
 */
import count from "./count";
import getExchangesHis, { GetExchangesHisParams } from "./getExchangesHis";
import getIncomePageList, {
  GetIncomePageListParams,
} from "./getIncomePageList";
import getIntegral from "./getIntegral";
import take, { TakeParams } from "./take";

export class IntegralApi {
  constructor(
    public count: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.IntegralCountInfo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getExchangesHis: (
      params: GetExchangesHisParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.ExchangesDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getIncomePageList: (
      params: GetIncomePageListParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.IntegralExpendDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getIntegral: (
      integralReq: member.IntegralReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.IntegralRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public take: (
      params: TakeParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.IntegralIncomeDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  count,
  getExchangesHis,
  getIncomePageList,
  getIntegral,
  take,
} as IntegralApi;
