/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 商城
 */
import searchCommodityExchanges from "./searchCommodityExchanges";
import findCommoditiesMember, {
  FindCommoditiesMemberParams,
} from "./findCommoditiesMember";
import findCommoditiesMemberBrand, {
  FindCommoditiesMemberBrandParams,
} from "./findCommoditiesMemberBrand";
import findCommoditiesMemberMine, {
  FindCommoditiesMemberMineParams,
} from "./findCommoditiesMemberMine";
import findCommoditiesPeriphery, {
  FindCommoditiesPeripheryParams,
} from "./findCommoditiesPeriphery";
import findRecommend, { FindRecommendParams } from "./findRecommend";
import searchCommodities from "./searchCommodities";
import searchPCCommodities from "./searchPCCommodities";
import getCommodityDetail from "./getCommodityDetail";
import getSubjectCommodities from "./getSubjectCommodities";

export class CommodityApi {
  constructor(
    public searchCommodityExchanges: (
      condition: good.CommodityCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findCommoditiesMember: (
      params: FindCommoditiesMemberParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findCommoditiesMemberBrand: (
      memberGradeId: string,

      params: FindCommoditiesMemberBrandParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findCommoditiesMemberMine: (
      params: FindCommoditiesMemberMineParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findCommoditiesPeriphery: (
      id: string,
      type: "COMMODITY_TOUR" | "COMMODITY_PRODUCT",

      params: FindCommoditiesPeripheryParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findRecommend: (
      params: FindRecommendParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchCommodities: (
      condition: good.CommodityCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchPCCommodities: (
      condition: good.CommodityPcCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCommodityDetail: (
      commodityId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.CommodityDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSubjectCommodities: (
      commodityCondition: good.SubjectCommodityCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.CommodityDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  searchCommodityExchanges,
  findCommoditiesMember,
  findCommoditiesMemberBrand,
  findCommoditiesMemberMine,
  findCommoditiesPeriphery,
  findRecommend,
  searchCommodities,
  searchPCCommodities,
  getCommodityDetail,
  getSubjectCommodities,
} as CommodityApi;
