import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";

export interface ChannelDistributionState {
  templateType: string; // 渠道分销-模板类型
  distributionChannelId: string; // 渠道分销-渠道 ID
  distributionSeriesId: string; // 渠道分销-系列 ID
}

@Module({ dynamic: true, store, name: "channelDistribution" })
class ChannelDistribution
  extends VuexModule
  implements ChannelDistributionState
{
  templateType = "";
  distributionChannelId = "";
  distributionSeriesId = "";

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromSessionStorage("vuex", "channelDistribution"));
  }

  @Mutation
  resetAllData_PERSIST() {
    this.templateType = "";
    this.distributionChannelId = "";
    this.distributionSeriesId = "";
  }

  @Mutation
  SET_templateType_PERSIST(templateType: string) {
    this.templateType = templateType;
  }

  @Mutation
  SET_distributionChannelId_PERSIST(distributionChannelId: string) {
    this.distributionChannelId = distributionChannelId;
  }

  @Mutation
  SET_distributionSeriesId_PERSIST(distributionSeriesId: string) {
    this.distributionSeriesId = distributionSeriesId;
  }
}

export const ChannelDistributionModule = getModule(ChannelDistribution);
