/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 云剧院配置
 */
import getVideoCategoryList from "./getVideoCategoryList";
import getCloundTheaterRenvation from "./getCloundTheaterRenvation";

export class CloundTheaterConfigApi {
  constructor(
    public getVideoCategoryList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cloundtheater.VideoCategoryDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCloundTheaterRenvation: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cloundtheater.RenvationDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getVideoCategoryList,
  getCloundTheaterRenvation,
} as CloundTheaterConfigApi;
