/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 删除我的印花兑换记录
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class DeleteExchangeRecordParams {
  constructor(public orderId: string) {}
}

export default async function (
  params: DeleteExchangeRecordParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: boolean;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "delete",
    url: `/member/exchangeRecord/delete`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
