/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 搜索
 */
import findCategoryMatchCount from "./findCategoryMatchCount";
import findCategoryMatchCountV2 from "./findCategoryMatchCountV2";
import findHistory from "./findHistory";
import delHistory from "./delHistory";
import findLenovo from "./findLenovo";
import setSearchSyncList from "./setSearchSyncList";

export class SearchApi {
  constructor(
    public findCategoryMatchCount: (
      countCondition: good.CategoryMacthCountCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.CategoryMatchCountDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findCategoryMatchCountV2: (
      countCondition: good.CategoryMacthCountCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.CategoryMatchCountDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findHistory: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.UserSearchHistory;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public delHistory: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findLenovo: (
      keyword: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.SearchLenovoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public setSearchSyncList: (
      userSearchHistory: good.UserSearchHistory,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.UserSearchHistory;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findCategoryMatchCount,
  findCategoryMatchCountV2,
  findHistory,
  delHistory,
  findLenovo,
  setSearchSyncList,
} as SearchApi;
