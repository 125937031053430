/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 场次
 */
import checkPriorityPurchase from "./checkPriorityPurchase";
import productCollectRecord from "./productCollectRecord";
import getProductCollectRecordByProductIdByProductReservation from "./getProductCollectRecordByProductIdByProductReservation";
import getShowPrice from "./getShowPrice";
import findResidue from "./findResidue";
import getSectionInfo, { GetSectionInfoParams } from "./getSectionInfo";
import getShowInfosByProjectId, {
  GetShowInfosByProjectIdParams,
} from "./getShowInfosByProjectId";
import getShowInfos, { GetShowInfosParams } from "./getShowInfos";

export class ShowApi {
  constructor(
    public checkPriorityPurchase: (
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public productCollectRecord: (
      productId: number,

      success?: ({
        data,
        code,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        code: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductCollectRecordByProductIdByProductReservation: (
      productId: number,
      productReservation: boolean,

      success?: ({
        data,
        code,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        code: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getShowPrice: (
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.ShowPriceDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findResidue: (
      showId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: number;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSectionInfo: (
      showId: number,

      params: GetSectionInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.SectionDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getShowInfosByProjectId: (
      projectId: string,

      params: GetShowInfosByProjectIdParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.ProjectShowsDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getShowInfos: (
      productId: number,

      params: GetShowInfosParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.ProjectShowsDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  checkPriorityPurchase,
  productCollectRecord,
  getProductCollectRecordByProductIdByProductReservation,
  getShowPrice,
  findResidue,
  getSectionInfo,
  getShowInfosByProjectId,
  getShowInfos,
} as ShowApi;
