/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 东艺演出
 */
import getVenueList from "./getVenueList";
import theaterShopDongYiProducts from "./theaterShopDongYiProducts";
import getShowMonthProduct from "./getShowMonthProduct";

export class DongYiGoodApi {
  constructor(
    public getVenueList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.DongYiVenueDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public theaterShopDongYiProducts: (
      condition: good.ShopCategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.ShopProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getShowMonthProduct: (
      month: number,
      year: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.DongYiHolidaysDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getVenueList,
  theaterShopDongYiProducts,
  getShowMonthProduct,
} as DongYiGoodApi;
