/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 礼品卡
 */
import bindingGiftCard from "./bindingGiftCard";
import giftCardConsumption, {
  GiftCardConsumptionParams,
} from "./giftCardConsumption";
import selTranslateGiftCard, {
  SelTranslateGiftCardParams,
} from "./selTranslateGiftCard";
import selMyselfGiftCardInfo, {
  SelMyselfGiftCardInfoParams,
} from "./selMyselfGiftCardInfo";

export class GiftCardApi {
  constructor(
    public bindingGiftCard: (
      giftCardDTO: member.GiftCardDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public giftCardConsumption: (
      params: GiftCardConsumptionParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.GiftCardConsumptionDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selTranslateGiftCard: (
      params: SelTranslateGiftCardParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<member.GiftCardRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selMyselfGiftCardInfo: (
      params: SelMyselfGiftCardInfoParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.GiftCardRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  bindingGiftCard,
  giftCardConsumption,
  selTranslateGiftCard,
  selMyselfGiftCardInfo,
} as GiftCardApi;
