import cloundTheaterConfig, {
  CloundTheaterConfigApi,
} from "./cloundTheaterConfig";

import cloundTheaterVideo, {
  CloundTheaterVideoApi,
} from "./cloundTheaterVideo";

export class CloundtheaterApi {
  constructor(
    public cloundTheaterConfig: CloundTheaterConfigApi,
    public cloundTheaterVideo: CloundTheaterVideoApi
  ) {}
}

export default {
  cloundTheaterConfig,
  cloundTheaterVideo,
} as CloundtheaterApi;
