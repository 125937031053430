/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 巡演
 */
import getTourProjectInfo from "./getTourProjectInfo";
import getProjectById from "./getProjectById";

export class TourApi {
  constructor(
    public getTourProjectInfo: (
      tourId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.TourDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProjectById: (
      tourId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.TourProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getTourProjectInfo,
  getProjectById,
} as TourApi;
