/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 客户端缓存
 */
import clientCache, { ClientCacheParams } from "./clientCache";
import clientCacheProducts from "./clientCacheProducts";
import initRecommendCacheProducts from "./initRecommendCacheProducts";
import initSearchCacheProducts from "./initSearchCacheProducts";

export class CleintCacheApi {
  constructor(
    public clientCache: (
      params: ClientCacheParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public clientCacheProducts: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<number>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public initRecommendCacheProducts: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public initSearchCacheProducts: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  clientCache,
  clientCacheProducts,
  initRecommendCacheProducts,
  initSearchCacheProducts,
} as CleintCacheApi;
