/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 专题管理
 */
import getSubjectGoods from "./getSubjectGoods";
import getSubjectProductNavigation from "./getSubjectProductNavigation";
import getSubjectProducts from "./getSubjectProducts";
import findSubjectInfoById from "./findSubjectInfoById";
import findNewSubjectInfoById from "./findNewSubjectInfoById";

export class SubjectApi {
  constructor(
    public getSubjectGoods: (
      goodsReq: cms.SubjectGoodsReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.SubjectGoodsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSubjectProductNavigation: (
      specialLayoutId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.SubjectProductNavigationDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getSubjectProducts: (
      goodsReq: cms.SubjectProductsReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.SubjectProductsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findSubjectInfoById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.SubjectDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findNewSubjectInfoById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.SubjectInfoDetailDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getSubjectGoods,
  getSubjectProductNavigation,
  getSubjectProducts,
  findSubjectInfoById,
  findNewSubjectInfoById,
} as SubjectApi;
