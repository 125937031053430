/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 电子钱包
 */
import getDirectlyAmount from "./getDirectlyAmount";
import saveWalletExtractApply from "./saveWalletExtractApply";
import getExtractDetail from "./getExtractDetail";
import identity from "./identity";
import setPassword from "./setPassword";
import sendCaptcha from "./sendCaptcha";
import getEWallet from "./getEWallet";
import getEWalletDetail, { GetEWalletDetailParams } from "./getEWalletDetail";
import getEWalletInfo from "./getEWalletInfo";

export class EWalletApi {
  constructor(
    public getDirectlyAmount: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: number;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public saveWalletExtractApply: (
      extractApply: member.ExtractApplyDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getExtractDetail: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.ExtractApplyDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public identity: (
      identityUser: member.IdentityUserDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public setPassword: (
      password: member.UserLoginPasswordDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public sendCaptcha: (
      identifyCaptchaReq: member.IdentifyCaptchaReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getEWallet: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.EWalletInfoDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getEWalletDetail: (
      params: GetEWalletDetailParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.Page<member.EWalletDetailDTO>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getEWalletInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.EWalletDTo;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getDirectlyAmount,
  saveWalletExtractApply,
  getExtractDetail,
  identity,
  setPassword,
  sendCaptcha,
  getEWallet,
  getEWalletDetail,
  getEWalletInfo,
} as EWalletApi;
