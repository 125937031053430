import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromStorage } from "@/store/utils";

export interface DeleteLockSeatState {
  orderUuid: string; // 预订单对应的 uuid
}

@Module({ dynamic: true, store, name: "deleteLockSeat" })
class DeleteLockSeat extends VuexModule implements DeleteLockSeatState {
  orderUuid = "";

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromStorage("vuex", "deleteLockSeat"));
  }

  @Mutation
  SET_orderUuid_PERSIST(orderUuid: string) {
    this.orderUuid = orderUuid;
  }
}

export const DeleteLockSeatModule = getModule(DeleteLockSeat);
