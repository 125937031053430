/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 社会化登录接口
 */
import authUrl, { AuthUrlParams } from "./authUrl";
import iosOauth from "./iosOauth";
import isoBindWxUser from "./isoBindWxUser";
import createJsapiSignature, {
  CreateJsapiSignatureParams,
} from "./createJsapiSignature";
import qrOption, { QrOptionParams } from "./qrOption";
import bind from "./bind";
import bindWxUser from "./bindWxUser";
import oauth, { OauthParams } from "./oauth";

export class SocialLoginApi {
  constructor(
    public authUrl: (
      params: AuthUrlParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public iosOauth: (
      iOSWechatDto: oauth2.IOSWechatDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.OAuth2AccessTokenDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public isoBindWxUser: (
      iOSWechatDto: oauth2.IOSWechatDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public createJsapiSignature: (
      params: CreateJsapiSignatureParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.WxJsapiSignature;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public qrOption: (
      params: QrOptionParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.WxLoginDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public bind: (
      socialLoginBindDTO: oauth2.SocialLoginBindDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.Token;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public bindWxUser: (
      wxBindDto: oauth2.WXBindDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public oauth: (
      channel: "MP" | "MINIAPP" | "WECHAT_SCAN" | "WECHAT" | "BEST_PAY",

      params: OauthParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oauth2.OAuth2AccessTokenDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  authUrl,
  iosOauth,
  isoBindWxUser,
  createJsapiSignature,
  qrOption,
  bind,
  bindWxUser,
  oauth,
} as SocialLoginApi;
