/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 活动报名
 */
import searchApplyActivityPager from "./searchApplyActivityPager";
import searchAllApplyActivityPager from "./searchAllApplyActivityPager";
import getApplyActivitiesList from "./getApplyActivitiesList";
import findApplyActivitiesRegistered from "./findApplyActivitiesRegistered";
import findApplyActivityInfoMine from "./findApplyActivityInfoMine";
import findApplyActivityFinalist, {
  FindApplyActivityFinalistParams,
} from "./findApplyActivityFinalist";
import findApplyActivityByActivityId from "./findApplyActivityByActivityId";
import findApplyActivityRegistered from "./findApplyActivityRegistered";
import cancelRegister from "./cancelRegister";
import listApplyActivityApplied from "./listApplyActivityApplied";
import saveApplyActivityRegistered from "./saveApplyActivityRegistered";
import signIn from "./signIn";

export class ApplyActivityApi {
  constructor(
    public searchApplyActivityPager: (
      activityRequest: cms.ApplyActivityRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ApplyActivityListDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchAllApplyActivityPager: (
      activityRequest: cms.ApplyActivityRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ApplyActivityListDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getApplyActivitiesList: (
      activityListReq: cms.ApplyActivityListRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ActivityHistoryDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findApplyActivitiesRegistered: (
      activityRequest: cms.ApplyActivityRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ApplyActivityListDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findApplyActivityInfoMine: (
      activityId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.MyApplyActivityMemberDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findApplyActivityFinalist: (
      activityId: string,

      params: FindApplyActivityFinalistParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ApplyActivityMemberDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findApplyActivityByActivityId: (
      activityId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.ApplyActivityResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findApplyActivityRegistered: (
      activityId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.ApplyActivityResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cancelRegister: (
      activityId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public listApplyActivityApplied: (
      activityRequest: cms.ApplyActivityRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Page<cms.ApplyActivityListDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public saveApplyActivityRegistered: (
      memberRequest: cms.ApplyActivityMemberRequest,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.ApplyActivityMemberResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public signIn: (
      activityId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  searchApplyActivityPager,
  searchAllApplyActivityPager,
  getApplyActivitiesList,
  findApplyActivitiesRegistered,
  findApplyActivityInfoMine,
  findApplyActivityFinalist,
  findApplyActivityByActivityId,
  findApplyActivityRegistered,
  cancelRegister,
  listApplyActivityApplied,
  saveApplyActivityRegistered,
  signIn,
} as ApplyActivityApi;
