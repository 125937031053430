/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 电影订单
 */
import getAdvance from "./getAdvance";
import cancelOrder from "./cancelOrder";
import addCalendar, { AddCalendarParams } from "./addCalendar";
import lockSeat from "./lockSeat";
import order from "./order";
import getMovieOrderDetail from "./getMovieOrderDetail";
import getCreateOrderStatus from "./getCreateOrderStatus";
import getOrderList from "./getOrderList";
import esOrderTicket, { EsOrderTicketParams } from "./esOrderTicket";
import getPendingOrder from "./getPendingOrder";

export class MovieOrderApi {
  constructor(
    public getAdvance: (
      uuid: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.MovieInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public cancelOrder: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public addCalendar: (
      params: AddCalendarParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public lockSeat: (
      lockMovieSeat: order.LockMovieSeat,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public order: (
      submitMovieOrderReq: order.SubmitMovieOrderReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.SubmitMovieOrderRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getMovieOrderDetail: (
      orderId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.MovieOrderDetailDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCreateOrderStatus: (
      uuid: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.MoviePayInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getOrderList: (
      movieOrderListReq: order.MovieOrderListReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.IPage<order.EsMovieOrderInfo>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public esOrderTicket: (
      params: EsOrderTicketParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getPendingOrder: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<order.PendingOrderDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getAdvance,
  cancelOrder,
  addCalendar,
  lockSeat,
  order,
  getMovieOrderDetail,
  getCreateOrderStatus,
  getOrderList,
  esOrderTicket,
  getPendingOrder,
} as MovieOrderApi;
