import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";

import store from "@/store";

export class Seat {
  seatId = -1; // 座位 ID，-1 表示过道
  description = ""; // 描述
  remark = ""; // 坐位备注
  available: boolean; // 是否可选
  priceId: number; // 票价 ID
  price: good.PriceDto = {}; // 价格信息
  rowCount = -1; // 第几行
  columnCount = -1; // 第几列
  type = 1; // 座位类型，1：座位；2：舞台

  constructor(
    seatId?: number,
    description?: string,
    remark?: string,
    available?: boolean,
    priceId?: number,
    rowCount?: number,
    columnCount?: number,
    type?: number
  ) {
    this.seatId = seatId == undefined ? -1 : seatId; // 可能为 0，所以不能用 ||
    this.description = description || "";
    this.remark = remark || "";
    this.available = available || false;
    this.priceId = priceId == undefined ? -1 : priceId; // 可能为 0，所以不能用 ||
    this.rowCount = rowCount == undefined ? -1 : rowCount; // 可能为 0，所以不能用 ||
    this.columnCount = columnCount == undefined ? -1 : columnCount; // 可能为 0，所以不能用 ||
    this.type = type == undefined ? -1 : type;
  }
}

export class Row {
  rowNum = 0; // 行号
  isSide?: boolean; // 这一行是不是过道
  area = ""; // 区域
  number?: { value?: number }; // 这一区域有多少座位
  id?: number;
  cols: Array<Seat> = [];

  constructor(rowNum: number) {
    this.rowNum = rowNum;
  }
}

export interface SelectSeatState {
  rowArr: Array<Row>; // 用来渲染座位图的数据
  isCustomStage: boolean; // 后端是否自定义了舞台
  customStageXmax: number; // 自定义舞台最大 X 坐标
  customStageXmin: number; // 自定义舞台最小 X 坐标
  customStageYmax: number; // 自定义舞台最大 Y 坐标
  customStageYmin: number; // 自定义舞台最小 Y 坐标
  isShowMiniSeatMap: boolean; // 是否显示迷你地图
  itemSize: number; // 画布中每个单元的大小，等于座位大小加间隔大小
  productId: string; // 产品 ID
  showId: number; // 场次 ID
  sectionId: number; // 分区 ID
  selectedSeatIds: Array<number>; // 选中的座位 ID
  highlightPriceId: number; // 选中的票档价格
  seatMapMatrixScale: number; // 座位图缩放
  seatMapMatrixX: number; // 选座图 x 位移
  seatMapMatrixY: number; // 选座图 y 位移
  surplus: number; // 用户还能选多少座位
  submitLoading: boolean; // 在调用锁座的异步请求返回前的加载效果
  maxColumnCount: number; // 座位图中最大的列数
  cartHeight: number; // 底部购物车的高度
  seatSelectorRowOffsetTop: number; // 选座画布最外边的 row 的顶部距离屏幕顶部的距离
  hasAvailableSeatPriceIds: Array<number>; // 存在可售座位的票档 ID
  bottomBarHeight: number; // 底部购物车占用的高度
  containerTopOffset: number; // 座位选择器的顶部到屏幕顶部的距离
}

@Module({ dynamic: true, store, name: "selectSeat" })
class SelectSeat extends VuexModule implements SelectSeatState {
  rowArr: Array<Row> = [];
  isCustomStage = false;
  customStageXmax = 0;
  customStageXmin = 100000;
  customStageYmax = 0;
  customStageYmin = 100000;
  isShowMiniSeatMap = true;
  itemSize = 30;
  productId = "";
  showId = -1;
  sectionId = -1;
  selectedSeatIds: Array<number> = [];
  highlightPriceId = -1;
  seatMapMatrixScale = 1;
  seatMapMatrixX = 0;
  seatMapMatrixY = 0;
  surplus = 0;
  submitLoading = false;
  maxColumnCount = 0;
  cartHeight = 0;
  seatSelectorRowOffsetTop = 0;
  hasAvailableSeatPriceIds: Array<number> = [];
  bottomBarHeight = 0;
  containerTopOffset = 0;

  /**
   * 画布宽度
   */
  get canvasWidth(): number {
    return this.itemSize * this.maxColumnCount;
  }

  /**
   * 画布高度
   */
  get canvasHeight(): number {
    return this.itemSize * this.rowArr.length + this.bottomBarHeight;
  }

  /**
   * 座位图的父元素的高度，座位图在其范围内放大缩小和移动，超出部分隐藏
   */
  get seatWrapperHeight(): number {
    return (
      window.document.documentElement.clientHeight -
      this.bottomBarHeight -
      this.containerTopOffset
    );
  }

  @Mutation
  resetAllData(): void {
    this.rowArr = [];
    this.isCustomStage = false;
    this.customStageXmax = 0;
    this.customStageXmin = 100000;
    this.customStageYmax = 0;
    this.customStageYmin = 100000;
    this.isShowMiniSeatMap = true;
    this.itemSize = 30;
    this.productId = "";
    this.showId = -1;
    this.sectionId = -1;
    this.selectedSeatIds = [];
    this.highlightPriceId = -1;
    this.seatMapMatrixScale = 1;
    this.seatMapMatrixX = 0;
    this.seatMapMatrixY = 0;
    this.surplus = 0;
    this.submitLoading = false;
    this.maxColumnCount = 0;
    this.cartHeight = 0;
    this.seatSelectorRowOffsetTop = 0;
    this.hasAvailableSeatPriceIds = [];
    this.bottomBarHeight = 0;
  }

  @Mutation
  SET_containerTopOffset(containerTopOffset: number): void {
    this.containerTopOffset = containerTopOffset;
  }

  @Mutation
  SET_rowArr(rowArr: Array<Row>): void {
    this.rowArr = rowArr;
  }

  @Mutation
  SET_bottomBarHeight(bottomBarHeight: number): void {
    this.bottomBarHeight = bottomBarHeight;
  }

  @Mutation
  SET_customStageXmax(customStageXmax: number): void {
    this.customStageXmax = customStageXmax;
  }

  @Mutation
  SET_customStageXmin(customStageXmin: number): void {
    this.customStageXmin = customStageXmin;
  }

  @Mutation
  SET_customStageYmax(customStageYmax: number): void {
    this.customStageYmax = customStageYmax;
  }

  @Mutation
  SET_customStageYmin(customStageYmin: number): void {
    this.customStageYmin = customStageYmin;
  }

  @Mutation
  SET_isCustomStage(isCustomStage: boolean): void {
    this.isCustomStage = isCustomStage;
  }

  @Mutation
  SET_hasAvailableSeatPriceIds(hasAvailableSeatPriceIds: Array<number>): void {
    this.hasAvailableSeatPriceIds = hasAvailableSeatPriceIds;
  }

  @Mutation
  SET_isShowMiniSeatMap(isShowMiniSeatMap: boolean): void {
    this.isShowMiniSeatMap = isShowMiniSeatMap;
  }

  @Mutation
  SET_cartHeight(cartHeight: number): void {
    this.cartHeight = cartHeight;
  }

  @Mutation
  SET_seatSelectorRowOffsetTop(seatSelectorRowOffsetTop: number): void {
    this.seatSelectorRowOffsetTop = seatSelectorRowOffsetTop;
  }

  @Mutation
  SET_maxColumnCount(maxColumnCount: number): void {
    this.maxColumnCount = maxColumnCount;
  }

  @Mutation
  SET_productId(productId: string): void {
    this.productId = productId;
  }

  @Mutation
  SET_showId(showId: number): void {
    this.showId = showId;
  }

  @Mutation
  SET_sectionId(sectionId: number): void {
    this.sectionId = sectionId;
  }

  @Mutation
  SET_highlightPriceId(highlightPriceId: number): void {
    this.highlightPriceId = highlightPriceId;
  }

  @Mutation
  SET_seatMapMatrixScale(seatMapMatrixScale: number): void {
    this.seatMapMatrixScale = seatMapMatrixScale;
  }

  @Mutation
  SET_seatMapMatrixX(seatMapMatrixX: number): void {
    this.seatMapMatrixX = seatMapMatrixX;
  }

  @Mutation
  SET_seatMapMatrixY(seatMapMatrixY: number): void {
    this.seatMapMatrixY = seatMapMatrixY;
  }

  @Mutation
  ADD_seatMapMatrixX(add: number): void {
    this.seatMapMatrixX += add;
  }

  @Mutation
  SUBTRACT_seatMapMatrixX(subtract: number): void {
    this.seatMapMatrixX -= subtract;
  }

  @Mutation
  ADD_seatMapMatrixY(add: number): void {
    this.seatMapMatrixY += add;
  }

  @Mutation
  SUBTRACT_seatMapMatrixY(subtract: number): void {
    this.seatMapMatrixY -= subtract;
  }

  @Mutation
  SET_surplus(surplus: number): void {
    this.surplus = surplus;
  }

  @Mutation
  SET_submitLoading(submitLoading: boolean): void {
    this.submitLoading = submitLoading;
  }

  @Mutation
  selectSeat(seatId: number): void {
    this.selectedSeatIds.push(seatId);
  }

  @Mutation
  unselectSeat(seatId: number): void {
    this.selectedSeatIds.splice(this.selectedSeatIds.indexOf(seatId), 1);
  }

  @Mutation
  clearSelectedSeat(): void {
    this.selectedSeatIds = [];
  }
}

export const SelectSeatModule = getModule(SelectSeat);
