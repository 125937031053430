/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 项目的观演人接口
 */
import saveProductCommonly from "./saveProductCommonly";
import getProductCommonlyList from "./getProductCommonlyList";

export class ProductCommonlyApi {
  constructor(
    public saveProductCommonly: (
      saveProductCommonlyDto: order.SaveProductCommonlyDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getProductCommonlyList: (
      productId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.ProductCommonlyDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  saveProductCommonly,
  getProductCommonlyList,
} as ProductCommonlyApi;
