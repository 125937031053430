import { DirectiveOptions } from "vue";
interface HTMLElementPlus extends HTMLElement {
  disabled?: boolean;
}
export const preventRepeatClick: DirectiveOptions = {
  inserted(el: HTMLElementPlus, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        el.style.cursor = "not-allowed";
        setTimeout(() => {
          el.style.cursor = "pointer";
          el.disabled = false;
        }, binding.value || 2000);
      }
    });
  },
};
