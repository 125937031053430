import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromStorage } from "@/store/utils";

declare type AddressInformation = {
  addressIdNameMap: Record<string, string>;
  addresses: Array<Address>;
};

const getAddresses = function (
  addressData?: Array<dictionary.RegionDto>
): AddressInformation {
  if (!addressData || addressData.length < 1) {
    return { addressIdNameMap: {}, addresses: [] };
  }
  const addressIdNameMap: Record<string, string> = {};
  const addresses: Array<Address> = [];
  addressData.forEach((region) => {
    const regionId = region.regionId || "";
    const regionName = region.regionName || "";
    if (regionId) {
      const childrenInformation = getAddresses(region.regionDtosList);
      const children = childrenInformation.addresses;
      addressIdNameMap[regionId] = regionName;
      if (children && children.length > 0) {
        Object.assign(addressIdNameMap, childrenInformation.addressIdNameMap);
        addresses.push({
          id: regionId,
          text: regionName,
          children,
        });
      } else {
        addresses.push({
          id: regionId,
          text: regionName,
        });
      }
    }
  });
  return { addressIdNameMap, addresses };
};

export type Address = {
  id: string;
  text: string;
  children?: Array<Address>;
};

export interface DictionaryState {
  addressIdNameMap: Record<string, string>; // 地址 ID 与地址名称的映射
  addresses: Array<Address>; // 选择地址需要用到的地址数据
  twoAddress: Array<dictionary.RegionDto>; // 个人中心-城市选择选择地址需要用到的地址数据
}

@Module({ dynamic: true, store, name: "dictionary" })
class Dictionary extends VuexModule implements DictionaryState {
  addressIdNameMap: Record<string, string> = {};
  addresses: Array<Address> = [];
  twoAddress: Array<dictionary.RegionDto> = []; // 个人中心-城市选择选择地址需要用到的二级地址数据
  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromStorage("vuex", "dictionary"));
  }

  @Mutation
  SET_addresses_PERSIST(addressData: Array<dictionary.RegionDto>) {
    const addressInformation: AddressInformation = getAddresses(addressData);
    this.addressIdNameMap = addressInformation.addressIdNameMap;
    this.addresses = addressInformation.addresses;
  }
  //个人资料-城市选择二级数据
  @Mutation
  TWO_DATA(addressData: Array<dictionary.RegionDto>) {
    this.twoAddress = addressData;
  }
}

export const DictionaryModule = getModule(Dictionary);
