/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 剧院cms相关接口
 */
import getTheaterArticles from "./getTheaterArticles";
import getTheaterBanners from "./getTheaterBanners";
import getTheaterFloorProducts from "./getTheaterFloorProducts";
import getTheaterFooters from "./getTheaterFooters";
import getTheaterIndexAds from "./getTheaterIndexAds";
import getTheaterLogo from "./getTheaterLogo";
import getTheaterNavs from "./getTheaterNavs";
import getTheaterPersonalList from "./getTheaterPersonalList";
import getDongYiAdverts from "./getDongYiAdverts";
import getTheaterNews, { GetTheaterNewsParams } from "./getTheaterNews";
import searchTheaterNewsPage, {
  SearchTheaterNewsPageParams,
} from "./searchTheaterNewsPage";

export class TheaterCmsApi {
  constructor(
    public getTheaterArticles: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.Article;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterBanners: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.TheaterBannerDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterFloorProducts: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.TheaterFloorDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterFooters: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.FooterArticlesDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterIndexAds: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.TheaterIndexAdsDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterLogo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.TheaterLogoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterNavs: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.TheaterNavigationDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterPersonalList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.Article>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getDongYiAdverts: (
      type: "SHOW_SET" | "VIDEO_SET" | "NEAR_EVENT" | "WECHAT_MALL",

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.DongYiAdvertDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getTheaterNews: (
      params: GetTheaterNewsParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.TheaterNewsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public searchTheaterNewsPage: (
      params: SearchTheaterNewsPageParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.IPage<cms.TheaterNewsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getTheaterArticles,
  getTheaterBanners,
  getTheaterFloorProducts,
  getTheaterFooters,
  getTheaterIndexAds,
  getTheaterLogo,
  getTheaterNavs,
  getTheaterPersonalList,
  getDongYiAdverts,
  getTheaterNews,
  searchTheaterNewsPage,
} as TheaterCmsApi;
