/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 抽奖活动
 */
import findActivityInfoAuxiliaryById from "./findActivityInfoAuxiliaryById";
import findActivityInfoById from "./findActivityInfoById";
import helpPrize from "./helpPrize";
import queryActivityWinningInfo from "./queryActivityWinningInfo";
import findMyActivityPrize from "./findMyActivityPrize";
import redPacketWinPrize from "./redPacketWinPrize";
import getLinkActivity from "./getLinkActivity";
import findLuckDrawById from "./findLuckDrawById";

export class LuckyApi {
  constructor(
    public findActivityInfoAuxiliaryById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.LuckDrawAuxiliaryDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findActivityInfoById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.ActivityInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public helpPrize: (
      activityQuery: cms.activityQuery,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.LuckDrawHelpDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public queryActivityWinningInfo: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<string>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findMyActivityPrize: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<cms.ActivityWinningRecordDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public redPacketWinPrize: (
      activityQuery: cms.activityQuery,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.RedPacketWinPrizeDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getLinkActivity: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.LinkActivityCouponResponse;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findLuckDrawById: (
      id: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.LuckDrawDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  findActivityInfoAuxiliaryById,
  findActivityInfoById,
  helpPrize,
  queryActivityWinningInfo,
  findMyActivityPrize,
  redPacketWinPrize,
  getLinkActivity,
  findLuckDrawById,
} as LuckyApi;
