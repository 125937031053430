import billboard, { BillboardApi } from "./billboard";

import channelDetail, { ChannelDetailApi } from "./channelDetail";

import cleintCache, { CleintCacheApi } from "./cleintCache";

import commodity, { CommodityApi } from "./commodity";

import coupon, { CouponApi } from "./coupon";

import daMai, { DaMaiApi } from "./daMai";

import dongYiGood, { DongYiGoodApi } from "./dongYiGood";

import preCache, { PreCacheApi } from "./preCache";

import product, { ProductApi } from "./product";

import search, { SearchApi } from "./search";

import seat, { SeatApi } from "./seat";

import show, { ShowApi } from "./show";

import theaterShop, { TheaterShopApi } from "./theaterShop";

import tour, { TourApi } from "./tour";

import ybyContent, { YbyContentApi } from "./ybyContent";

export class GoodApi {
  constructor(
    public billboard: BillboardApi,
    public channelDetail: ChannelDetailApi,
    public cleintCache: CleintCacheApi,
    public commodity: CommodityApi,
    public coupon: CouponApi,
    public daMai: DaMaiApi,
    public dongYiGood: DongYiGoodApi,
    public preCache: PreCacheApi,
    public product: ProductApi,
    public search: SearchApi,
    public seat: SeatApi,
    public show: ShowApi,
    public theaterShop: TheaterShopApi,
    public tour: TourApi,
    public ybyContent: YbyContentApi
  ) {}
}

export default {
  billboard,
  channelDetail,
  cleintCache,
  commodity,
  coupon,
  daMai,
  dongYiGood,
  preCache,
  product,
  search,
  seat,
  show,
  theaterShop,
  tour,
  ybyContent,
} as GoodApi;
