import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
// import VueAMap from "vue-amap";
import { Toast, Lazyload, Loading } from "vant";
import VueCropper from "vue-cropper";
import { preventRepeatClick } from "./utils/directive"; //自定义指令 防止短时间多次点击按钮触发接口调用！！！
import VueQrcode from "@/components/VueQrcode.vue";
import { UserModule } from "@/store/modules/user";
import { LocationModule } from "@/store/modules/location";
import { DictionaryModule } from "@/store/modules/dictionary";
import { DeleteLockSeatModule } from "@/store/modules/deleteLockSeat";
import { HomeDataModule } from "@/store/modules/homeData";
import { ExternalAccessModule } from "@/store/modules/externalAccess";
import { ChannelModule } from "@/store/modules/channel";
import { TheaterModule } from "@/store/modules/theater";
import { productIdModule } from "@/store/modules/rProductId";
import { RouteRecordModule } from "@/store/modules/routeRecord";
import { SelectSectionModule } from "@/store/modules/selectSection";
import { ChannelDistributionModule } from "@/store/modules/channelDistribution";
import { InterfaceCacheModule } from "@/store/modules/interfaceCache";
import { ProjectDetailCacheModule } from "@/store/modules/projectDetailCache";
import { ChannelFromModule } from "@/store/modules/channelFrom";

// import VConsole from "vconsole";
// import { initPlugin } from "vue-vconsole-devtools";
// if (
//   window.location.host !== "m.polyt.cn" ||
//   window.location.hash.indexOf("vconsole=1") >= 0
// ) {
//   initPlugin(new VConsole()); // 需要在创建Vue根实例前调用
// }

UserModule.setStateFromStorage();
LocationModule.setStateFromStorage();
DictionaryModule.setStateFromStorage();
DeleteLockSeatModule.setStateFromStorage();
HomeDataModule.setStateFromStorage();
ExternalAccessModule.setStateFromStorage();
ChannelModule.setStateFromStorage();
TheaterModule.setStateFromStorage();
productIdModule.setStateFromStorage();
RouteRecordModule.setStateFromStorage();
SelectSectionModule.setStateFromStorage();
ChannelDistributionModule.setStateFromStorage();
InterfaceCacheModule.setStateFromStorage();
ProjectDetailCacheModule.setStateFromStorage();
ChannelFromModule.setStateFromStorage();
Vue.directive("preventRepeatClick", preventRepeatClick);
Vue.use(VueCropper);
Vue.use(Lazyload);
Vue.use(Toast);
Vue.use(Loading);

// Vue.use(VueAMap);

// 初始化高德地图的 key 和插件
// VueAMap.initAMapApiLoader({
//   key: "8f2cabe1725281788ba9de184184942f",
//   plugin: [
//     "AMap.Autocomplete",
//     "AMap.PlaceSearch",
//     "AMap.Scale",
//     "AMap.OverView",
//     "AMap.ToolBar",
//     "AMap.MapType",
//     "AMap.PolyEditor",
//     "AMap.CircleEditor",
//     "AMap.Geolocation",
//   ],
//   v: "1.4.15",
// });
// const AMap = VueAMap;

// Vue.use(AMap);
Vue.use(VueDOMPurifyHTML);
Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
);
const ua = window.navigator.userAgent;
let AppSource = "";

if (/ios_poly/i.test(ua)) {
  AppSource = "ios";
} else if (/Harmony/.test(ua)) {
  AppSource = "harmonyOS";
} else if (/android_poly/i.test(ua)) {
  AppSource = "android";
} else if (/miniprogram/.test(ua)) {
  AppSource = "miniprogram";
}
Vue.prototype.$AppSource = AppSource;
console.log("ua>>", ua);
console.log("AppSource>>", AppSource);
Vue.component("vue-qrcode", VueQrcode);
// import "lib-flexible/flexible";
Vue.config.productionTip = false;
import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
