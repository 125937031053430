/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 历史售票订单写ES接口
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class EsOrderTicketParams {
  constructor(
    public isAll: boolean,
    public isDeleteStartOrderId: boolean,
    public orderIds?: Array<string>
  ) {}
}

export default async function (
  params: EsOrderTicketParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: boolean;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "post",
    url: `/order/movie/syn-order-list`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
