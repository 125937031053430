/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 用户登记记录
 */
import userLackCommodityRecord from "./userLackCommodityRecord";
import userLackTicketRecord, {
  UserLackTicketRecordParams,
} from "./userLackTicketRecord";

export class RecordApi {
  constructor(
    public userLackCommodityRecord: (
      commodityId: number,
      specsRelationId: string,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public userLackTicketRecord: (
      priceId: number,
      productId: number,
      showId: number,

      params: UserLackTicketRecordParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  userLackCommodityRecord,
  userLackTicketRecord,
} as RecordApi;
