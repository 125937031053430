import { Vue, Component } from "vue-property-decorator";
import { MenuModule } from "@/store/modules/menu";
import { UserModule } from "@/store/modules/user";
import { SelectSectionModule } from "@/store/modules/selectSection";
import { SelectSeatModule } from "@/store/modules/selectSeat";
import { TheaterModule } from "@/store/modules/theater";
import { productIdModule } from "@/store/modules/rProductId";
import { ChannelModule } from "@/store/modules/channel";
import { SpecialManagementModule } from "@/store/modules/SpecialManagement";
import { RouteRecordModule } from "@/store/modules/routeRecord";
import { LocationModule } from "@/store/modules/location";
import { ExternalAccessModule } from "@/store/modules/externalAccess";
import { DictionaryModule } from "@/store/modules/dictionary";
import { HomeDataModule } from "@/store/modules/homeData";
import { DeleteLockSeatModule } from "@/store/modules/deleteLockSeat";
import { ChannelDistributionModule } from "@/store/modules/channelDistribution";
import { InterfaceCacheModule } from "@/store/modules/interfaceCache";
import { ProjectDetailCacheModule } from "@/store/modules/projectDetailCache";
import { ChannelFromModule } from "@/store/modules/channelFrom";
import dayjs from "dayjs";
import config from "../config";
import wx from "weixin-js-sdk";
import {
  getCityCodeByAdcode,
  isChannelDistribution,
  isCustomChannelDistribution,
} from "@/utils/index";
// import setupWebViewJavascriptBridge from "@/core/config/Bridge";
import { Dialog } from "vant";
import { isWeiBo } from "@/utils/index";
let ticking = false;
declare const window: Window & {
  WebViewJavascriptBridge: any;
  WVJBCallbacks: any;
};

export type ChannelType =
  | "plat_h5"
  | "plat_ios"
  | "plat_android"
  | "plat_wechat_miniapp"
  | "douyin_miniapp";

@Component
class Mixin extends Vue {
  public DeleteLockSeatModule = DeleteLockSeatModule;
  public HomeDataModule = HomeDataModule;
  public MenuModule = MenuModule;
  public UserModule = UserModule;
  public TheaterModule = TheaterModule;
  public LocationModule = LocationModule;
  public SelectSectionModule = SelectSectionModule;
  public SelectSeatModule = SelectSeatModule;
  public SpecialManagementModule = SpecialManagementModule;
  public RouteRecordModule = RouteRecordModule;
  public ChannelModule = ChannelModule;
  public ExternalAccessModule = ExternalAccessModule;
  public DictionaryModule = DictionaryModule;
  public ChannelDistributionModule = ChannelDistributionModule;
  public productIdModule = productIdModule;
  public InterfaceCacheModule = InterfaceCacheModule;
  public ProjectDetailCacheModule = ProjectDetailCacheModule;
  public ChannelFromModule = ChannelFromModule;
  public app = config.app;
  public categroyIdMix = "";
  public mallCategroyMix = 0;
  public imageFit = "fill";
  public AppSource = Vue.prototype.$AppSource;

  pageSize = 20; // 分页查询，每页多少数据

  // 分页查询，根据返回的数据判断是否查询完成
  isPageFinished(currentPage: number, data: good.Page<unknown>): boolean {
    let pages = 0;
    // 终止加载的标志
    if (data) {
      pages = data.pages || 0;
    }
    return currentPage >= pages;
  }

  // 是否是渠道分销
  get isChannelDistribution(): boolean {
    return isChannelDistribution(this.$route);
  }

  // 是否是渠道分销自定义模板
  get isCustomChannelDistribution(): boolean {
    return isCustomChannelDistribution(this.$route);
  }

  // 渠道分销-渠道 ID
  get distributionChannelId(): string | undefined {
    const distributionChannelId = this.$route.query.distributionChannelId;
    return distributionChannelId ? String(distributionChannelId) : undefined;
  }

  // 渠道分销-系列 ID
  get distributionSeriesId(): string | undefined {
    const distributionSeriesId = this.$route.query.distributionSeriesId;
    return distributionSeriesId ? String(distributionSeriesId) : undefined;
  }

  // 渠道分销-模板类型
  get templateType(): string | undefined {
    const templateType = this.$route.query.templateType;
    return templateType ? String(templateType) : undefined;
  }

  /**
   * APP端交互
   * @param method 交互方法
   * @param params 给app传输数据
   * @param callback 非app环境处理
   * http://172.16.50.103:8090/pages/viewpage.action?pageId=15499953
   */

  jsBridge(method: string | number, params: any, callback?: () => void): void {
    if (Vue.prototype.$AppSource === "ios") {
      if (window.webkit.messageHandlers[method]) {
        window.webkit.messageHandlers[method].postMessage(params);
      } else {
        callback && callback();
      }
    } else if (
      Vue.prototype.$AppSource === "android" ||
      Vue.prototype.$AppSource == "harmonyOS"
    ) {
      if (window.android[method]) {
        if (params) {
          window.android[method](JSON.stringify(params));
        } else {
          window.android[method]();
        }
      } else {
        callback && callback();
      }
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      if (method === "needLogin") {
        wx.miniProgram.redirectTo({
          url: `/pagesCenter/bindPhone/bindPhone`,
        });
      } else if (method === "needRealName") {
        wx.miniProgram.navigateTo({
          url: `/pagesSet/realNameAuthentication/realNameAuthentication?goBack=true`,
        });
      }
    } else {
      // 否则是H5页面
      callback && callback();
    }
  }

  /**
   * 获取完整的跳转 url，点金页 iframe 向外跳转时使用
   */
  getCompleteUrl(uri: string): string {
    const location = window.location;
    const hash = location.hash;
    const hashIndex = hash.indexOf("?");
    const queryString = hashIndex < 0 ? "" : hash.slice(hashIndex);
    return location.origin + location.pathname + "#" + uri + queryString;
  }

  /**
   * 用高德地图定位
   */
  amapLocate(
    complete?: (cityCode: string, cityName: string) => void,
    error?: () => void
  ): void {
    const AMap = (window as any).AMap;
    const mapObj = new AMap.Map("iCenter");
    mapObj.plugin("AMap.Geolocation", () => {
      const geolocation = new AMap.Geolocation({
        timeout: 100000, // 超过 100 秒后停止定位
      });
      mapObj.addControl(geolocation);

      geolocation.getCurrentPosition();
      AMap.event.addListener(
        geolocation,
        "complete",
        (data: {
          position: { lat: number; lng: number };
          addressComponent: {
            adcode?: string;
            province: string;
            city: string;
          };
        }) => {
          // let longitude = 111.023491
          // let latitude = 35.000227
          // // let longitude = 121.48106
          // // let latitude = 31.19861
          console.log("定位------------------", data);
          const position = data.position;
          const addressComponent = data.addressComponent;
          this.LocationModule.SET_longitude_PERSIST(position.lng);
          this.LocationModule.SET_latitude_PERSIST(position.lat);
          if (complete) {
            complete(
              getCityCodeByAdcode(
                addressComponent
                  ? addressComponent.adcode
                  : LocationModule.defaultCityCode
              ),
              addressComponent
                ? addressComponent.city || addressComponent.province
                : LocationModule.defaultCityName // 直辖市 addressComponent.city 为空字符串，这时要取 province
            );
          }
        }
      );
      AMap.event.addListener(geolocation, "error", (errorData: unknown) => {
        console.error("locate >>> errorData", errorData);
        this.LocationModule.SET_longitude_PERSIST(-1);
        this.LocationModule.SET_latitude_PERSIST(-1);
        if (error) {
          error();
        }
      });
    });
  }

  public dateFormat = (date?: Date | string, format?: string): string => {
    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }
    return this.timestampFormat(date.getTime(), format);
  };

  public timestampFormat = (time: number, format?: string): string => {
    if (isNaN(time)) {
      return "";
    }
    return dayjs(time).format(format || "YYYY.MM.DD HH:mm:ss");
  };

  // 滚动分页，判断当前滚动条是否已到达底部
  public isWindowBottom(): boolean {
    // 滚动条滚动时，距离顶部的距离
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    // 可视区的高度，数值上与“滚动条滚轮的高度”相等，只是长度单位不同
    const windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    // 滚动条的总高度
    const scrollHeight =
      document.documentElement.scrollHeight - 100 ||
      document.body.scrollHeight - 100;
    return scrollTop + windowHeight >= scrollHeight;
  }

  /**
   * 路由到演出详情页
   *
   * @param productId 项目 ID
   * @param tourId 巡演 ID
   */
  public goToProjectDetail(
    productId?: number | string,
    tourId?: string,
    tour?: boolean
  ): void {
    // 排行榜会直接调用这个方法
    if (tour) {
      this.$router.push(`/tour/${tourId}`);
    } else {
      this.$router.push({
        path: `/projectdetail/${productId}/${tourId || null}`,
      });
    }
  }

  /**
   * 路由到确认订单页
   *
   * @param uuid 预订单 UUID
   */
  public goToOrderConfirm(uuid: string): void {
    this.$router.push({ path: "/orderconfirm/" + uuid });
  }

  // 小程序环境，弹出下载 APP 的弹框
  downloadAppForWechatMiniapp(): void {
    Dialog.confirm({
      confirmButtonText: "复制",
      title: "复制链接",
      message: `<div>
      <span>
      复制下载地址到浏览器打开
      </span>
      <span>
      https://a.app.qq.com/o/simple.jsp?pkgname=com.poly.mobile&android_scheme=polyt://&ios_scheme=polyt://
      </span>
        </div>
      `,
    }).then(() => {
      const input = document.createElement("input"); // 直接构建input
      input.value =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.poly.mobile&android_scheme=polyt://&ios_scheme=polyt://"; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      if (document.execCommand("Copy")) {
        this.$toast("复制成功");
      } else {
        this.$toast("复制失败");
      }
      document.body.removeChild(input); // 删除临时实例
    });
  }

  //根据21种数据类型跳转
  //keyWord (string, optional): 搜索关键词 ,
  // linkId (string, optional): 链接Id ,详情页所需id
  // linkUrl (string, optional): 链接地址
  async goToJump(
    keyWord: string | undefined,
    linkId: string | undefined,
    linkType: string | undefined,
    linkUrl: string | undefined,
    isTheater?: boolean
  ): Promise<void> {
    const theaterId = this.TheaterModule.theaterId;
    const channel = this.ChannelModule.channel;
    switch (linkType) {
      case "WECHAT_MINIAPP":
        //"小程序(linkId 为小程序Id)"
        if (channel === "plat_h5") {
          // this.$router.push(`/cloud-theatre-details?id=${linkId}`)
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goMiniapp", linkId);
        } else if (channel === "plat_wechat_miniapp") {
          // 小程序环境，弹出下载 APP 的弹框（小程序中无法播放视频）
          this.downloadAppForWechatMiniapp();
        }
        break;
      case "PRODUCT_LINK":
        // "项目链接(linkId 为项目productId,跳转到项目详情页)"
        this.getViewShow(channel, theaterId, false, linkId);
        break;
      case "EXTERNALE_LINK":
        // "外部链接(取 url 跳转到该网址)"
        /*
         * 后端配置广告时，APP、小程、H5 都是一个地方统一配
         * 在 APP 和小程序中需要跳到 H5 时需要在 url 上加上 externalaccess
         * H5 自己跳自己要把 externalaccess 去掉
         */
        if (linkUrl && linkUrl.includes("externalaccess")) {
          window.location.href = linkUrl.replace("/externalaccess", "");
        } else {
          window.location.href = linkUrl || "";
        }

        break;
      case "VIDEO_LINK":
        //"视频链接(linkId 为视频Id 跳转到视频详情页)"
        if (channel === "plat_h5") {
          this.$router.push(`/cloud-theatre-details?id=${linkId}`);
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goCloudDetail", `id/${linkId},taskId/""`);
        } else if (channel === "plat_wechat_miniapp") {
          // 小程序环境，弹出下载 APP 的弹框（小程序中无法播放视频）
          this.downloadAppForWechatMiniapp();
        }
        break;
      case "SHOW_CATEGORY":
        if (channel === "plat_h5") {
          //"演出分类(linkId 为演出分类Id)"
          sessionStorage.setItem("tabValue", linkId!);
          this.categroyIdMix = linkId!;
          this.HomeDataModule.SET_classFicationCategoryTabName_PERSIST(linkId!);
          this.$router.push(isTheater ? "/show" : "/classify");
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goClassify", linkId);
        } else if (channel === "plat_wechat_miniapp") {
          if (theaterId !== "40") {
            wx.miniProgram.navigateTo({
              url: `/pagesIndex/performanceScreeningF/performanceScreeningF?id=${linkId}&theaterId=${theaterId}`,
            });
          } else {
            console.log(123123);
            wx.miniProgram.switchTab({
              url: `/index/performanceScreening`,
            });
          }
        }

        break;
      case "SHOP_COMMODITY":
        // "积分商城商品链接(linkId 为积分商城商品Id 跳转到商品详情页)"
        // this.$router.push(`/mall/details?commodityId=${linkId}`);
        this.getViewMall(channel, theaterId, linkId);
        break;
      case "SHOP":
        //"积分商城(跳转到积分商城模块)"
        if (channel === "plat_h5") {
          this.$router.push(isTheater ? "/show/mall" : "/mall");
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goStore", "-1");
        } else if (channel === "plat_wechat_miniapp") {
          if (theaterId !== "40") {
            wx.miniProgram.navigateTo({
              url: `/pagesFlagshipStore/mallF/mallF?theaterId=${theaterId}`,
            });
          } else {
            wx.miniProgram.switchTab({
              url: `/index/mall`,
            });
          }
        }

        break;
      case "CLOUND_THEATER":
        // "云剧院(跳转到云剧院模块)"
        if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goCloud", "");
        } else if (channel === "plat_h5") {
          this.$router.push(`/mine/download-app`);
        } else if (channel === "plat_wechat_miniapp") {
          // 小程序环境，弹出下载 APP 的弹框
          this.downloadAppForWechatMiniapp();
        }
        break;
      case "COUPON_CENTRE":
        //"领券中心(跳转到领券中心模块)"
        if (channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesCenter/couponCenter/couponCenter`,
          });
        } else if (channel === "plat_h5") {
          this.$router.push("/coupon-center");
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goCouponCenter", `theaterId/${theaterId}`);
        }

        break;
      case "FLASH_COUPON":
        this.$router.push(`/limit-coupon`);
        //"限时抢券(跳转到限时抢券模块)"
        break;
      case "SEARCH":
        //"搜索(根据 keyWord 字段进行搜索)"
        const newKeyWord = keyWord ? keyWord : "";
        if (channel === "plat_h5") {
          if (this.$route.name == "search") {
            this.$router.replace(`/search/show?keyword=${newKeyWord}`);
          } else {
            this.$router.push(`/search/show?keyword=${newKeyWord}`);
          }
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goSearch", newKeyWord);
        } else if (channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/search/search?content=${newKeyWord}&type=performance`,
          });
        }
        break;
      case "CLUB":
        //"俱乐部活动(linkId为 活动Id)"
        //应该是活动报名
        this.$router.push(`/enter-activity`);
        break;
      case "LUCK_DRAW":
        //"九宫格抽奖(linkId为 抽奖活动Id)"
        this.$router.push(`/lottery?id=${linkId}`);
        break;
      case "FISSION":
        //"裂变活动(linkId 为 裂变活动Id)"
        this.$router.push(`/fission-activity?id=${linkId}`);
        break;
      case "FLASH_TICKET":
        //"限时抢购(跳转到限时抢购模块)"
        this.$router.push(`/flash-sale`);
        break;
      case "PRINTING":
        //"集印花活动(linkId 为 集印花活动Id)"
        this.$router.push(`/print-activity?id=${linkId}`);
        break;
      case "WECHAT_MINIAPP":
        //"小程序(linkId为小程序Id，url为小程序路径)"
        break;
      case "MORE":
        //"更多(栏目为更多，跳转到更多页)"
        this.$router.push("/more");
        break;
      case "SHOP_CATEGORY":
        //直接跳到积分商城
        if (channel === "plat_h5") {
          this.$router.push(
            isTheater ? `/show/mall?linkId=${linkId}` : `/mall?linkId=${linkId}`
          );
        } else if (channel === "plat_ios" || channel === "plat_android") {
          this.jsBridge("goStore", linkId);
        } else if (channel === "plat_wechat_miniapp") {
          if (theaterId !== "40") {
            wx.miniProgram.navigateTo({
              url: `/pagesFlagshipStore/mallF/mallF?theaterId=${theaterId}&typeId=${linkId}`,
            });
          } else {
            wx.miniProgram.switchTab({
              url: `/index/mall`,
            });
          }
        }
        break;
      case "YUBOYA_MODULE":
        window.location.href = location.origin + "/third/#/yuboya";
        break;
      case "USER_CENTER":
        //"个人中心(跳转到个人中心页)"
        this.$router.push({ path: "/mine" });
        break;
      case "TOUR":
        //"巡演聚合(跳转到巡演聚合页)",给巡演聚合页传tourId
        this.$router.push(`/tour/${linkId}`);
        break;
      case "LINK_MODULE":
        //"链接活动(跳到链接活动)"
        this.$router.push(`/coupon-link?id=${linkId}`);
        break;
      case "CLUB_MODULE":
        //"俱乐部活动(跳转俱乐部活动"
        this.$router.push(`/enter-activity-detail?id=${linkId}`);
        break;
      case "RED_MODULE":
        //"红包雨(跳转红包雨)"
        this.$router.push(`/red-envelope?id=${linkId}`);
        break;
      case "SPECIAL_MODULE":
        //"专题(跳转专题)"
        window.location.href =
          location.origin + `/third/#/special-theme/${linkId}`;
        // this.$router.push(`/special-theme?id=${linkId}`);
        break;
      case "ARTICLE_MODULE":
        //"添加文章(跳转文章)"
        this.$router.push(`/article?id=${linkId}`);
        break;

      default:
        // "旗舰店列表(跳转到旗舰店列表页)"
        return;
    }
  }

  guid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  /**
   * 防抖
   */
  antiShake(f: () => void): void {
    // ticking 用 vue data 无法拦住类似 Watch 时执行两次这类问题，因此用 js 变量
    if (!ticking) {
      ticking = true;
      window.setTimeout(() => {
        f();
        ticking = false;
      }, 1000 / 30);
    }
  }

  /**
   * 中奖的我的商品 去查看
   */
  getViewPrizes(item: cms.ActivityWinningRecordDto): void {
    const theaterId = this.TheaterModule.theaterId;
    const channel = this.ChannelModule.channel;
    switch (channel) {
      case "plat_h5":
        this.$router.push(`/mall/m-order/${item.orderId}`);
        break;
      case "plat_ios":
        this.jsBridge(
          "goMallOrder",
          item.orderId ? `${Number(item.orderId)}` : "0"
        );
        break;
      case "plat_android":
        this.jsBridge(
          "goMallOrder",
          item.orderId ? `${Number(item.orderId)}` : "0"
        );
        break;
      case "plat_wechat_miniapp":
        wx.miniProgram.navigateTo({
          url: `/pagesOrder/goodsOrder/goodsOrder?orderId=${item.orderId}&theaterId=${theaterId}&pageSources=orderList`,
        });
        break;
      default:
        // 默认跳H5
        this.$router.push(`/mall/m-order/${item.orderId}`);
        return;
    }
  }

  /**
   * 去各端演出详情
   */
  getViewShow(
    channel: ChannelType,
    theaterId: string,
    tour?: boolean,
    productId?: string,
    tourId?: string,
    plat?: boolean
  ): void {
    switch (channel) {
      case "plat_h5":
        // 是否是巡演
        if (tour) {
          this.$router.push(`/tour/${tourId}`);
        } else {
          this.goToProjectDetail(productId);
        }
        break;
      case "plat_ios":
      case "plat_android":
        this.jsBridge(
          "goProDetail",
          `productId/${productId},theaterId/${
            plat ? "40" : theaterId
          },taskId/""`
        );
        break;
      case "plat_wechat_miniapp":
        if (tour) {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/toursDetails/toursDetails?tourId=${tourId}`,
          });
        } else {
          if (theaterId === "40") {
            wx.miniProgram.navigateTo({
              url: `/pagesIndex/performDetail/performDetail?id=${productId}&tourid=${tourId}`,
            });
          } else {
            wx.miniProgram.navigateTo({
              url: `/pagesIndex/performDetailF/performDetailF?id=${productId}&theaterId=${
                plat ? "40" : theaterId
              }`,
            });
          }
        }
        break;
      default:
        return;
    }
  }
  /**
   * 限时抢购各端的活动演出详情抢购
   */

  goSnapUp(activityId?: string, productId?: string): void {
    const theaterId = this.TheaterModule.theaterId;
    const channel = this.ChannelModule.channel;
    switch (channel) {
      case "plat_h5":
        this.$router.push(`/activity/${activityId}/${productId}/null`);
        break;
      case "plat_ios":
      case "plat_android":
        this.jsBridge(
          "goFlashSaleDetail",
          `productId/${productId},activityId/${activityId},theaterId/${theaterId}`
        );
        break;
      case "plat_wechat_miniapp":
        if (theaterId === "40") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performDetail/performDetail?id=${productId}&activityId=${activityId}&pageSource=performDetail`,
          });
        } else {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performDetailF/performDetailF?id=${productId}&theaterId=${theaterId}&activityId=${activityId}&pageSource=performDetail`,
          });
        }
        break;
      default:
        return;
    }
  }

  /**
   * 去各端商品详情
   */
  getViewMall(
    channel: ChannelType,
    theaterId: string,
    id?: number | string
  ): void {
    switch (channel) {
      case "plat_h5":
        this.$router.push(`/mall/details?commodityId=${id}`);
        break;
      case "plat_ios":
      case "plat_android":
        this.jsBridge(
          "goGoodDetail",
          `commodityInfoId/${id},theaterId/${theaterId},taskId/""`
        );
        break;
      case "plat_wechat_miniapp":
        if (theaterId === "40") {
          wx.miniProgram.navigateTo({
            url: `/pagesMall/goodsDetails/goodsDetails?commodityInfoId=${id}`,
          });
        } else {
          wx.miniProgram.navigateTo({
            url: `/pagesMall/goodsDetailsF/goodsDetailsF?commodityInfoId=${id}&theaterId=${theaterId}`,
          });
        }
        break;
      default:
        return;
    }
  }
  // 禁输表情
  checkEmoji(value: string): boolean {
    // eslint-disable-next-line
        let regStr =
      /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
    const reg =
      /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
    if (reg.test(value)) {
      Vue.prototype.$toast("不支持输入表情符号");
      return false;
    }
    return true;
  }

  /**
   * 商品预订单
   */
  getOrderSheet(
    channel: ChannelType,
    uuid: string,
    fictitious?: boolean
  ): void {
    switch (channel) {
      case "plat_h5":
        this.$router.push(`/mall/confirm-order?uuid=${uuid}&type=exchange`);
        break;
      case "plat_ios":
      case "plat_android":
        this.jsBridge(
          "goMallPreOrder",
          `uuid/${uuid},fictitious/${fictitious},theaterId/${this.TheaterModule.theaterId}`
        );
        break;
      case "plat_wechat_miniapp":
        wx.miniProgram.navigateTo({
          url:
            this.TheaterModule.theaterId === "40"
              ? `/pagesOrder/confirmGoodsOrder/confirmGoodsOrder?uuid=${uuid}&theaterId=${this.TheaterModule.theaterId}`
              : `/pagesOrder/confirmGoodsOrderF/confirmGoodsOrderF?uuid=${uuid}&theaterId=${this.TheaterModule.theaterId}&pageSourcem=mallF`,
        });
        break;
      default:
        return;
    }
  }
  /**
   * 去优惠卷详情
   */
  goCouponDetail(couponId: number): void {
    const channel = this.ChannelModule.channel;
    const theaterId = this.TheaterModule.theaterId;
    switch (channel) {
      case "plat_ios":
      case "plat_android":
        this.jsBridge("goCouponDetail", String(couponId));
        break;
      case "plat_wechat_miniapp":
        if (theaterId === "40") {
          wx.miniProgram.navigateTo({
            url: `/pagesCenter/couponDetails/couponDetails?couponId=${couponId}&theaterId=${theaterId}`,
          });
        } else {
          wx.miniProgram.navigateTo({
            url: `/pagesCenter/couponDetailsF/couponDetailsF?couponId=${couponId}&theaterId=${theaterId}`,
          });
        }
        break;
      default:
        return;
    }
  }

  /**
   * 处理限时活动时间轴高亮
   * 热抢中取最后一个
   * 即将开始取第一个
   * 已结束取最后一个
   */
  dealTimeList(timeList: cms.ActivityLimitCouponTimeDto[]): {
    activityState: string;
    num: number;
    activeIndex: number;
    activeId: string;
  } {
    const info = { activityState: "", num: 0, activeIndex: 1, activeId: "" };
    const isState = timeList.some((item) => {
      return item.activityState === "2";
    });
    if (isState) {
      //当有热抢状态时
      timeList.forEach((item, index) => {
        if (item.activityState === "2") {
          //初始活动时热抢种的状态高亮 展示热抢中最后一个
          info.activityState = item.activityState;
          info.num = index;
          info.activeIndex = index;
          info.activeId = item.activityId ? item.activityId : "";
        }
      });
    } else {
      const obj = timeList.find((item, index) => {
        info.num = index;
        return item.activityState === "0";
      });
      //当没有热抢状态 取即将开始的第一个
      if (obj) {
        info.activityState = obj.activityState!;
        info.activeIndex = info.num;
        info.activeId = obj.activityId!;
      } else {
        const res = timeList.some((item) => {
          return item.activityState === "3";
        });
        //当热抢和即将都没有时，取最后一个结束活动
        if (res) {
          timeList.forEach((item, index) => {
            if (item.activityState === "3") {
              //初始活动时热抢种的状态高亮
              info.activityState = item.activityState;
              info.num = index;
              info.activeIndex = index;
              info.activeId = item.activityId ? item.activityId : "";
            }
          });
        } else {
          //其他状态默认取数组第一个
          info.activityState = timeList[0].activityState!;
          info.activeIndex = 0;
          info.num = 0;
          info.activeId = timeList[0].activityId!;
        }
      }
    }
    return info;
  }

  getIsWeiBo(): boolean {
    if (isWeiBo()) {
      Dialog.confirm({
        confirmButtonText: "去下载",
        title: "下载APP",
        message: `<div>
        请在APP中进行操作！</div>
        `,
      }).then(() => {
        if (Vue.prototype.$AppSource === "ios") {
          this.$router.push("/mine/download-app");
        } else {
          window.location.href =
            "https://a.app.qq.com/o/simple.jsp?pkgname=com.poly.mobile&android_scheme=polyt://&ios_scheme=polyt://";
        }
      });
      return true;
    }
    return false;
  }

  /**
   * 判断浏览器环境
   */
  getBrowserEnvironment(): {
    trident: boolean;
    presto: boolean;
    webKit: boolean;
    gecko: boolean;
    mobile: boolean;
    ios: boolean;
    android: boolean;
    iPhone: boolean;
    iPad: boolean;
    webApp: boolean;
  } {
    const u = navigator.userAgent;
    return {
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1, //是否web应用程序，没有头部与底部
    };
  }
}

export { Mixin };
