/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 云剧院评论
 */
import comment from "./comment";
import praise, { PraiseParams } from "./praise";
import getCommentPage, { GetCommentPageParams } from "./getCommentPage";

export class CloundTheaterCommentApi {
  constructor(
    public comment: (
      req: cms.CloudTheaterCommentReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public praise: (
      params: PraiseParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.CommentLikeDTO;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCommentPage: (
      id: string,

      params: GetCommentPageParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: cms.CloudTheaterCommentDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  comment,
  praise,
  getCommentPage,
} as CloundTheaterCommentApi;
