export function loadFromStorage(key: string, module: string): unknown {
  const info = localStorage.getItem(key);
  if (!info) {
    return null;
  }
  return JSON.parse(info)[module];
}

export function loadFromSessionStorage(key: string, module: string): unknown {
  const info = sessionStorage.getItem(key);
  if (!info) {
    return null;
  }
  return JSON.parse(info)[module];
}
