/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description OSS授权访问
 */
import getSTS from "./getSTS";
import presignedUrlDelete, {
  PresignedUrlDeleteParams,
} from "./presignedUrlDelete";
import presignedUrlGet, { PresignedUrlGetParams } from "./presignedUrlGet";
import presignedUrlPut, { PresignedUrlPutParams } from "./presignedUrlPut";

export class StsApi {
  constructor(
    public getSTS: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: oss.STSDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public presignedUrlDelete: (
      params: PresignedUrlDeleteParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public presignedUrlGet: (
      params: PresignedUrlGetParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public presignedUrlPut: (
      params: PresignedUrlPutParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getSTS,
  presignedUrlDelete,
  presignedUrlGet,
  presignedUrlPut,
} as StsApi;
