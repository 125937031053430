/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 榜单
 */
import getBillboardSquare from "./getBillboardSquare";
import getBillboardById from "./getBillboardById";
import getBillboardByCityCode from "./getBillboardByCityCode";
import floorBillboard from "./floorBillboard";

export class BillboardApi {
  constructor(
    public getBillboardSquare: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.BillboardSquareDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getBillboardById: (
      billboardId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.BillboardInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getBillboardByCityCode: (
      billboardId: number,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.BillboardInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public floorBillboard: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.FloorBillboardDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getBillboardSquare,
  getBillboardById,
  getBillboardByCityCode,
  floorBillboard,
} as BillboardApi;
