import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadFromSessionStorage } from "@/store/utils";

export interface ChannelFromState {
  channelFrom: string;
  cooperativeInstitutionInfo: string;
  showId: string;
  productId: string;
  sectionId: string;
  priceId: string;
  tourId: string;
  isChoseSeat: boolean;
}

@Module({ dynamic: true, store, name: "channelFrom" })
class ChannelFrom extends VuexModule implements ChannelFromState {
  channelFrom = "";
  cooperativeInstitutionInfo = "";
  showId = "";
  productId = "";
  tourId = "";
  sectionId = "";
  priceId = "";
  isChoseSeat = false;

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromSessionStorage("vuex", "channelFrom"));
  }

  @Mutation
  SET_ChannelFrom_PERSIST(form: string) {
    this.channelFrom = form;
  }

  @Mutation
  SET_showId_PERSIST(id: string) {
    this.showId = id;
  }

  @Mutation
  SET_productId_PERSIST(id: string) {
    this.productId = id;
  }

  @Mutation
  SET_sectionId_PERSIST(id: string) {
    this.sectionId = id;
  }

  @Mutation
  SET_priceId_PERSIST(id: string) {
    this.priceId = id;
  }

  @Mutation
  SET_IsChoseSeat_PERSIST(isChoseSeat: boolean) {
    this.isChoseSeat = isChoseSeat;
  }

  @Mutation
  SET_tourId_PERSIST(id: string) {
    this.tourId = id;
  }

  @Mutation
  RESET_Data() {
    this.showId = "";
    this.priceId = "";
    this.productId = "";
    this.sectionId = "";
    this.isChoseSeat = false;
  }

  @Mutation
  SET_cooperativeInstitutionInfo_PERSIST(info: string) {
    this.cooperativeInstitutionInfo = info;
  }
}

export const ChannelFromModule = getModule(ChannelFrom);
