/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 获取上传签名
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: oss.SignatureDto;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/oss/policy`,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
