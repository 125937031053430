/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 大学生认证服务接口
 */
import getCollegeStudentCertification from "./getCollegeStudentCertification";
import getCollegeStudentCertificationBaseInfo from "./getCollegeStudentCertificationBaseInfo";
import addCollegeStudentCertificationBaseInfo from "./addCollegeStudentCertificationBaseInfo";
import getStudentStatusList from "./getStudentStatusList";
import getUniversityList from "./getUniversityList";

export class CollegeStudentCertificationApi {
  constructor(
    public getCollegeStudentCertification: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.CollegeStudentCertificationRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getCollegeStudentCertificationBaseInfo: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.CollegeStudentCertificationBaseInfoRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public addCollegeStudentCertificationBaseInfo: (
      collegeStudentCertification: order.CollegeStudentCertificationBaseInfoRes,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getStudentStatusList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<order.StudentStatusRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public getUniversityList: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<order.SchoolRes>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  getCollegeStudentCertification,
  getCollegeStudentCertificationBaseInfo,
  addCollegeStudentCertificationBaseInfo,
  getStudentStatusList,
  getUniversityList,
} as CollegeStudentCertificationApi;
