/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 获取旗舰店相关广告位信息,瀑布流,轮播图,积分商城通栏广告,旗舰店banne
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  type:
    | "APP_BANNER"
    | "APP_POPUP"
    | "APP_RECOMMEND_ADVERT"
    | "APP_NOTICE"
    | "APP_PROJECT_ADVERT"
    | "PAY_SUCCESS"
    | "MALL_BANNER"
    | "PC_POPUP"
    | "PC_BOTTOM_FLOAT_LAYER"
    | "PC_BANNER"
    | "FLAGSHIP_STORE_BANNER"
    | "FLAGSHIP_STORE_FALLS_ADVERT"
    | "FLAGSHIP_STORE_SLIDE_SHOW"
    | "FLAGSHIP_STORE_LATEST_NEWS"
    | "FLAGSHIP_STORE_NEWS_FOCUS"
    | "FLAGSHIP_STORE_AFFICHE"
    | "FLAGSHIP_STORE_PRODUCT"
    | "FLAGSHIP_STORE_MALL_BANNER"
    | "PC_NOTICE"
    | "CLOUD_THEATER_BANNER"
    | "CLOUD_THEATER_TOP"
    | "CLOUD_THEATER_BOTTOM"
    | "CLOUD_THEATER_VIDEO"
    | "APP_OPEN_SCREEN"
    | "LIMIT_COUPON"
    | "APP_SEARCH",

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: Array<cms.TheaterAdvertDto>;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/cms/${type}/flagship-store/adverts`,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
