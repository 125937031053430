/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 旗舰店
 */
import theaterShopProducts from "./theaterShopProducts";
import findShopInfoById from "./findShopInfoById";
import shopProducts from "./shopProducts";
import shopsAll, { ShopsAllParams } from "./shopsAll";
import shopsCity from "./shopsCity";
import shopsCityAll from "./shopsCityAll";
import shopsDiscover, { ShopsDiscoverParams } from "./shopsDiscover";
import shopsGlobalSearch, {
  ShopsGlobalSearchParams,
} from "./shopsGlobalSearch";
import shopSwonderfulReview, {
  ShopSwonderfulReviewParams,
} from "./shopSwonderfulReview";

export class TheaterShopApi {
  constructor(
    public theaterShopProducts: (
      condition: good.ShopCategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.ShopProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public findShopInfoById: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.TheaterShopInfoDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopProducts: (
      condition: good.ShopCategoryCondition,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopsAll: (
      params: ShopsAllParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.TheaterShopsDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopsCity: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.TheaterShopSearchDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopsCityAll: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: Array<good.TheaterShopCityInitialDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopsDiscover: (
      params: ShopsDiscoverParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.TheaterShopSearchDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopsGlobalSearch: (
      params: ShopsGlobalSearchParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.TheaterShopSearchDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public shopSwonderfulReview: (
      params: ShopSwonderfulReviewParams,
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: good.Page<good.RecommendProductDto>;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  theaterShopProducts,
  findShopInfoById,
  shopProducts,
  shopsAll,
  shopsCity,
  shopsCityAll,
  shopsDiscover,
  shopsGlobalSearch,
  shopSwonderfulReview,
} as TheaterShopApi;
