/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 查询可售的座位列表
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class GetAvailableSeatParams {
  constructor(
    public distributionChannelId?: string,
    public distributionSeriesId?: string
  ) {}
}

export default async function (
  productId: string,
  sectionId: number,
  showId: number,

  params: GetAvailableSeatParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: Array<number>;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/good/seats/${productId}/${showId}/${sectionId}/available`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
