/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 商品模板列表查询
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export default async function (
  goodsReq: cms.SubjectGoodsReq,

  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: cms.Page<cms.SubjectGoodsDto>;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "post",
    url: `/cms/subject-info/goods`,
    data: goodsReq,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
