import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import { loadFromSessionStorage } from "@/store/utils";

import store from "@/store";

export interface SelectSectionState {
  sectionIdWebCdnPathMap: Record<string, string>; // key：分区 ID；value：这个分区对应座位信息的 CDN 资源地址
}

@Module({ dynamic: true, store, name: "selectSection" })
class SelectSection extends VuexModule implements SelectSectionState {
  sectionIdWebCdnPathMap: Record<string, string> = {};

  @Mutation
  setStateFromStorage(): void {
    Object.assign(this, loadFromSessionStorage("vuex", "selectSection"));
  }

  @Mutation
  SET_sectionIdWebCdnPathMap_PERSIST(
    sectionIdWebCdnPathMap: Record<string, string>
  ): void {
    this.sectionIdWebCdnPathMap = sectionIdWebCdnPathMap;
  }
}

export const SelectSectionModule = getModule(SelectSection);
