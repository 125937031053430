/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 消息设置和个人信息
 */
import selectBasicMessage from "./selectBasicMessage";
import editMessage from "./editMessage";
import emptyMessages from "./emptyMessages";
import selectMessage from "./selectMessage";
import selectUseMessage from "./selectUseMessage";

export class MessageAndPersonApi {
  constructor(
    public selectBasicMessage: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.BasicMessageDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public editMessage: (
      messageDto: member.MessageDto,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public emptyMessages: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: boolean;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selectMessage: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.MessageDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public selectUseMessage: (
      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: member.UserMessageDto;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  selectBasicMessage,
  editMessage,
  emptyMessages,
  selectMessage,
  selectUseMessage,
} as MessageAndPersonApi;
