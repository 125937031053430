/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description 电子钱包订单
 */
import activateECard from "./activateECard";
import setRechargeOrder from "./setRechargeOrder";

export class WalletOrderApi {
  constructor(
    public activateECard: (
      giftCardReq: order.GiftCardReq,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: order.RechargeByCardRes;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void,

    public setRechargeOrder: (
      deposit: order.DepositDTO,

      success?: ({
        data,
        ext,
        state,
        errors,
      }: {
        data: string;
        ext: ObjectMap;
        state: "SUCCESS" | "FAIL" | "EXCEPTION";
        errors?: Array<string>;
      }) => any,
      fail?: (error: string) => any
    ) => void
  ) {}
}

export default {
  activateECard,
  setRechargeOrder,
} as WalletOrderApi;
